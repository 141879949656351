import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabs: [],
  lastActiveTab: null,
};

export const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {

  
    setLastActiveTab : (state, action) => {
      state.lastActiveTab = action.payload;
    },
   
    addTab: (state, action) => {
       
       
      state.tabs.push(action.payload);
     
    
    },
    removeTab: (state, action) => {
      state.tabs = state.tabs.filter((tab) => tab.id !== action.payload.id);
    },
    removeTabById: (state, action) => {
      state.tabs = state.tabs.filter((tab) => tab.id !== action.payload);
     
    },
    clearTabs : (state, action) => {
      state.tabs = []
    }
  },
});

export const { addTab, removeTab , clearTabs, removeTabById, setLastActiveTab } = tabSlice.actions;
export default tabSlice.reducer;