import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const Alert = React.forwardRef(function Alert(props, ref) {
  const severity = props.severity
  const getBackgroundColor = () => {
    return 'linear-gradient(to right , #F6743E, #FFD02B)';
    
    }
 

  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} 
  sx={{
   background: getBackgroundColor(),
   color : 'white'
  }}
  />;
});



export default function AlertBox({open, message, setOpen, autoHideDuration, header}) {
 
  const [title, setTitle] = useState('Alerte');
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

   setOpen(false);
  };

 
  
  return (
    <div>
      <Snackbar open={open}
       autoHideDuration={autoHideDuration? autoHideDuration : 5000}
        onClose={handleClose}
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert  onClose={handleClose} severity="error" sx={{ width: '100%', minWidth: '320px', maxWidth: '320px' }}>
          <AlertTitle>{title} </AlertTitle>
          
          <br></br>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
