import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

export const AddNoteInterne = createAsyncThunk('note/AddNoteInterne',
async (data, thunkAPI) => {
   
    try {

    const tokenStored = localStorage.getItem('TokenStored')
    const timezone = localStorage.getItem('TZ')
    const res = await axios.post(BaseUrl + 
        `/SAV/AjoutNoteInterne?idTicket=${data.idTicket}`,
          data.contenuNoteInterne,
         {
            headers: {
            Accept: 'application/json',  
            'Content-Type': 'application/json',
            'Authorization': tokenStored,
            'Platform': 'web',
            'timezone' : timezone,
          }
         },
         
          );
    return res}
    catch(err)
    {
        return thunkAPI.rejectWithValue({ error: err.message })
    }     
})  
const initialState = {
    loading : false,
    data : [],
    notes : [],
    note : null,
    error: "",
};
const notesSlice = createSlice({
    name: 'note',
    initialState
    ,
    extraReducers: (builder) => {
        
       
        builder.addCase(AddNoteInterne.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(AddNoteInterne.fulfilled, (state, action) => {
            state.loading = false;
            state.note = action.payload;
            state.error = "";
        })
        builder.addCase(AddNoteInterne.rejected, (state, action) => {
            state.loading = false;
            state.note = null;
            state.error = action.error.message;
        }) 
        
}
});
export default notesSlice.reducer;