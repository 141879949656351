import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function FullScreenOnIcon() {
    return (
       
        <SvgIcon>
 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g clipPath="url(#clip0_1_38673)">
<path fillRule="evenodd" clipRule="evenodd" d="M3.95265 16.7764L9.55058 11.1785L8.37207 10L2.77414 15.5979L3.95265 16.7764Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M3.90169 15.6482L3.90169 12.3568L2.23503 12.3568L2.23503 16.4816C2.23503 16.9418 2.60812 17.3149 3.06836 17.3149L7.19315 17.3149L7.19315 15.6482L3.90169 15.6482Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5981 2.77336L10.0002 8.37129L11.1787 9.5498L16.7766 3.95188L15.5981 2.77336Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.6486 3.90158L15.6486 7.19303L17.3153 7.19303L17.3153 3.06824C17.3153 2.60801 16.9422 2.23491 16.4819 2.23491L12.3571 2.23491L12.3571 3.90158L15.6486 3.90158Z" fill="#2A66C6"/>
</g>
<defs>
<clipPath id="clip0_1_38673">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
     </SvgIcon>
    )
}
