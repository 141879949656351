/**
 * Imports React, useCallback, MuiAppBar, NotificationsIcon, IconButton, Badge, Typography, useTranslation, Toolbar, and styled from their respective libraries.
 * Imports Logo image from '../../assets/images/Logo.svg'.
 * Imports IconGlobalization component from "../IconComponents/IconGlobalization".
 * Imports ChangeLang component from "./ChangeLanguage".
 *
 * @param {object} props - Object containing toggleDrawer, drawerwidth, and open props.
 * @returns {React.Component} The Navbar component.
 */

import React, {  useState } from 'react';
import IconButton from '@mui/material/IconButton';
// import ChangeLang from './ChangeLanguage';

import H5 from '../TypoGraphy/H5';
import SettingsIcon from '../IconComponents/SettingsIcon';
import ChevronLeftIcon from '../IconComponents/ChevronLeftIcon';
import ChevronRightIcon from '../IconComponents/ChevronRightIcon';


export default function SidebarFooter({  open, sidebarMinimize, sidebarMaximize }) {
 
  const handleMinimize = () => {
    sidebarMinimize();

   // toggleDrawer();
   //setOpen(!open);
  };
  const handleMaximize = () => {
    sidebarMaximize();

   // toggleDrawer();
   //setOpen(!open);
  };
 
  return (
    // <Drawer position="fixed" open={open}  variant="permanent" 
  
    //  sx={{  left: 0,  bottom: 0, 
    //  // width: drawerFooterWidth,
    //    height : 50,  zIndex: 999 ,  background: 'white', border : 0}}>
    <>
        
          {open ? 
          (
        
        <div
  style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexDirection: 'row',
  marginTop: '-2%' 
  }}
>
  <div style={{ flex: '6 10 10 9', marginLeft: '10px', }}>
    <IconButton>
      <SettingsIcon />
      <span style={{ marginLeft: '10px',  marginTop: '10px'}}>
        <H5 title={'Settings'} fontStyle={'bold'} fontFamily={'DM Sans'}/>
      </span>
    </IconButton>
  </div>

  <div style={{ flex: '2 2 2 2', marginRight: '10px',  }}>
    <IconButton 
    sx={{ 
      width: '25px',

height: '25px',
marginTop : '10px',
marginRight: '10px',
//transform: 'rotate(90deg)',
      borderRadius: '8px',

      border: '1px solid #344054',
      
      background: '#FFF',
    }}
    onClick={handleMinimize}>
      <ChevronLeftIcon />
    </IconButton>
  </div>
</div>

             ) : 
             
             
             (
              <div
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  //  marginBottom : '100px'
  
  }}
>
  {/* <div >
    <IconButton>
      <SettingsIcon  />
    </IconButton>
  </div> */}

  <div>
    <IconButton onClick={handleMaximize} sx={{ 
        width: '25px',

        height: '25px',
       
      // transform: 'rotate(90deg)',
      borderRadius: '8px',

      border: '1px solid #344054',
      
      background: '#FFF',
    }}>
      <ChevronRightIcon />
    </IconButton>
  </div>
</div>
         
          )
          }
       </>
    

  );
}