import React, {useState} from "react";
import { Typography , Tooltip} from '@mui/material';
import { Colors } from "../Utility/Colors";
import { ColorLensRounded } from "@mui/icons-material";
/**
 * This is a React component called H5 that renders a Typography element with customizable font style,
 * color, and title.
 * @returns a Typography component with the following props and styles:
 */
export default function H5 (props){
    const [fontStyle, setFontStyle] = useState(props.fontStyle ? props.fontStyle : "normal");
    const [fontWeight, setFontWeight] = useState(props.fontWeight ? props.fontWeight : "regular");
    const [mt, setMt] = useState(props.mt ? props.mt : "10px");
    const [color, setColor] = useState(props.color ? props.color : Colors.black);
    return (
       
        <Typography sx={{
            color: {color},
            textAlign: "left",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: {fontStyle},
            fontWeight: {fontWeight},
            lineHeight: "12px",
            weight : "24px",
            height : "20px",
            mt : {mt},
            
           
        }} >
            {props.title} {(props.requiredInput ? (<span style={{ color: 'black' }}>*</span>) : null) }
            </Typography>
    ) 
}