import React, {  useEffect, useState, useRef } from "react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import { getMessages, sendMessage } from "../../ReduxTLK/features/Chat/ChatSlice";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,

} from "@chatscope/chat-ui-kit-react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Dialog, DialogContent, Drawer, Avatar, Badge, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import H5 from "../TypoGraphy/H5";

import ChatIcon from "../../GlobalComponents/IconComponents/ChatIcon";
import { Button } from "@mui/material";
import './ChangeStateMsg.css'
import Box from '@mui/material/Box';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import ImageIcon from '@mui/icons-material/Image';
import { getAttachementById } from "../../ReduxTLK/features/Attachement/AttachementSlice";
import PdfIcon from '../../GlobalComponents/IconComponents/PdfIcon';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import moment from "moment";
import ErrorBox from "../Alerts/ErrorBox";
import { array_status } from "../../ReduxTLK/features/SAV/ticketList";
import { fetchCauses } from "../../ReduxTLK/features/Causes/CauseSlice";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


//const shapeCircleStyles = { borderRadius: '50%' };


export default function ChatComponent(props) {
  const dispatch = useDispatch()
  const userId = localStorage.getItem('userId')
  const userlastName = localStorage.getItem('userlastName')
  const connectionId = localStorage.getItem('connectionId')
  const [array_causes, setArray_causes] = useState([])
  const openTicket = props.openTicket
  const [open, setOpen] = useState(false);
  const [openErrorBox, setOpenErrorBox] = useState(false)
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("")
  // useEffect(() => {
  //   signalRService.onJoinRoom(props.openTicket);
  // }, [open])
  const handleClose = () => {

    const data = {
      idTicket: props.openTicket,
      page: 1,
      pageSize: 1000
    }
    dispatch(getMessages(data))
    setOpen(false)
    props.setNbreMessagesNonLus(0)
  }
  useEffect(() => {
    //props.joinRoomFunction();
    if (props.openTicket == !undefined || props.openTicket == ! null) {
      props.fetchMessages();
    }
  }, []);


  const handleSendMessage = async (textContent) => {
   
    props.setNbreMessagesNonLus(0)
    // signalRService.onJoinRoom(props.openTicket);
    const data = {
      contenu: textContent,
      idTicket: props.openTicket,
      connectionId: connectionId
    }
    const encodedMessage = encodeURIComponent(textContent);
    const form = new FormData();
    form.append('contenu', encodedMessage)
    form.append('idTicket', props.openTicket)
    form.append('connectionId', connectionId)
    form.append('isUpdateStatut', false)
    try {
      dispatch(sendMessage(form)).then(unwrapResult).then(() => {
        props.setMessagesList((prevMessagesList) => {
          
          const message = {

            contenu: textContent,
            sender: {
              id: userId,
              nom: userlastName
            },
            sentTime: new Date(),

          };
        
          return [...prevMessagesList, message];
        })


      });

    }
    catch (err) {
     
    }
  }
  /*********file input */
  const inputFile = useRef(null)
  const handleAttach = () => {
    //  open file input box on click of another element
    inputFile.current.click();
  };
  const handleFileChange = event => {

    props.setNbreMessagesNonLus(0)
    
    const fileObj = event.target.files && event.target.files[0];
    
    if (!fileObj) {
      return;
    }
    
    // File type check
    const allowedTypes = ['png', 'jpg', 'jpeg','gif', 'pdf', 'docx', 'doc', 'xlsx'];
    if (!allowedTypes.includes(fileObj?.name?.toLowerCase().split('.').pop())) {

      setMessage('Type de fichier invalide. Les types de fichiers autorisés sont PNG, JPG, JPEG, GIF, PDF, DOC ou XLS.')
      setSeverity('error')
      setOpenErrorBox(true)

      return;
    }

    // File size check
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
    if (fileObj?.size > maxSize) {


      setMessage('La taille du fichier dépasse la taille maximale autorisée (5 Mo).')
      setSeverity('error')
      setOpenErrorBox(true)
      return;
    }

    const form = new FormData();
    form.append('attachement', fileObj);
    // form.append('Type', fileObj.type.split('/').pop());
    form.append('contenu', '')
    form.append('idTicket', props.openTicket)
    form.append('connectionId', connectionId)
    form.append('isUpdateStatut', false)
    try {
      const data = {
        pageSize: 7,
        page: 1,
        nbPage: 0,
        idTicket: props.openTicket
      }
      dispatch(sendMessage(form)).then((res) => {
        props.fetchAttachments(data)
      })


      setTimeout(() => {
        props.fetchMessages(); // Dispatch the message fetching action
      }, 2000);
    }
    catch (err) {
     
    }
  }


  /************* */
  const [invisible, setInvisible] = useState(props.nbreMessagesNonLus > 0 ? false : true);

  const openDialog = () => {
    setOpen(true);
    //  props.setNbreMessagesNonLus(0)
    setInvisible(true)


  }
  const [openPreview, setOpenPreview] = useState(false)
  const [openPreviewImage, setOpenPreviewImage] = useState(false)
  const previewImage = () => {
    setOpenPreviewImage(true);
  }
  const previewPdf = () => {
    setOpenPreview(true);
  }
  function getTimeDifference(pastTime) {
    const now = new moment();
    const left = moment(pastTime)

    const difference = now.diff(left, 'milliseconds');

    let differenceInSeconds = Math.floor(difference / 1000);
    let differenceInMinutes = Math.floor(differenceInSeconds / 60);
    let differenceInHours = Math.floor(differenceInMinutes / 60);
    let differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInSeconds < 60) {
      return `maintenant`;
    } else if (differenceInMinutes < 60) {
      return `il y a ${differenceInMinutes} m`;
    } else if (differenceInHours < 24) {
      return `il y a ${differenceInHours} h`;
    } else {
      return `il y a ${differenceInDays} j`;
    }
  }

  const NewMessages = ({ nbreMessagesNonLus }) => {

    if (nbreMessagesNonLus > 0) {
      setInvisible(false)
      return (
        <Badge
          color="primary"
          sx={{
            display: 'flex',
            ml: 1,
            width: 25,
            height: 20,
            padding: '0px 8px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '12px',
            background: 'var(--secondary-400-base, #1671D9)',
          }}>
          <Typography sx={{ color: 'white' }} >{nbreMessagesNonLus}</Typography>
        </Badge>
      );
    }
    else {
      return <></>
    }

  };
  useEffect(() => {
    dispatch(fetchCauses()).then((response) => {
     
       setArray_causes(response.payload.data?.data.result)
    })
}, [])
  const renderStatus = (status) => {
    const res =  array_status.find((item) => item.id === status);
    return res
  }
  const renderCause = (cause) => {
    const res =  array_causes.find((item) => item.valeur === cause);
    return res?.text
  }
  const AssetMessageType = (props) => {
  
    if (props?.message?.contenu?.includes('Message_Update')) {
      
      const mots = props?.message?.contenu?.split(" ");
      const oldState = renderStatus(props?.message?.messageUpdateStatut?.action?.firstStatuts)
      const newState =  renderStatus(props?.message?.messageUpdateStatut?.action?.secondStatuts)
      const cause = renderCause(props?.message?.messageUpdateStatut?.cause)
      const note = props?.message?.messageUpdateStatut?.note
      /*** */
      return (
        <div className="asset-message-type">
          <p className="status">
            <span className="text-wrapper">
              Action :
              <br />
            </span>
            <span className="span">{props?.message?.sender?.nom}</span>
            <span className="text-wrapper-2">
              {" "}
              a changé le statut du <br />
              ticket{" "}
            </span>
            <span className="span">{props?.message?.messageUpdateStatut?.action?.idTicket}</span>
            <span className="text-wrapper-2"> de </span>
            <span style={{color : oldState.backgroundColor,  fontWeight: 600}}>
              "{oldState.nom}"
              <br />
            </span>
            <span className="text-wrapper-2">vers </span>
           
          
           <span style={{color : newState.backgroundColor,  fontWeight: 600}} > "{newState.nom}"</span>
           
            <span className="text-wrapper-3">&nbsp;</span>
          </p>
          {(cause !== undefined &&  cause !== null) ? <p className="div">
            <span className="text-wrapper-5">
              Cause :<br />
            </span>
            <span className="text-wrapper-2">{cause}</span>
          </p> : <></>}
          {(props?.message?.messageUpdateStatut?.note !== undefined &&  props?.message?.messageUpdateStatut?.note !== null &&  props?.message?.messageUpdateStatut?.note !== '' ) ?
          <p className="div">
            <span className="text-wrapper">
              Note :<br />
            </span>
            <span className="text-wrapper-2">
              {note}
              <br />

            </span>
          </p> : <></> }
        </div>
      );
    }
    else {
      return <></>
    }
  };

  function renderNewMessages() {
    const userId = ""; // Replace with the actual user ID

    // Find the index of the last message sent by the user
    const lastUserMessageIndex = props.messagesList
      ?.slice()
      .reverse()
      .findIndex((message) => message.sender.id === userId);

    // Calculate the start index for slicing
    const startIndex = lastUserMessageIndex !== -1 ? props.messagesList.length - lastUserMessageIndex : 0;

    // Slice the array based on the calculated start index
    const slicedMessages = props.messagesList?.slice(0, startIndex);

    // Map over the slicedMessages array and return JSX
    return (
      <>
        {slicedMessages?.map((message) => (
          <div key={message.id}>
            {/* Rest of your code */}
            <div style={{
              justifyContent: "flex-start",
              display: "flex",
              marginLeft: 0,
              color: 'var(--Base-Shade-2, #898E9E)',
              textAlign: 'left',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '18px',
            }}>
              <Avatar src={""} name={""} status="" sx={{ width: 18, height: 18, marginRight: 1 }} /> {''} {message.sender.nom} {''} {getTimeDifference(message.date)}
            </div>
            {/* Rest of your code */}
          </div>
        ))}
      </>
    );
  }

  // Call the function in your component where you want to render the messages
  const downloadFile = (obj) => {
    dispatch(getAttachementById(obj.id)).then((res) => {
      const content = res?.payload?.data?.data?.result?.contenu;
      const fileType = res?.payload?.data?.data?.result?.type;
      const fileName = res?.payload?.data?.data?.result?.nom;

      if (content && fileType && fileName) {
        const a = document.createElement("a");
        a.href = `data:${fileType};base64,${content}`;
        a.download = fileName;

        // Append the link to the document body
        document.body.appendChild(a);

        // Trigger the click event to start the download
        a.click();

        // Remove the link from the document after the download is initiated
        document.body.removeChild(a);
      } else {
       
      }
    });
  };

  return (
    <>
     <div style={{backgroundColor : 'white'}}>
      <Button
        sx={{
          display: 'flex',
          padding: '10px 14px',
          justifyContent: 'center',
          textTransform: 'none',
          alignItems: 'center',
          gap: 'var(--spacing-3xs, 2px)',
          borderRadius: '6px',
          height : '40px',
          background: 'var(--Base-White, #FFF)',
          boxShadow: '0px 0px 2px 0.15px rgba(16, 24, 40, 0.1), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          marginRight : '-100px'}}
        type="primary" onClick={openDialog} size="small">
        <IconButton size="small" >
          <ChatIcon />

        </IconButton>
        <Typography sx={{
          color: '#344054',
      
          fontFamily: "DM Sans",
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '24px',
        }}>Chat</Typography> 
        <NewMessages nbreMessagesNonLus={props.nbreMessagesNonLus} />
      </Button>
      <Box sx={{
        position: 'relative', bottom: 0, right: 0, 
        // width : '30%'
      }} >
        <Drawer
          open={open}
          variant='persistent'
          hideBackdrop
          // width="50%"
          anchor="bottom"
          // anchorOrigin={{
          //   vertical: 'bottom',
          //   horizontal: 'right',
          // }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: {
              height: '73%',
              width: '30%',
              position: 'fixed',
              marginLeft: "auto",
              // paddingTop: '50%', // Remove this, as it's no longer needed
              marginRight: 2,
              overflowY: 'auto',
              border: '1px solid #dad8d8',
              borderRadius: '12px',
            },
          }}
        >


          <GridContainer
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            direction='row'

          >
            <GridItem sx={1} sm={1} md={1} xl={1} xxl={1}>
              <Avatar sx={{ width: 24, height: 24 }}>

              </Avatar>
            </GridItem>
            <GridItem sx={10} sm={10} md={10} xl={10} xxl={10}> <H5 title={props.pageTitle}></H5></GridItem>


            <GridItem sx={1} sm={1} md={1} xl={1} xxl={1}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  // position: 'absolute',
                  // right: 8,
                  // top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </GridItem>
          </GridContainer>
          {/*  <DialogContent> */}

          {/* <Dialog open={props.open} fullWidth maxWidth='md'>
           
            <DialogContent>  */}


          <MainContainer responsive={true} className="chat-container">
            <ChatContainer className="chat-container">
              <MessageList autoScrollToBottomOnMount
              // loadingMore loadingMorePosition='top'
              >


                {
                  props.messagesList?.map((message) => {
                  
                    return (message.sender.id === userId ?
                      (<>  <div style={{
                        justifyContent: "flex-end",
                        display: "flex",
                        marginRight: 0,
                        color: 'var(--Base-Shade-2, #898E9E)',
                        textAlign: 'right',
                        fontFamily: "DM Sans",
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '18px',
                        
                      }}>{message.sender.nom} {''} {getTimeDifference(message.date)}</div >
                        <div style={{
                          justifyContent: "flex-end", display: "flex", marginRight: 0, marginLeft: 50
                        }}>

                          {/* <Message.Header sender={message.sender.nom}  sentTime={getTimeDifference(message.date)} style={{marginRight : 0}}/>
                          */}
                          {/* <br></br> */}

                          {message?.attachement?.type?.slice(0, message?.attachement?.type.indexOf('/')) === 'image' ?
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                borderRadius: '8px 0px 8px 8px',
                                backgroundColor: '#c6e3fa',
                                height: '40px',
                                marginTop: 10,
                                marginBottom: 10,
                                marginright: 0,
                              }}>
                              <Button onClick={previewImage}
                                style={{
                                  //width : '50px'
                                }}> <ImageIcon></ImageIcon>  {message?.attachement?.nom}</Button>
                              <PreviewImageComponent message={message} openPreviewImage={openPreviewImage} setOpenPreviewImage={setOpenPreviewImage} />
                            </div> : <></>}
                          {message?.attachement?.type?.slice(0, message?.attachement?.type.indexOf('/')) === 'audio' ?
                            <>
                              <AudioPlayerComponent message={message} /> </> : <></>}
                          {message?.attachement?.type === 'application/pdf' ?
                            <div style={{
                              gap: '4px',
                              borderRadius: '8px 0px 8px 8px',
                              backgroundColor: '#c6e3fa',
                              height: '100px',
                              marginTop: 10,
                              marginBottom: 10
                              //  marginLeft: -225,
                            }}>
                              <Button onClick={previewPdf}
                                style={{
                                }}> <PdfIcon></PdfIcon>  {message?.attachement?.nom}</Button>
                              <PreviewPDFComponent message={message} openPreview={openPreview} setOpenPreview={setOpenPreview} /></div> : <></>}
                          {/* doc + xls */}
                          {message?.attachement?.nom?.toLowerCase().split('.').pop() === 'docx' || message?.attachement?.nom?.toLowerCase().split('.').pop() === 'doc' || message?.attachement?.nom?.toLowerCase().split('.').pop() === 'xlsx' || message?.attachement?.nom?.toLowerCase().split('.').pop() === 'xls' ?
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                              borderRadius: '8px 0px 8px 8px',
                              backgroundColor: '#EBECEF',
                              height: '100px',
                              marginTop: 10,
                              marginBottom: 10
                              // marginLeft: -100,
                            }}>
                              <Button
                                onClick={() => downloadFile(message?.attachement)}
                                style={{
                                  //width : '50px'
                                }}> <TextSnippetIcon></TextSnippetIcon>  {message?.attachement?.nom}</Button>
                            </div> : <></>}


                          {((message?.attachement?.type === null || message?.attachement?.type === undefined)) && !(message?.contenu?.includes('Message_Update')) ?
                            <Message
                              model={{
                                type: 'text',
                                message: message.contenu,
                                sender: message.sender.id,
                                sentTime: message.date,
                                direction:
                                  message.sender.id === userId ? "outgoing" : "incoming",
                              }}
                              style={{

                                marginTop: 10,
                                marginBottom: 10,
                                marginLeft: -50,
                                maxWidth: '70%'
                              }}
                            /> :
                            <>
                              <div style={{
                                marginTop: 20,
                                marginRight: 0

                              }}>
                                <AssetMessageType message={message} />
                              </div> </>}
                        </div> </>)
                      : (
                        <>

                          <div style={{
                            justifyContent: "flex-start",
                            display: "flex",
                            marginLeft: 0,
                            color: 'var(--Base-Shade-2, #898E9E)',
                            textAlign: 'left',
                            fontFamily: "DM Sans",
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '18px',
                          }}> <Avatar src={""} name={""} status="" sx={{ width: 18, height: 18, marginRight: 1 }} /> {''} {message.sender.nom} {''} {getTimeDifference(message.date)}</div >
                          <div style={{ justifyContent: "flex-start", display: "flex", marginLeft: 0 }}>
                            {/* <Avatar src={""} name={""} status="" sx={{ width: 18, height: 18 }} />
                <Message.Header sender={message.sender.nom} sentTime={getTimeDifference(message.date)} /> */}
                            <br></br>
                            {message?.attachement?.type?.slice(0, message?.attachement?.type.indexOf('/')) === 'image' ?
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                borderRadius: '8px 0px 8px 8px',
                                backgroundColor: '#EBECEF',
                                height: '40px',
                                marginTop: 10,
                                marginBottom: 10
                                //  marginLeft: -100,
                              }}>
                                <Button onClick={previewImage}
                                  style={{
                                  }}> <ImageIcon></ImageIcon>  {message?.attachement?.nom}</Button>
                                <PreviewImageComponent message={message} openPreviewImage={openPreviewImage} setOpenPreviewImage={setOpenPreviewImage} />
                              </div> : <></>}

                            {message?.attachement?.type?.slice(0, message?.attachement?.type.indexOf('/')) === 'audio' ?
                              <>
                                {/* <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                              borderRadius: '8px 0px 8px 8px',
                              backgroundColor: '#EBECEF',
                              height: '100px',
                              marginTop: 30,
                              marginLeft: -50,
                            }}> */}

                                <AudioPlayerComponent message={message} />


                                {/* <IconButton> <PlayCircleOutlineIcon></PlayCircleOutlineIcon></IconButton> {message?.attachement?.nom} */}
                                {/* </div>  */}
                              </>
                              : <></>}
                            {message?.attachement?.type === 'application/pdf' ?
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                borderRadius: '8px 0px 8px 8px',
                                backgroundColor: '#EBECEF',
                                height: '100px',
                                marginTop: 10,
                                marginBottom: 10
                                // marginLeft: -100,
                              }}>
                                <Button onClick={previewPdf}
                                  style={{
                                    //width : '50px'
                                  }}> <PdfIcon></PdfIcon>  {message?.attachement?.nom}</Button>
                                <PreviewPDFComponent message={message} openPreview={openPreview} setOpenPreview={setOpenPreview} /></div> : <></>}
                            {/* doc + xls */}


                            {message?.attachement?.nom?.toLowerCase().split('.').pop() === 'docx' || message?.attachement?.nom?.toLowerCase().split('.').pop() === 'doc' || message?.attachement?.nom?.toLowerCase().split('.').pop() === 'xlsx' || message?.attachement?.nom?.toLowerCase().split('.').pop() === 'xls' ?
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                borderRadius: '8px 0px 8px 8px',
                                backgroundColor: '#EBECEF',
                                height: '100px',
                                marginTop: 10,
                                marginBottom: 10
                                // marginLeft: -100,
                              }}>
                                <Button
                                  onClick={() => downloadFile(message?.attachement)}
                                  style={{
                                    //width : '50px'
                                  }}> <TextSnippetIcon></TextSnippetIcon>  {message?.attachement?.nom}</Button>
                              </div> : <></>}


                            {((message?.attachement?.id === null || message?.attachement?.id === undefined)) && !(message?.contenu?.includes('Message_Update')) ? <Message
                              model={{
                                type: 'text',
                                message: message.contenu,
                                sender: message.sender.id,
                                sentTime: message.date,
                                direction:
                                  message.sender.id === userId ? "outgoing" : "incoming",
                              }}


                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                                marginLeft: 0,
                                maxWidth: '70%'
                              }}
                            /> : <>
                              <div style={{
                                marginTop: 20,
                              }}>
                                <AssetMessageType message={message}/>
                              </div>
                            </>

                            }

                            <br></br>



                          </div> </>
                      ));
                  })}

              </MessageList>


              {/* <InputToolbox style={{width:'100%'}}> */}
              {props.disableInput ? <></> :
                <MessageInput style={{ width: '100%', }}
                  // placeholder="Type message here"
                  attachButton={true}
                  sendButton={true}

                  onSend={(_, textContent) => handleSendMessage(textContent)}
                  onAttachClick={handleAttach}
                />
              }
              {/* <AttachmentButton onAttachClick={handleAttach}/>
        <SendButton />
      </InputToolbox> */}
            </ChatContainer>
          </MainContainer>
          <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleFileChange} />

          <ErrorBox severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autoHideDuration={1000} />
        </Drawer>

      </Box >
      </div>
    </>
  )
}
const AudioPlayerComponent = ({ message }) => {
  const [audio, setAudio] = useState();
  const [loading, setLoading] = useState(true)
  const [type, setType] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAttachementById(message.attachement.id)).then(unwrapResult).then((res) => {

      // let audioBuffer = await decode(res.data.data.result.contenu);
      setAudio(res?.data?.data?.result?.contenu)
      setType((res?.data?.data?.result?.type))
      setLoading(false)
    })
  }, [message])

  return (
    <>
      <div style={{
        marginTop: 10,
        // marginLeft: -100
      }}>

{loading ? (
              <LoaderComponent />
            ) : audio ? ( <audio controls>
          {/* <source src={`data:audio/ogg;base64,${audio}`} type="audio/mpeg" /> */}
          <source src={`data:audio/ogg;base64,${audio}`} type={type} />
          {/* <source src={`data:audio/mp3;base64,${audio}`} type="audio/mp3"/>
  <source src={`data:audio/ogg;base64,${audio}`} type="audio/ogg"/> */}
          Votre navigateur ne supporte pas l'élément audio
        </audio>   ) : (
              <p>Un problème est survenu lors du téléchargement du l'audio</p>
            )}

      </div>
    </>
  )
}
const PreviewImageComponent = ({ message, openPreviewImage, setOpenPreviewImage }) => {
  const [loading, setLoading] = useState(true)
  const [img, setImg] = useState();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenPreviewImage(false);
    setImg(null)
  };
  useEffect(() => {
    dispatch(getAttachementById(message.attachement.id)).then(unwrapResult).then((res) => {

      // let audioBuffer = await decode(res.data.data.result.contenu);
      setImg(res?.data?.data?.result?.contenu)
      setLoading(false)
      //setOpenPreview(true);
    })
  }, [message])
  return (
    <Dialog
      open={openPreviewImage} onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          minHeight: '900px',
          minWidth: '800px',
        },
      }}
    >

      <DialogContent>
        {loading ? (
        <LoaderComponent />
        ) : img ? (
          <img src={`data:image/jpeg;base64,${img}`} width= '700px' height= '820px' />
        ) : (
          <p>Un problème est survenu lors du téléchargement.</p>
        )}
      </DialogContent>
    </Dialog>
  )
}
const PreviewPDFComponent = ({ message, openPreview, setOpenPreview }) => {
  const [pdf, setPDF] = useState();
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenPreview(false);

  };
  useEffect(() => {
    dispatch(getAttachementById(message.attachement.id)).then(unwrapResult).then((res) => {

      // let audioBuffer = await decode(res.data.data.result.contenu);
      setPDF(res?.data?.data?.result?.contenu)
      setLoading(false)
      //setOpenPreview(true);
    })
  }, [message])
  return (
    <>
      {/* <div style={{ minWidth: '750px', minHeight: '1000px' , display : 'flex', alignContent : 'center'}}> */}
      <Dialog open={openPreview} onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            minHeight: '850px',
            minWidth: '750px',
          },
        }}
      >
        <DialogContent>
        {loading ? (
           <LoaderComponent />
          ) : pdf ? (
          <object
            data={`data:application/pdf;base64,${pdf && pdf}`}
            type="application/pdf"
            width='700px' height='820px'
          >

          </object>
 ) : (
  <p>Un problème est survenu lors du téléchargement du document</p>
)}
        </DialogContent>
      </Dialog>

      {/* </div> */}
    </>

  )
}
const LoaderComponent = () => {
  return (
    <><center>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
    </center>
   
    </>)
}