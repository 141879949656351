import { Badge, Typography } from "@mui/material";
import React, { useState } from "react";

const AttachementCounter = ({ nbreAttachementsNonLus }) => {
    
     if (nbreAttachementsNonLus > 0) {
    
      return (
        <Badge
          color="primary"
          sx={{
            display: 'flex',
            ml: 1,
            width: 25,
            height: 20,
            padding: '0px 8px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '12px',
            background: 'var(--secondary-400-base, #1671D9)',
          }}>
          <Typography sx={{ color: 'white' }} >{nbreAttachementsNonLus}</Typography>
        </Badge>
      );
    }
    else {
      return <></>
    }

  };

export default  AttachementCounter;