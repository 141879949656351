
import React from 'react'

import SvgIcon from '@mui/material/SvgIcon';
export default function HomeIcon () {
    return (
       
        <SvgIcon>
   <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="icon / home-alt">
<path id="icon" fillRule="evenodd" 
clipRule="evenodd"
 d="M11.9996 3.04167C10.8145 2.15278 9.18483 2.15278 7.99965 3.04167L2.27251 7.33702C1.35474 8.02535 0.855418 9.13725 0.950689 10.2805L1.47816 16.6102C1.62213 18.3378 3.06635 19.6667 4.79998 19.6667H15.1993C16.9329 19.6667 18.3772 18.3378 18.5211 16.6102L19.0486 10.2805C19.1439 9.13725 18.6446 8.02535 17.7268 7.33702L11.9996 3.04167ZM8.99965 4.375C9.59224 3.93056 10.4071 3.93056 10.9996 4.375L16.7268 8.67035C17.1857 9.01452 17.4353 9.57047 17.3877 10.1421L16.8602 16.4717C16.7882 17.3356 16.0661 18 15.1993 18H12.572L12.7404 15.9787C12.874 14.3752 11.6087 13 9.99965 13C8.39062 13 7.12525 14.3752 7.25887 15.9787L7.42731 18H4.79998C3.93316 18 3.21105 17.3356 3.13907 16.4717L2.6116 10.1421C2.56396 9.57047 2.81362 9.01452 3.27251 8.67035L8.99965 4.375ZM11.0795 15.8403L10.8995 18H9.09976L8.91978 15.8403C8.86713 15.2085 9.36569 14.6667 9.99965 14.6667C10.6336 14.6667 11.1322 15.2085 11.0795 15.8403Z"
  fill="currentColor"/>
</g>
</svg>

     </SvgIcon>
    )
}