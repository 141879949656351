
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function FullScreenOffIcon() {
    return (
       
        <SvgIcon>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M6.66661 12.1757L1.06868 17.7736L2.24719 18.9521L7.84512 13.3542L6.66661 12.1757Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.7172 13.3039L6.7172 16.5954L8.38387 16.5954L8.38387 12.4706C8.38387 12.0104 8.01077 11.6373 7.55054 11.6373L3.42575 11.6373L3.42575 13.3039H6.7172Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.3543 7.8448L18.9522 2.24687L17.7737 1.06836L12.1758 6.66629L13.3543 7.8448Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.3037 6.71756L13.3037 3.42611L11.637 3.42611L11.637 7.5509C11.637 8.01113 12.0101 8.38423 12.4703 8.38423L16.5951 8.38423L16.5951 6.71756H13.3037Z" fill="#2A66C6"/>
</svg>
     </SvgIcon>
    )
}


