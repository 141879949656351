import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

  
export const getAttachementList = createAsyncThunk('attachement/getAttachementList', 
(data) => {
  
    const tokenStored = localStorage.getItem('TokenStored')
    const timezone = localStorage.getItem('TZ')
    const view = data.view ? data.view : false;
    return axios.get(BaseUrl + `/SAV/getAttachements?idTicket=${data.idTicket}&isConsulter=${view}&page=${data.page}&pageSize=${data.pageSize}`,
    {headers: {
        'Content-Type': 'application/json',
        'Authorization': tokenStored,
        'Platform': 'web',
        'timezone' : timezone
      }});
   

   
  });
export const getAttachementById = createAsyncThunk('attachement/getAttachementById', 
  (id) => {
    const tokenStored = localStorage.getItem('TokenStored')
    const timezone = localStorage.getItem('TZ')
      return axios.get(BaseUrl + `/SAV/getAttachementParId?idAttachement=${id}`,
       {headers: {
        'Content-Type': 'application/json',
        'Authorization': tokenStored,
        'Platform': 'web',
        'timezone' : timezone
      }});
     
    });
    export const getNbreAttachementNotSeen = createAsyncThunk('attachement/getNbreAttachementNotSeen', 
  (id) => {
    const tokenStored = localStorage.getItem('TokenStored')
    const timezone = localStorage.getItem('TZ')
      return axios.get(BaseUrl + `/SAV/getNbrAttachementsNonLus?idTicket=${id}`,
       {headers: {
        'Content-Type': 'application/json',
        'Authorization': tokenStored,
        'Platform': 'web',
        'timezone' : timezone
      }});
     
  
     
    });
    export const deleteAttachementById = createAsyncThunk('attachement/deleteAttachementById', 
    (id) => {
      const tokenStored = localStorage.getItem('TokenStored');
      const timezone = localStorage.getItem('TZ');
      
      return axios.delete(BaseUrl + `/SAV/supprimerAttachement?idAttachement=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': tokenStored,
          'Platform': 'web',
          'timezone': timezone
        }
      });
    });    
export const AddAttachment = createAsyncThunk('attachement/AddAttachment',
async (data, thunkAPI) => {
   
    try {
  
    const tokenStored = localStorage.getItem('TokenStored')
    const timezone = localStorage.getItem('TZ')
    const res = await axios.post(BaseUrl + 
        `/SAV/ajouterAttachementTicket?idTicket=${data.idTicket}`,
          data.array,
         {
            headers: {
            Accept: 'application/json',  
            'Content-Type': 'multipart/form-data',
            'Authorization': tokenStored,
            'Platform': 'web',
            'timezone' : timezone,
          }
         },
         
          );
    return res}
    catch(err)
    {
        return thunkAPI.rejectWithValue({ error: err.message })
    }     
})  
const initialState = {
    loading : false,
    data : [],
    attachements : [],
    attachement : null,
    counter : null,
    error: "",
};
const attachementsSlice = createSlice({
    name: 'attachement',
    initialState
    ,
    extraReducers: (builder) => {
        
        builder.addCase(getAttachementList.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAttachementList.fulfilled, (state, action) => {
            state.loading = false;
            state.attachements = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(getAttachementList.rejected, (state, action) => {
            state.loading = false;
            state.attachements = [];
            state.error = action.error.message;
        }) 
        builder.addCase(getAttachementById.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAttachementById.fulfilled, (state, action) => {
            state.loading = false;
            state.attachement = action.payload.data.result;
            state.error = "";
        })
        builder.addCase(getAttachementById.rejected, (state, action) => {
            state.loading = false;
            state.attachement = null;
            state.error = action.error.message;
        }) 
        builder.addCase(deleteAttachementById.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteAttachementById.fulfilled, (state, action) => {
            state.loading = false;
            state.attachement = null;
            state.error = "";
        })
        builder.addCase(deleteAttachementById.rejected, (state, action) => {
            state.loading = false;
            state.attachement = null;
            state.error = action.error.message;
        }) 
        builder.addCase(getNbreAttachementNotSeen.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getNbreAttachementNotSeen.fulfilled, (state, action) => {
            state.loading = false;
            state.counter = action.payload.data.result;
            state.error = "";
        })
        builder.addCase(getNbreAttachementNotSeen.rejected, (state, action) => {
            state.loading = false;
            state.counter = null;
            state.error = action.error.message;
        }) 
        
}
});
export default attachementsSlice.reducer;