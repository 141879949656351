import { createSlice,  createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

export const fetchChaines = createAsyncThunk('chaine/fetchChaines', 
() => {
    const tokenStored = localStorage.getItem('TokenStored')
    const timezone = localStorage.getItem('TZ')
    return axios.get(BaseUrl + `/GMPV/getChaines?page=${1}&pageSize=${1000}`,
     {headers:  {
        'Content-Type': 'application/json',
        'Authorization': tokenStored,
        'Platform': 'web',
        'timezone' : timezone,
      }});
   

   
  });
const initialState = {
    loading : false,
    data : [],
    chaines : [],
    error: "",
};
const chainesSlice = createSlice({
    name: ' chaine',
    initialState
    ,
    extraReducers: (builder) => {
        
        builder.addCase(fetchChaines.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchChaines.fulfilled, (state, action) => {
            state.loading = false;
            state.chaines = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(fetchChaines.rejected, (state, action) => {
            state.loading = false;
            state.chaines = [];
            state.error = action.error.message;
        }) 
}
});
export default chainesSlice.reducer;