import React from "react";
import PropTypes from "prop-types";
/**
 * The TabContainer component is a wrapper that renders its children inside a div.
 */

export const TabContainer = props => <div style={{marginLeft : '10px', marginTop : '10px'}}>{props.children}</div>;

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default TabContainer;
