import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";


import { Tabs, Tab, IconButton, Menu, MenuItem, Popper } from "@mui/material";

import TabContainer from "./TabContainer";
import { Box } from '@mui/material';
import { NavContext } from '../../App';
import { Colors } from "../Utility/Colors";
import SAVIcon from "../IconComponents/SAVIcon";
import EditSAVTicket from "../../Pages/SAV/TicketDetails/Edit/EditSAVTicket";
import ViewSAVTicket from "../../Pages/SAV/TicketDetails/View/ViewSAVTicket";
import { useDispatch, useSelector } from "react-redux";
import { removeTab, setLastActiveTab } from "../../ReduxTLK/features/Tabs/TabsSlice";
import FullScreenOnIcon from "../IconComponents/FullScreenOnIcon";
import ChevronRightIcon from "../IconComponents/ChevronRightIcon";
import ChevronLeftIcon from "../IconComponents/ChevronLeftIcon";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import H4 from "../TypoGraphy/H4";
import CloseIcon from '@mui/icons-material/Close';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import PerfectScrollbar from 'react-perfect-scrollbar';
import FullScreenOffIcon from "../IconComponents/FullScreenOffIcon";
import { Persistor } from "../..";
import { resetEditTicketDraftByIndex, resetNewTicketDraft } from "../../ReduxTLK/features/SAV/NewTicketDraftSlice";
import signalRService from "../Utility/SignalRConnection";
import New from "../../Pages/SAV/AddTicket/NewSAVTicket";

import RedDotIcon from "../IconComponents/RedDotIcon";
import GenericDialogAddTicketSaveConfirmation from "../Dialog/GenericDialogAddTicketSaveConfirmation";
import GenericDialogEditTicketSaveConfirmation from "../Dialog/GenericDialogEditTicketSaveConfirmation";


export default function TabsDemo({
    fullScreen,
    setFullScreen,
    tabs,
    reloadTab,
    setTabs,
    setAddTab,
    selectedTab,
    onClose,
    tabsProps = { indicatorColor: "primary" },
    ...rest
}) {
    // const { height, width } = useWindowDimensions();
    const dispatch = useDispatch()
    const context = React.useContext(NavContext)
    const [activetabs, setActiveTabs] = useState(context.tabs);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
    const [openCloseEditConfirmation, setOpenCloseEditConfirmation] = useState(false);
    const [tabToBeDelete, setTabToBeDelete] = useState()
    const moreVertButtonRef = useRef(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleSelect = (selectedTabId) => {
        context.setActiveTab(selectedTabId);
        dispatch(setLastActiveTab(selectedTabId));
        if (selectedTabId !== 3 && selectedTabId !== 2) {
            context.setRoomId(selectedTabId?.substring(11))
            signalRService.onJoinRoom(selectedTabId?.substring(11));
        }

    };

    const opened_tabs = useSelector((state) => state.tabs.tabs);
    const last_opened = useSelector((state) => state.tabs.lastActiveTab);

    useEffect(() => {
        // setTimeout(() => {
       
        if (opened_tabs?.length > 0) {
            const t = opened_tabs?.map((tab, i) => {

                if (tab.id === 2) {

                    const existingAddTicket = tabs.findIndex((add) => add.id === 2);

                    if (existingAddTicket === -1) {
                        tab.component = <New />;
                        tab.closeable = true;
                        tab.icon = <SAVIcon />;
                        return tab;
                    }

                }
                if ((tab.module === 'SAV' && tab.action === 'Edit') || (tab.module === 'SAV' && tab.action === 'View')) {
                    // Check if tab ID already exists in the tabs array
                    const existingTabIndex = tabs.findIndex((existingTab) => existingTab.id === tab.id);

                    if (existingTabIndex === -1) {
                        const modifiedTab = { ...tab, component: tab.action === 'Edit' ? <EditSAVTicket id={tab.identifier} /> : <ViewSAVTicket id={tab.identifier} />, closeable: true, icon: <SAVIcon /> };
                        // If ID doesn't exist, modify and return the tab
                        // tab.component = tab.action === 'Edit' ? <EditSAVTicket id={tab.identifier} /> : <ViewSAVTicket id={tab.identifier} />;
                        // tab.closeable = true;
                        // tab.icon = <SAVIcon />;
                        return modifiedTab;

                    } else {
                        // If ID exists, remove it from the tabs array to prevent duplicates

                       
                    }
                }

                // Return null if conditions aren't met
                // return null;
            })
            //t.filter((item) => !duplicates.includes(item))
            setActiveTabs([...tabs, ...t].filter((item) => item !== undefined));
            setTabs([...tabs, ...t].filter((item) => item !== undefined));
            if (last_opened === 3 || last_opened === 2) {
                context.setActiveTab(parseInt(last_opened));
                dispatch(setLastActiveTab(parseInt(last_opened)));
            }
            else {

                context.setActiveTab(last_opened ? last_opened : context.activeTab);
                dispatch(setLastActiveTab(last_opened ? parseInt(last_opened) : context.activeTab));
            }


        } else {
            setTabs(tabs);
        }
        // }, 1000);
    }, []);
    const [lastOpenedTabId, setLastOpenedTabId] = useState(null);

    /******************** */


    /******************* */

    const newTicketDraft = useSelector((state) => state.draftNewTicket.newTicketDraft);
   // const dirtyNewTicketDraft = useSelector((state) => state.draftNewTicket.dirty);
   const dirtyNewTicketDraft = useSelector((state) => {
   
    return state.draftNewTicket.dirty;
  });
  useEffect(() => {
    // callback logic  depends on dirtyNewTicketDraft
    // It will run every time dirtyNewTicketDraft changes
  
  }, [dirtyNewTicketDraft]);
    const handleChange = useCallback((event, activeTab) => {
      
        if (lastOpenedTabId === 2) {
           // context.setCancelledReload(true)
            Persistor.persist()
        }
        setLastOpenedTabId(context.activeTabRef.current);
        dispatch(setLastActiveTab(activeTab))
        context.setActiveTab(activeTab)
        Persistor.persist()
        if (activeTab !== 3 && activeTab !== 2) {
            context.setRoomId(activeTab?.substring(11))
            signalRService.onJoinRoom(activeTab?.substring(11));
        }


    }, []);

    /* The `handleClose` function is a callback function that is used to handle the closing of a tab. */
    const editedTicketDraft = useSelector((state) => state.draftNewTicket.editedTicketDraft);
    const [dirtyTabState, setDirtyTabState ] = useState(false)
    const isDirtyEditTab = (id) => {
      
      
        const dirtyEditTabIndex = editedTicketDraft.findIndex((item) => item.id === id);
       
        return dirtyEditTabIndex !== -1;
       
    }
    useEffect(() => {
     
    
        const updateDirtyTabState = async () => {
            const tabIndex = context.tabs.findIndex(
                tab => tab.id === context.activeTab
            );
           
            const tab = context.tabs.filter((tab, index) => index === tabIndex);
           
            // Assuming isDirtyEditTab returns a Promise
            if (tab && tab?.length > 0 && tab[0]?.identifier !== undefined) {
                const result = isDirtyEditTab(tab[0].identifier);
                setDirtyTabState(result);
            }
        };
    
        updateDirtyTabState();
    }, [editedTicketDraft]);
  
    const handleClose = useCallback(

        (event, tabToDelete) => {
           
            setTabToBeDelete(tabToDelete)
            Persistor.persist()
            event.stopPropagation();
            if (tabToDelete.id === 2) {

                if (dirtyNewTicketDraft) {
                    setOpenCloseConfirmation(true)
                    return;
                }

                else {
                    const tabToDeleteIndex = context.tabs.findIndex(
                        tab => tab.id === tabToDelete.id
                    );
                    const updatedTabs = context.tabs.filter((tab, index) => {
                        return index !== tabToDeleteIndex;
                    });
                    context.setTabs(updatedTabs);


                    const previousTab =
                        context.tabs[tabToDeleteIndex - 1] ||
                        context.tabs[tabToDeleteIndex + 1] ||
                        {};
                    setActiveTabs(updatedTabs);
                    context.setActiveTab(previousTab.id);

                    dispatch(setLastActiveTab(previousTab.id));
                    dispatch(resetNewTicketDraft())
                    dispatch(removeTab(tabToDelete))
                    Persistor.persist()
                    return;
                }

            }

            //*************************/
            else {

               
                if(dirtyTabState){
                   setOpenCloseEditConfirmation(true)
                   return;
                }
                else {
                    // const dirtyEditIndex = editedTicketDraft.findIndex((item) => item.id === tabToDelete.identifier);
                    // dispatch(resetEditTicketDraftByIndex(dirtyEditIndex))
                    const tabToDeleteIndex = context.tabs.findIndex(
                        tab => tab.id === tabToDelete.id
                    );
    
                    const updatedTabs = context.tabs.filter((tab, index) => {
                        return index !== tabToDeleteIndex;
                    });
    
                    context.setTabs(updatedTabs);
    
    
                    const previousTab =
                        context.tabs[tabToDeleteIndex - 1] ||
                        context.tabs[tabToDeleteIndex + 1] ||
                        {};
                    if (previousTab.id !== 2 && previousTab.id !== 3) {
                        context.setRoomId(previousTab.id?.substring(11))
                        signalRService.onJoinRoom(previousTab.id?.substring(11));
                    }
                    setActiveTabs(updatedTabs);
    
                    context.setActiveTab(previousTab.id);
                    dispatch(setLastActiveTab(previousTab.id));
                    dispatch(removeTab(tabToDelete))
                    Persistor.persist()
                }
             
            }

        },
        [context.tabs, dirtyNewTicketDraft, dirtyTabState, editedTicketDraft]
    );
    const handleFullScreenOn = () => {
        setFullScreen(!fullScreen);

    }
    const handleFullScreenOff = () => {
        setFullScreen(!fullScreen);

    }
    const [firstThreeTabs, remainingTabs] = useMemo(() => {
        return tabs.reduce((acc, tab, index) => {

            const [firstPart, secondPart] = acc;
            return tab.id === 3 ? [[...firstPart, tab], secondPart] : [firstPart, [...secondPart, tab]];
        }, [[], []]);
    }, [tabs]);


    return (
        <>




            <Tabs
                value={context.activeTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                ScrollButtonComponent={(props) => {
                    if (props.direction === "left" && !props.disabled) {
                        return (
                            <IconButton {...props} variant='rounded' sx={{
                                marginLeft: "7px",
                                marginTop: '12px',
                                borderRadius: '43px',
                                background: '#FFF',
                                border: '1px solid #F4F7FE',
                                boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.25)',
                                width: '30px',
                                height: '30px',
                            }}>
                                <ChevronLeftIcon />
                            </IconButton>
                        );
                    } else if (props.direction === "right" && !props.disabled) {
                        return (
                            <>
                                <IconButton {...props} sx={{
                                    marginLeft: "7px",
                                    marginTop: '12px',
                                    borderRadius: '43px',
                                    background: '#FFF',
                                    border: '1px solid #F4F7FE',
                                    boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.25)',
                                    width: '30px',
                                    height: '30px',
                                }}>
                                    <ChevronRightIcon color="#007474" icon="arrow-right" />
                                </IconButton>
                                <Popper open={open} anchorEl={moreVertButtonRef.current} transition>
                                    {({ TransitionProps }) => (
                                        <PerfectScrollbar
                                        // options={{ wheelPropagation: false }}
                                        >
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                    ...TransitionProps,
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={open}
                                                onClose={handleCloseMenu}
                                                PaperProps={{
                                                    style: {
                                                        borderRadius: '16px',
                                                        marginTop: '92px',
                                                        maxHeight: 'auto',
                                                        width: '30ch',
                                                        // scroll: 'smooth',
                                                        // overflowY: 'auto',
                                                        //  overflowY: 'auto', // Enable scrolling when content exceeds height
                                                        // Improved scroll behavior: 
                                                        // scrollbarWidth: '0.1px', // Adjust scrollbar width to your preference
                                                        // scrollbarColor: 'rgba(0, 0, 0, 0.3)', // Customize scrollbar color
                                                    },
                                                }}
                                            >

                                                <MenuItem><H4 title={'Pages ouvertes'}></H4></MenuItem>
                                                {/* {tabs.map((option) => (

                                                <MenuItem key={option.id} value={option.id} onClick={() => handleSelect(option.id)}>
                                                    {option.label}
                                                    <IconButton edge="end"
                                                        size="small"
                                                        aria-label="close"
                                                        sx={{
                                                            marginLeft: 20,
                                                            marginRight: -10,
                                                            width: '1px'
                                                        }}
                                                        onClick={event => handleClose(event, option)}>
                                                        <CloseIcon fontSize='small' />
                                                    </IconButton>
                                                </MenuItem>
                                            ))} */}
                                                {firstThreeTabs.map((option) => (
                                                    <MenuItem key={option.id} value={option.id} onClick={() => handleSelect(option.id)} sx={{ fontFamily: 'DM Sans' }}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                                {remainingTabs.map((option) => (
                                                    <MenuItem key={option.id} value={option.id} onClick={() => handleSelect(option.id)} sx={{ fontFamily: 'DM Sans' }}>
                                                        <GridContainer
                                                            display='flex'
                                                            alignItems='space-between'
                                                            direction='row'
                                                        >
                                                            <GridItem sx={11} sm={11} md={11} xl={11} xxl={11}>
                                                                {option.label}
                                                            </GridItem>
                                                            <GridItem sx={1} sm={1} md={1} xl={1} xxl={1}>
                                                                <IconButton sx={{ display: 'flex', alignItems: 'flex-end' }} size="small" aria-label="close" onClick={event => handleClose(event, option)}>
                                                                    <CloseIcon fontSize='small' />
                                                                </IconButton>
                                                            </GridItem>
                                                        </GridContainer>


                                                    </MenuItem>
                                                ))}

                                            </Menu>
                                        </PerfectScrollbar>
                                    )}
                                </Popper>
                                <IconButton
                                    ref={moreVertButtonRef}
                                    zindex="100"
                                    sx={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        marginTop: '12px',
                                        borderRadius: '43px',
                                        background: '#FFF',
                                        border: '1px solid #F4F7FE',
                                        boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.25)',
                                        width: '30px',
                                        height: '30px',
                                    }}
                                    onClick={handleClick}
                                >
                                    <MoreHorizIcon />
                                </IconButton>
                            </>
                        );
                    } else {
                        return null;
                    }
                }}

                sx={{
                    // height: '(calcHeight - 10%)',
                    // minHeight : '(calcHeight - 10%)',
                    // flexGrow: 1,
                    '& .MuiTabs-indicator':
                        { display: 'none' },
                    backgroundColor: `${Colors.white}`,
                    fontWeight: 'bold',
                    fontStyle: 'bold',
                    // borderCollapse : 'separate',
                    // borderSpacing : '20px',
                    //position: 'fixed'
                }}
            >

                {tabs && tabs.map(tab => (
                    context.activeTab === tab.id ? (
                        tab.id === 1 ? (<>
                            <Tab
                                key={tab.id}
                                component={Box}
                                value={tab.id}
                                maxWidth='5px'
                                sx={{

                                    backgroundColor: "#F4F7FE",
                                    borderCollapse: 'separate',
                                    broderShadow: '0px -2px 2px 0px rgba(16, 24, 40, 0.08)',
                                    border: '1px solid #ededf0',
                                    textTransform: 'none',
                                    //  borderSpacing : '10px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxHeight: '3px',
                                    height: '3px',
                                    // weight: '5px',
                                    '&.MuiButtonBase-root': {
                                        //  padding: "5px, 5px, 5px, 0px",
                                        marginleft: "5px",
                                        marginRight: "5px",
                                        marginBottom: "-5px",
                                        marginTop: "8px",
                                        borderTopLeftRadius: '16px',
                                        borderTopRightRadius: '16px',
                                        broderShadow: 50,
                                        width: '5px',
                                        maxWidth: '5px',
                                        height: '20px',
                                        minHeight: '40px',
                                        maxHeight: '20px',

                                    }
                                }}
                                icon={tab.icon}
                                iconPosition="center"
                                iconwidth='5px'> </Tab>
                        </>) :
                            <Tab
                                key={tab.id}
                                component={Box}
                                value={tab.id}

                                // renderValue={(value) => <Box id={value} />}
                                // labelMargin='1px'
                                label={
                                    typeof tab.label === "string" ? (
                                        <span style={{ marginLeft: 10, fontFamily: 'DM Sans' }}>
                                            {" "}
                                            {tab.label}
                                            {!fullScreen && <IconButton
                                                component="div"
                                                onClick={event => handleFullScreenOn(event, tab)}
                                                sx={{ color: '#2A66C6' }}
                                            >
                                                <FullScreenOnIcon /> </IconButton>}
                                            {fullScreen && <IconButton
                                                component="div"
                                                onClick={event => handleFullScreenOff(event, tab)}
                                                sx={{ color: '#2A66C6' }}
                                            >
                                                <FullScreenOffIcon /> </IconButton>}


                                            {tab.closeable && (
                                                <IconButton
                                                    component="div"

                                                    onClick={event => handleClose(event, tab)}
                                                    sx={{ color: '#2A66C6' }}
                                                >
                                                    <CloseIcon fontSize='small' />
                                                </IconButton>
                                            )}
                                            {tab.id === 2 && dirtyNewTicketDraft === true && (
                                                 <IconButton
                                                 component="div"
                                                 sx={{ color: '#2A66C6' , marginTop: '12px', marginRight: '-23px' }}
                                             >
                                                 <RedDotIcon fontSize='small' />
                                             </IconButton>
                                            )}
                                             {tab.id !== 2 && tab.id !== 1 && isDirtyEditTab(tab.identifier) === true && (
                                                 <IconButton
                                                 component="div"
                                                 sx={{ color: '#2A66C6' , marginTop: '12px', marginRight: '-23px' }}
                                             >
                                                 <RedDotIcon fontSize='small' />
                                             </IconButton>
                                            )}
                                        </span>
                                    ) : (
                                        null
                                    )
                                }
                                sx={{

                                    backgroundColor: "#F4F7FE",
                                    borderCollapse: 'separate',
                                    broderShadow: '0px -2px 2px 0px rgba(16, 24, 40, 0.08)',
                                    border: '1px solid #ededf0',
                                    textTransform: 'none',
                                    borderSpacing: '10px',
                                    justifyContent: 'right',
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxHeight: '3px',
                                    height: '3px',
                                    '&.MuiButtonBase-root': {
                                        //  padding: "5px, 5px, 5px, 0px",
                                        marginleft: "5px",
                                        marginRight: "5px",
                                        marginBottom: "-5px",
                                        marginTop: "8px",
                                        borderTopLeftRadius: '16px',
                                        borderTopRightRadius: '16px',
                                        broderShadow: 50,
                                        // width : '100px',
                                        height: '20px',
                                        minHeight: '40px',
                                        maxHeight: '20px',

                                    }
                                }}
                                icon={tab.icon}
                                iconPosition="start"
                                iconwidth='5px'> </Tab>

                    ) : (
                        tab.id === 1 ? (<>
                            <Tab
                                key={tab.id}
                                component={Box}

                                // boxShadow={5}

                                // borderCollapse='separate'
                                // borderSpacing='50px'
                                value={tab.id}
                                sx={{

                                    backgroundColor: "white",
                                    borderCollapse: 'separate',
                                    broderShadow: '0px -2px 2px 0px rgba(16, 24, 40, 0.08)',
                                    border: '1px solid #ededf0',
                                    textTransform: 'none',
                                    //  borderSpacing : '10px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxHeight: '3px',
                                    height: '3px',
                                    // weight: '5px',
                                    '&.MuiButtonBase-root': {
                                        //  padding: "5px, 5px, 5px, 0px",
                                        marginleft: "5px",
                                        marginRight: "5px",
                                        marginBottom: "-5px",
                                        marginTop: "8px",
                                        borderTopLeftRadius: '16px',
                                        borderTopRightRadius: '16px',
                                        broderShadow: 50,
                                        width: '5px',
                                        maxWidth: '5px',
                                        height: '20px',
                                        minHeight: '40px',
                                        maxHeight: '20px',

                                    }
                                }}
                                icon={tab.icon}
                                iconPosition="center"
                                iconwidth='5px'
                            />
                        </>) :
                            <Tab
                                key={tab.id}
                                component={Box}

                                // boxShadow={5}

                                // borderCollapse='separate'
                                // borderSpacing='50px'
                                value={tab.id}
                                label={
                                    typeof tab.label === "string" ? (
                                        <span style={{ marginLeft: 10, fontFamily: 'DM Sans' }}>
                                            {" "}
                                            {tab.label}
                                            {tab.closeable && (
                                                <IconButton
                                                    component="div"

                                                    onClick={event => handleClose(event, tab)}
                                                >
                                                    <CloseIcon fontSize='small' />
                                                </IconButton>
                                            )}
                                             {tab.id === 2 && dirtyNewTicketDraft === true && (
                                                 <IconButton
                                                 component="div"
                                                 sx={{ color: '#2A66C6' , marginTop: '12px', marginRight: '-23px' }}
                                             >
                                                 <RedDotIcon fontSize='small' />
                                             </IconButton>
                                            )}
                                            {tab.id !== 2 && tab.id !== 1 && isDirtyEditTab(tab.identifier) === true && (
                                                 <IconButton
                                                 component="div"
                                                 sx={{ color: '#2A66C6' , marginTop: '12px', marginRight: '-23px' }}
                                             >
                                                 <RedDotIcon fontSize='small' />
                                             </IconButton>
                                            )}
                                        </span>
                                    ) : (
                                        null
                                    )
                                }

                                sx={{
                                    backgroundColor: "white",
                                    borderCollapse: 'separate',
                                    broderShadow: '0px -2px 2px 0px rgba(16, 24, 40, 0.08)',
                                    border: '1px solid #ededf0',
                                    textTransform: 'none',
                                    borderSpacing: '10px',
                                    justifyContent: 'right',
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxHeight: '3px',
                                    height: '3px',
                                    '&.MuiButtonBase-root': {
                                        //  padding: "5px, 5px, 5px, 0px",
                                        broderShadow: '0px -2px 2px 0px rgba(16, 24, 40, 0.08)',
                                        marginleft: "5px",
                                        marginRight: "5px",
                                        marginBottom: "-5px",
                                        marginTop: "8px",
                                        borderTopLeftRadius: '16px',
                                        borderTopRightRadius: '16px',
                                        // width : '100px',
                                        height: '20px',
                                        minHeight: '40px',
                                        maxHeight: '20px',
                                    }
                                }}
                                icon={tab.icon}
                                iconPosition="start"
                                iconwidth='20px'
                            />)
                ))
                }

            </Tabs>
            <GenericDialogAddTicketSaveConfirmation open={openCloseConfirmation}
             setOpen={setOpenCloseConfirmation} tabToBeDelete={tabToBeDelete} setActiveTabs={setActiveTabs} />
              <GenericDialogEditTicketSaveConfirmation open={openCloseEditConfirmation}
             setOpen={setOpenCloseEditConfirmation} tabToBeDelete={tabToBeDelete} setActiveTabs={setActiveTabs} />
            {tabs.map(tab =>
                context.activeTab === tab.id ? (

                    <TabContainer key={tab.id}


                    >

                        {tab.component}
                    </TabContainer>
                ) : null
            )}



        </>
    );
};

TabsDemo.propTypes = {

    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            id: PropTypes.number.isRequired,
            component: PropTypes.object.isRequired,
            closeable: PropTypes.bool.isRequired
        }).isRequired
    ).isRequired
};


