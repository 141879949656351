/**
 * Imports React and PropTypes libraries.
 * Imports Grid component from '@material-ui/core' library.
 *
 * @param {string} props.className - The CSS class name of the GridItem component.
 * @param {React.Component} props.children - The children components of the GridItem component.
 * @returns {React.Component} The GridItem component.
 */
import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

export default function GridItem(props) {
  /**
   * Destructure props to extract className and children.
   */
  const { className, children, ...rest } = props;

  return (
    <Grid item {...rest}
    //  sx={{ grid: {
    //   padding: "20 15px !important"
    // }}}
     className={className}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  /**
   * Prop type for className.
   */
  className: PropTypes.string,
  /**
   * Prop type for children.
   */
  children: PropTypes.node,
};