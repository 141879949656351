import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

export const fetchPventes = createAsyncThunk('pvente/fetchPventes',
    (idChaine) => {
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')
        return axios.get(BaseUrl + `/GMPV/getListPV?idChaine=${idChaine}&page=${1}&pageSize=${1000}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokenStored,
                    'Platform': 'web',
                    'timezone': timezone
                }
            });



    });
export const fetchPventesIntervenant = createAsyncThunk('pvente/fetchPventesIntervenant',
    (idPv) => {
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')
        return axios.get(BaseUrl + `/SAV/getListIntervenantPV?idPv=${idPv}&page=${1}&pageSize=${1000}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokenStored,
                    'Platform': 'web',
                    'timezone': timezone
                }
            });



    });
export const fetchListPventesIntervenant = createAsyncThunk('pvente/fetchListPventesIntervenant',
    (list) => {

        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')
        return axios.post(BaseUrl + `/SAV/getListIntervenantsPVs?page=${1}&pageSize=${1000}`, list,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokenStored,
                    'Platform': 'web',
                    'timezone': timezone
                }
            });



    });

const initialState = {
    loading: false,
    data: [],
    pventes: [],
    pventesIntervenants: [],
    error: "",
};
const pventesSlice = createSlice({
    name: 'pointDeVente',
    initialState
    ,
    extraReducers: (builder) => {

        builder.addCase(fetchPventes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchPventes.fulfilled, (state, action) => {
            state.loading = false;
            state.pventes = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(fetchPventes.rejected, (state, action) => {
            state.loading = false;
            state.pventes = [];
            state.error = action.error.message;
        })
        builder.addCase(fetchPventesIntervenant.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchPventesIntervenant.fulfilled, (state, action) => {
            state.loading = false;
            state.pventesIntervenants = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(fetchPventesIntervenant.rejected, (state, action) => {
            state.loading = false;
            state.pventesIntervenants = [];
            state.error = action.error.message;
        })
        builder.addCase(fetchListPventesIntervenant.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchListPventesIntervenant.fulfilled, (state, action) => {
            state.loading = false;
            state.pventesIntervenants = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(fetchListPventesIntervenant.rejected, (state, action) => {
            state.loading = false;
            state.pventesIntervenants = [];
            state.error = action.error.message;
        })

    }
});
export default pventesSlice.reducer;