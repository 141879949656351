import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function AlertIcon () {
    return (
    <SvgIcon>
<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2363 18.485H16.0697V21.3184H13.2363V18.485ZM13.2363 7.15169H16.0697V15.6517H13.2363V7.15169ZM14.6388 0.0683594C6.81883 0.0683594 0.486328 6.41503 0.486328 14.235C0.486328 22.055 6.81883 28.4017 14.6388 28.4017C22.473 28.4017 28.8197 22.055 28.8197 14.235C28.8197 6.41503 22.473 0.0683594 14.6388 0.0683594ZM14.653 25.5684C8.39133 25.5684 3.31966 20.4967 3.31966 14.235C3.31966 7.97336 8.39133 2.90169 14.653 2.90169C20.9147 2.90169 25.9863 7.97336 25.9863 14.235C25.9863 20.4967 20.9147 25.5684 14.653 25.5684Z" fill="#FBA533"/>
</svg>

</SvgIcon>
    )
}