/**
 * Imports React and PropTypes libraries.
 * Imports Grid component from '@material-ui/core' library.
 * Defines Styles object for styling the GridContainer component.
 *
 * @param {string} props.className - The CSS class name of the GridContainer component.
 * @param {React.Component} props.children - The children components of the GridContainer component.
 * @returns {React.Component} The GridContainer component.
 */
import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";


export default function GridContainer(props) {
  /**
   * Destructure props to extract className and children.
   */
  const { children} = props;

  return (
    <Grid
      container
      display={props.display}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      direction={props.direction}
      
    >
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  /**
   * Prop type for className.
   */
  className: PropTypes.string,
  /**
   * Prop type for children.
   */
  children: PropTypes.node,
};