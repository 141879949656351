
import MainContainer from "../src/GlobalComponents/MainContainer/MainContainer"
import SignIn from "../src/GlobalComponents/sign-in"
import SAVDashboard from "../src/Pages/SAV/SAVDashboard"

import SAVIcon from "./GlobalComponents/IconComponents/SAVIcon";
import ListAllTickets from "../src/Pages/SAV/TicketList/ListAllTickets";
import New from "./Pages/SAV/AddTicket/NewSAVTicket";
import EditSAVTicket from "./Pages/SAV/TicketDetails/Edit/EditSAVTicket";
import ViewSAVTicket from "./Pages/SAV/TicketDetails/View/ViewSAVTicket";

/* The code is defining an array of routes for a React application. Each route object represents a page
or a group of pages in the application. */

export const routes = [
  {
    layout: "dashboard",
    pages: [
      {
        key : 1,
        name: "FIOManager",
        path: "/main",
        layout: "dashboard",
        element: <MainContainer />,
        collapse: false,
        invisible : true,
        state: "MainContainerCollapse",
        closeable: true,
        views : []

      },
     
      {
        key : 4,
        name: "SUPPORT SAV",
        path: "/SAV/Dashboard",
        layout: "dashboard",
        icon : <SAVIcon />,
        collapse: true,
        invisible : false,
        closeable: true,
        state: "SAVDashboardCollapse", 
        views: [
         
              {
                key : 5,
                name: "Tous les Tickets",
                path: "/SAV/Search",
                layout: "dashboard",
                element: <ListAllTickets />,
                collapse: false,
                invisible : false,
                closeable: true,
                
              },
              {
                key : 6,
                name: "Ajout Ticket",
                path: "/SAV/Search",
                layout: "dashboard",
                element: <New />,
                collapse: false,
                invisible : false,
                closeable: true,
              },
              {
                key : 7,
                name: "Edit Ticket",
                path: "/SAV/EditTicket",
                layout: "dashboard",
                element: <EditSAVTicket />,
                collapse: false,
                invisible : true,
                closeable: true,
              },
              {
                key : 8,
                name: " Mes Tickets",
                path: "/SAV/Search",
                layout: "dashboard",
                element: <SAVDashboard />,
                collapse: false,
                invisible : true,
                closeable: true,
              },
              {
                key : 9,
                name: "Ticket Details",
                path: "/SAV/TicketDetails",
                layout: "dashboard",
                element: <ViewSAVTicket />,
                collapse: false,
                invisible : true,
                closeable: true,
              },
             
        ]

      },


    ],
  },
  {

    title: "auth pages",
    layout: "auth",
    pages: [
      {
        //icon: <ArrowRightOnRectangleIcon {...icon} />,
        key : 0,
        name: "sign in",
        path: "/",
        element: <SignIn />,

      },

    ],
  },
];

export default routes;
