import  React , { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import GridContainer from '../../GlobalComponents/Grid/GridContainer';
import GridItem from '../../GlobalComponents/Grid/GridItem';
import  CustumDataGrid  from '../../GlobalComponents/Grid/CustomDataGrid'
import { Card, CardHeader } from '@mui/material';
import TitleCard from '../../GlobalComponents/Utility/TitleCard';
const drawerwidth = 240;


const defaultTheme = createTheme();

  const columns = [
  { field: 'id', headerName: 'ID', 
  // minWidth: 111 ,
  flex: 0.15},
  {
    field: 'Point',
    headerName: 'Point de vente',
    // minWidth: 250,
    editable: true,
    flex: 0.25
  },
  {
    field: 'Sujet',
    headerName: 'Sujet',
    // minWidth: 250,
    editable: true,
    flex: 0.25
  },
  {
    field: 'Date',
    headerName: 'Date Ticket',
    type: 'number',
    // minWidth: 150,
    editable: true,

    flex: 0.15
  },
  {
    field: 'Assigne',
    headerName: 'Assigné à ',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    // minWidth: 168,
    flex: 0.15
    // valueGetter: (params) =>
    //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  {
    field: 'Statut',
    headerName: 'Statut',
    type: 'number',
    // minWidth: 170,
    editable: true,
    flex: 0.15
  },
  {
    field: 'Action',
    headerName: 'Action',
    type: 'number',
    // minWidth: 152,
    editable: true,
    flex:0.15
  },
];

const rows = [
  { id: 1, Point: 'Snow', Sujet: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.", Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 2, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 3, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 4, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 10, Point: 'Snow', Sujet: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.", Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 20, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 30, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 40, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 11, Point: 'Snow', Sujet: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.", Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 21, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 31, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 41, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 12, Point: 'Snow', Sujet: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.", Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 22, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 32, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 42, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 13, Point: 'Snow', Sujet: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.", Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 23, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 33, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
  { id: 43, Point: 'Snow', Sujet: 'Jon', Date: 35 , Assigne : 'john', Satus : 'done', Action : 'action'},
];

/**
 * The SAVDashboard function returns a React component that displays a grid of tickets with various
 * options for page size, checkbox selection, and row selection.
 * @returns a JSX element.
 */
export default function SAVDashboard() {
  const [pageSize, setPageSize] = useState(10);
  const [pageSizeOptions, setPageSizeOptions] = useState([5,10,20,30,40,50]);
  const [checkboxSelection, setCheckboxSelection] = useState(true);
  const [disableRowSelectionOnClick, setDisableRowSelectionOnClick] = useState(true);
  return ( 
    <>
    <GridContainer   >
      <GridItem sm={12} 
      //sx={12}
       sx={{ 
        paddingTop : '3px',
        //paddingBottom : '3px',
        maxHeight : '10%'
       }}
        >
   <TitleCard title="Tickets Ouverts"/>
   </GridItem>
   <GridItem sm={12} sx={12} 
    > 
       {/* <Box sx={{ height: '499px',
        //width: '100%',
        ml: 5,
        display: "inline", 
       //minHeight : '100%' ,
    flexDirection: "row"}}>
     */}
     <Card 
      // sx={{ 
      //   paddingTop : '50px',
      //  }}
       >
      {rows.length > 0 ? 
      <CustumDataGrid
        rows={rows}
        columns={columns}
        pageSize= {pageSize}
        pageNumber={1}
        //disableColumnMenu 
        pageSizeOptions={pageSizeOptions}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        sx={{ 
          paddingTop : '3px',
          paddingRight : '0px',
          paddingLeft : '0px',
         // maxHeight : '45%',
          height : '300px',
          display : 'flex',
          width : '100%',
         // minWidth : '100%'
         }}
      /> : null}
      </Card>
      
    </GridItem>
 
    </GridContainer>
    </>
  );
}
    