
import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import NavBarButtons from './NavBarButtons';
import NavBarLogo from './NavBarLogo';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, drawerWidth }) => ({
  zIndex:
 //  theme.zIndex.drawer + 
   1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginleft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Navbar({ open }) {
  return (

    <AppBar position="sticky" open={open} width='100%'  sx={{
    
      minHeight : '20px',
      height: '20px',
      maxHeight : '20px',
   
    }}>
      
      <Toolbar
        sx={{
          // pr: '2px',
          background: 'white',
      
        }}
      >
        <GridContainer
          display='flex'
          justifyContent='flex-end'
          alignItems='flex-end'
          direction='row'
        
        >
          <GridItem sx={6} sm={6} md={2} xl={2} xxl={2}>
         <NavBarLogo  />
          </GridItem>
          <GridItem 
          sx={0} sm={0} md={9} xl={9} xxl={9}
          
          >
         
        
          </GridItem> 
          <GridItem 
         sx={6} sm={6} md={1} xl={1} xxl={1}
          
          >
         
          <NavBarButtons />
          </GridItem>

        </GridContainer>
      </Toolbar>
    </AppBar>
  );
}