import React, { useState, useEffect, useContext } from "react";
import {
  useNavigate,
} from "react-router-dom";
import routes from "../routes";
import Sidebar from '../GlobalComponents/Sidebar/sidebar';
import Navbar from '../GlobalComponents/Navbar/navbar';
import DefaultTab from '../GlobalComponents/TabsContainer/DefaultTab';

import HomeIcon from "../GlobalComponents/IconComponents/HomeIcon";

import { NavContext } from "../App";
import MainContainer from "../GlobalComponents/MainContainer/MainContainer";

import { useDispatch } from "react-redux";
import { setLastActiveTab } from "../ReduxTLK/features/Tabs/TabsSlice";





/*******************/
/* The `DashboardLayout` function is a React component that renders a layout for a dashboard. It
includes a sidebar, a navbar, and a main content area. */


export function DashboardLayout(props) {
  
    const navigate = useNavigate()
    const context = useContext(NavContext)
    const dispatch = useDispatch()
   
  
  useEffect(() => {
    
    if(!context.logged) 
    {
      navigate('/')
    }
    
  }, [context.logged])

  const { ...rest } = props;
  const [miniActive, setMiniActive] = React.useState(false);
  // const { sidenavType } = controller;
  const [open, setOpen] = React.useState(true);
  const [mode, setMode] = useState('inline');
  // const toggleDrawer = () => {
  //   setOpen(!open);
  // };
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
   
   // joinRoom(user, room);
    setTabs([{
      id: 1,
      label: 'Default',
      component: <DefaultTab />,
      closeable: false,
      icon: <HomeIcon />,
      collapse: false,
      invisible: false,
    }])
  }, [])



  /********************* */

  
  const sidebarMinimize = () => {
    dispatch(setLastActiveTab(context.activeTab))
    setMiniActive(!miniActive);
    setMode('vertical');
    //mobileFunction();
   
  
    setOpen(!open);
  };
  const sidebarMaximize= () => {
    dispatch(setLastActiveTab(context.activeTab))
 
    setMode('inline');
    //mobileFunction();
    setOpen(!open);
  };
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <>

      {open ? (
        fullScreen ? (<div className='fullScreen'> <MainContainer fullScreen={fullScreen} setFullScreen={setFullScreen}/></div>) : (
        <div className="container">
          <nav> <Navbar open={open} /></nav>

          <div id='main'>  
            <MainContainer fullScreen={fullScreen} setFullScreen={setFullScreen}/>
         
             {/* <Routes>
            {routes.map(
              ({ layout, pages }) =>
                layout === "dashboard" &&
                pages.map(({ path, element }) => (

                  <Route exact path={path} element={element} />
                ))
            )}
          </Routes> 
        */}
          </div>

          
         
          <div id="sidebar">
           <Sidebar 
            open={open}
            mode={mode}
            //toggleDrawer={toggleDrawer}
           // drawerwidth={drawerwidth}
            routes={routes}
            rtlActive={true}
            sidebarMinimize={sidebarMinimize}
            sidebarMaximize={sidebarMaximize}
            miniActive={miniActive}
            {...rest}
           /> 
          </div>
           {/* <footer>
            <SidebarFooter 
             //toggleDrawer={toggleDrawer}
             open={open}
             mode={mode}
             //drawerwidth={drawerwidth}
             sidebarMinimize={sidebarMinimize}
             sidebarMaximize={sidebarMaximize}
             miniActive={miniActive}
            />
          </footer>  */}
        
        
        </div> )
        )
        : (
          fullScreen ? (<div className='fullScreen'> <MainContainer fullScreen={fullScreen} setFullScreen={setFullScreen}/></div>) : (
        <div className="container_sidebar_mini">
          <nav> <Navbar open={open} /></nav>
          <div id='main'>  <MainContainer fullScreen={fullScreen} setFullScreen={setFullScreen}/></div>
          
          <div id="sidebar">
            <Sidebar
              open={open}
              mode={mode}
             // toggleDrawer={toggleDrawer}
             // drawerwidth={drawerwidth}
              routes={routes}
              rtlActive={true}
             sidebarMinimize={sidebarMinimize}
             sidebarMaximize={sidebarMaximize}
              miniActive={miniActive}
              {...rest}
            />
          </div>
           {/* <footer>
            <SidebarFooter
             // toggleDrawer={toggleDrawer}
              open={open}
              mode={mode}
            //  drawerwidth={drawerwidth}
             sidebarMinimize={sidebarMinimize}
             sidebarMaximize={sidebarMaximize}
              miniActive={miniActive}
            />
          </footer> 
          */}
        </div>))

      }
   
    </>

  );
}

DashboardLayout.displayName = "/src/layouts/dashbord.jsx";

export default DashboardLayout;
