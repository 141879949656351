import { Button, IconButton, MenuItem, Typography } from "@mui/material"
import Snackbars from "../../../GlobalComponents/Alerts/Snackbars"
import H5 from "../../../GlobalComponents/TypoGraphy/H5";
import React, { useEffect, useRef, useState } from "react";
import {
    Form, Input, Select,
    DatePicker,
    InputNumber
} from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { NavContext } from "../../../App";
import GridItem from "../../../GlobalComponents/Grid/GridItem";
import GridContainer from "../../../GlobalComponents/Grid/GridContainer";
import { Colors } from "../../../GlobalComponents/Utility/Colors";
import { array_machine, array_priorite, array_status } from "../../../ReduxTLK/features/SAV/ticketList";
import { fetchChaines } from "../../../ReduxTLK/features/Chaines/ChaineSlice";
import { fetchListPventesIntervenant, fetchPventes, fetchPventesIntervenant } from "../../../ReduxTLK/features/Pointdeventes/PointdeventeSlice";



import FiltreIcon from "../../../GlobalComponents/IconComponents/FiltreIcon";
import { unwrapResult } from "@reduxjs/toolkit";

import { fetchFiltredTickets } from "../../../ReduxTLK/features/SAV/TicketSlice";
import locale from 'antd/es/date-picker/locale/fr_FR';

import 'dayjs/locale/fr';
import dayjs from "dayjs";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { setPaginationModelSAVTT } from "../../../ReduxTLK/features/Pagination/PaginationsSlice";
import moment from "moment";
import { closeFilterBox, openFilterBox, resetFilter, resetFilterCounter, setFilterCounter, setFilterDraft, setFilterModel } from "../../../ReduxTLK/features/SAV/FilterSlice";






const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 22,
    },
    size: 'large',

};
const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;
export default function Filtre(props) {
    const { TextArea } = Input;
    const [open, setOpen] = useState(false);

    const [openErrorBox, setOpenErrorBox] = useState(false);
    const [message, setMessage] = useState("")
    const [date, setDate] = useState();
    const [openCalenderModal, setOpenCalenderModal] = useState(false);
    const [priorite, setPriorite] = useState()

    const [pVName, setPVName] = useState("")
    const today = dayjs();
    const dispatch = useDispatch();
    const context = React.useContext(NavContext)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [chaineId, setChaineId] = useState()
    const [pointVenteId, setPointDeVente] = useState()

    const [nbrePages, setNbrePages] = useState()
    const newTicketDraft = useSelector((state) => state.draftNewTicket.newTicketDraft);
    // const intervenant = useSelector((state) => state.pointDeVente.pventesIntervenants)
    const chaines = useSelector((state) => state.chaine.chaines)
    // const pointDeVentes = useSelector((state) => state.pointDeVente.pventes)
    const [pointDeVentes, setPointDeVentes] = useState([])
    const [intervenant, setIntervenant] = useState([])
    const [selectedPointVenteIds, setSelectedPointVenteIds] = useState([]);

    const savedFilterCounter = useSelector((state) => state.filter.counter);
    const savedFilterForm = useSelector((state) => state.filter.filter);
    //const [filterCounter, setFilterCounter] = useState()
    useEffect(() => {

        if (savedFilterForm) {

            form.setFieldsValue(savedFilterForm)
        }
    }, []);

    useEffect(() => {

        dispatch(fetchChaines());



    }, [])
    useEffect(() => {

        if (chaineId !== undefined && chaineId !== null) {
            dispatch(fetchPventes(chaineId)).then(unwrapResult).then((res) => {

                setPointDeVentes(res?.data?.data?.result)
            });

        }




    }, [chaineId])
    const disabledDate = (current) => {
        // Disable dates that are in the future
        return current && current > moment().endOf('day');
    };

    const resetForm = () => {


        // setFilterCounter();
        dispatch(resetFilterCounter())
        form.resetFields();
        setPointDeVentes([]);
        setIntervenant([]);
        setLoading(true);
        dispatch(fetchFiltredTickets({
            paginationModel: {
                page: 1,
                pageSize: 12,
                nbPage: null
            },
            body: {
                id: null,
                chaineId: null,
                pointVenteId: null,
                statut: null,
                equipement: null,
                priorite: null,
                intervenantId: null,

                dateBegin: props.dateBegin,
                dateEnd: props.dateEnd
            },
        }))
            .then(unwrapResult)
            .then((res) => {
                dispatch(setFilterModel(
                    {
                        paginationModel: {
                            page: res?.data?.pagination?.currentPage,
                            pageSize: res?.data?.pagination?.pageSize,
                            nbPage: res?.data?.pagination?.nbPage
                        },
                        body: {
                            id: null,
                            chaineId: null,
                            pointVenteId: null,
                            statut: null,
                            equipement: null,
                            priorite: null,
                            intervenantId: null,

                            dateBegin: props.dateBegin,
                            dateEnd: props.dateEnd
                        },


                    }
                ))
                // props.setPaginationModelCustom({
                //     page: res?.data?.pagination?.currentPage,
                //     pageSize: res?.data?.pagination?.pageSize,
                //     nbPage: res?.data?.pagination?.nbPage
                // })
                // dispatch(setPaginationModelSAVTT({
                //     page: res?.data?.pagination?.currentPage,
                //     pageSize: res?.data?.pagination?.pageSize,
                //     nbPage: res?.data?.pagination?.nbPage
                // }))
                setNbrePages(res?.data?.pagination?.nbPage)
                props.setTicketList(res?.data?.result?.tickets);
                setLoading(false);
                props.setFilterOptions({
                    id: null,
                    chaineId: null,
                    pointVenteId: null,
                    statut: null,
                    equipement: null,
                    priorite: null,
                    intervenantId: null,

                    dateBegin: props.dateBegin,
                    dateEnd: props.dateEnd
                })
                props.setOpenFiltreBox(false);
                dispatch(closeFilterBox());
                dispatch(resetFilter())
            });
    }
    const [selectedDate, setSelectedDate] = useState([]);
    const handleDateChange = (dates) => {

        // Check if both start and end dates are selected
        if (dates?.length === 2) {
            setSelectedDate(dates);
        } else if (dates?.length === 1) {
            // If only one date is selected, set both start and end dates to the selected date
            setSelectedDate([dates[0], dates[0]]);
        } else {
            // If no date is selected, reset the selection
            setSelectedDate([]);
        }

    };
    /*************** */
    // Fonction pour compter le nombre de champs non null dans le filtre
    function countNonNullFields(obj) {
        let count = 0;
        for (let key in obj) {
           
            if (Array.isArray(obj[key]) && obj[key].length > 0) {
                count++;
            } 
            else if (Array.isArray(obj[key]) && obj[key].length === 0) {
                continue; 
            }
            else if (obj[key] !== null && obj[key] !== undefined) {
                count++;
            }
        } 
        return count;
    }
    const onFinish = (values) => {
        setLoading(true);
        dispatch(setFilterDraft(values));
        dispatch(fetchFiltredTickets({
            paginationModel: {
                page: 1,
                pageSize: 12,
                nbPage: null
            },
            body: {
                ...values,
                dateBegin: props.dateBegin,
                dateEnd: props.dateEnd
            },
        }))
            .then(unwrapResult)
            .then((res) => {
                const nonNullFieldsCount = countNonNullFields({
                    ...values,
                    
                });
                
                //   setFilterCounter(nonNullFieldsCount)
                dispatch(setFilterCounter(nonNullFieldsCount))
                dispatch(setFilterModel(
                    {
                        paginationModel: {
                            page: res?.data?.pagination?.currentPage,
                            pageSize: res?.data?.pagination?.pageSize,
                            nbPage: res?.data?.pagination?.nbPage
                        },
                        body: {
                            ...values,
                            dateBegin: props.dateBegin,
                            dateEnd: props.dateEnd
                        }


                    }
                ))

                // props.setPaginationModelCustom({
                //     page: res?.data?.pagination?.currentPage,
                //     pageSize: res?.data?.pagination?.pageSize,
                //     nbPage: res?.data?.pagination?.nbPage
                // })
                // dispatch(setPaginationModelSAVTT({
                //     page: res?.data?.pagination?.currentPage,
                //     pageSize: res?.data?.pagination?.pageSize,
                //     nbPage: res?.data?.pagination?.nbPage
                // }))
                setNbrePages(res?.data?.pagination?.nbPage)
                props.setTicketList(res?.data?.result?.tickets);
                setLoading(false);
                props.setOpenFiltreBox(false);
                dispatch(closeFilterBox());

            });
    };
    const onValuesChange = (changedValues, allValues) => {

        props.setFilterOptions(allValues)
        const [key, value] = Object.entries(changedValues)[0];
        if (key === 'dateCreation' && value !== undefined && value !== null) {

            props.setDateBegin(value[0]?.format('YYYY-MM-DD'));
            props.setDateEnd(value[1]?.format('YYYY-MM-DD'));

        }
        if (key === 'dateCreation' && (value === undefined || value === null)) {
            props.setDateBegin();
            props.setDateEnd();
        }
        if (key === 'priorite') {
            setPriorite(value);

        }
        if (key === 'chaineId' && value !== undefined && value !== null) {
            form.setFieldsValue({
                chaineId: value,
                pointVenteId: null,
                intervenantId: null,

            })


            setIntervenant([])
            setChaineId(value);



            dispatch(fetchPventes(value)).then(unwrapResult).then((res) => {

                setPointDeVentes(res?.data?.data?.result)
            });
        }
        if (key === 'chaineId' && (value === undefined || value === null)) {
            form.setFieldsValue({
                chaineId: null,
                pointVenteId: null,
                intervenantId: null,

            })
            setPointDeVentes([])
            setIntervenant([])
        }
        if (key === 'pointVenteId' && (value !== undefined || value !== null)) {

            if (value?.length > 0) {

                dispatch(fetchListPventesIntervenant(value)).then((res) => {
                    setIntervenant(res?.payload?.data?.data?.result)

                });
            } else {

                setPointDeVentes([])
                setIntervenant([])
            }
        }



    };
    const handleFiltreClick = () => {
        if (props.openFiltreBox === true) {
            props.setOpenFiltreBox(false)
            dispatch(closeFilterBox());
        }
        else {
            props.setOpenFiltreBox(true)
            dispatch(openFilterBox());
        }

    }
    /*************disptach depending on filterbox state */
    const fetchFiltredModel = useSelector((state) => state.filter.filterModel);
    useEffect(() => {
        if (props.openFiltreBox === true) {
            dispatch(openFilterBox())
            props.fetchData({
                paginationModel: {
                    page: fetchFiltredModel?.paginationModel?.page,
                    pageSize: 8,
                    nbPage: null
                },
                body: {
                    ...fetchFiltredModel.body
                }


            })
        }
        else {

            dispatch(closeFilterBox())

            props.fetchData({
                paginationModel: {
                    page: fetchFiltredModel?.paginationModel?.page,
                    pageSize: 12,
                    nbPage: null
                },
                body: {
                    ...fetchFiltredModel.body
                }


            })
        }


    }, [props.openFiltreBox]);
    const validateIsInteger = (_, value) => {
        if (value && !Number.isInteger(value)) {
            return Promise.reject(new Error('Veuillez saisir un entier valide'));
        }
        return Promise.resolve();
    };
    const handleOpenCalenderModal = () => {
        setOpenCalenderModal(true);
    }
    const filterOption = (input, option) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    /************************ */


    /********************** */
    return (
        <>
            <div style={{ backgroundColor: '#F4F7FE', marginBottom: '5px' }} >

                <div style={{
                    display: 'flex',
                    direction: 'row',
                    alignItems: 'flex-end',
                    backgroundColor: 'white',
                    //   height: '60px'
                }} >

                  { savedFilterCounter > 0 ? <span style={{
                        fontFamily: 'DM Sans',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        textAlign: 'center',
                        marginLeft: '10px',
                        marginBottom: '17px',
                        color: '#344054'
                    }}>{savedFilterCounter} filtre(s) appliqué(s)</span> : <></>}
                    <Button
                        onClick={handleFiltreClick}
                        startIcon={<FiltreIcon></FiltreIcon>} sx={{
                            textTransform: 'none',
                            marginTop: '5px',
                            marginBottom: '10px',
                            marginRight: '30px',
                            marginLeft: 'auto',
                            borderRadius: 32,
                            '&.MuiButtonBase-root': {
                                color: '#2A66C6',

                                border: '1px solid #D9D9D9',
                                borderShadow: '0px 4px 12px -4px #0000001F',

                                borderRadius: '32px',
                                width: '100px',
                                height: '40px',
                                ':disabled': {
                                    backgroundColor: ' #F3F4F6',
                                    color: '#9CA3AF',
                                    borderColor: '#F3F4F6',
                                },
                            },
                        }}
                        variant="outlined"><span
                            style={{
                                fontFamily: 'DM Sans',
                                fontSize: '16px',
                                fontWeight: '500',
                                lineHeight: '16px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}>Filtres</span></Button>
                </div>
            </div>
            {props.openFiltreBox ? <div style={{ backgroundColor: '#F4F7FE', marginBottom: '5px' }} >

                <div style={{ backgroundColor: 'white', }}>
                    <br></br>

                    <Form
                        {...layout}
                        form={form}
                        name="control-hooks-filter"
                        onValuesChange={onValuesChange}
                        // initialValues={initialValues}
                        onFinish={onFinish}
                    >

                        <GridContainer spacing={0.5}>


                            <GridItem sx={{
                                marginTop: '10px', marginLeft: '10px',
                            }} sm={12} md={2.95} xl={2.95} xxl={2.95}>
                                <H5 title={'Référence'} requiredInput={false} />
                                <Form.Item
                                    name="id"
                                    rules={[
                                        {
                                            max: 100,
                                            required: false,
                                            validator: validateIsInteger,
                                        },
                                    ]}
                                    style={{
                                        marginBottom: '0.5%',
                                    }}

                                >
                                    <InputNumber style={{ backgroundColor: 'white', width: '100%', }} />
                                </Form.Item>
                            </GridItem>
                            <GridItem sx={{
                                marginTop: '10px',
                            }} sm={12} md={2.95} xl={2.95} xxl={2.95} >
                                <H5 title={'Etablissement'} requiredInput={false} />
                                <Form.Item
                                    name="chaineId"

                                    rules={[
                                        {
                                            required: false,
                                            //message: 'Ce champ est obligatoire !',
                                        },
                                    ]}
                                    style={{
                                        marginBottom: '0.5%',

                                    }}
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={filterOption}
                                        placeholder=""

                                    >
                                        {chaines &&
                                            chaines.map((chaine) => (
                                                <option key={chaine.id} value={chaine.id}>
                                                    {chaine.nom}
                                                </option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </GridItem>
                            <GridItem sx={{
                                marginTop: '10px',
                            }} sm={12} md={2.95} xl={2.95} xxl={2.95} >
                                <H5 title={'Point de vente'} requiredInput={false} />
                                <Form.Item
                                    name="pointVenteId"

                                    rules={[
                                        {
                                            required: false,
                                            message: 'Ce champ est obligatoire !',
                                        },
                                    ]}
                                    style={{
                                        marginBottom: '0.5%',

                                    }}


                                >
                                    <Select
                                        placeholder=""
                                        mode="multiple"
                                        //style={{  overflowY : 'auto' , maxHeight: '45px' }}
                                        showSearch
                                        filterOption={filterOption}
                                        maxTagCount='responsive'


                                    //onChange={onChangePV}
                                    >
                                        {pointDeVentes &&
                                            pointDeVentes.map((pv) => (
                                                <option key={pv.id} value={pv.id}>
                                                    {pv.nom}
                                                </option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </GridItem>
                            <GridItem sx={{
                                marginTop: '10px',
                            }} sm={12} md={2.95} xl={2.95} xxl={2.95}>
                                <H5 title={'Status'} requiredInput={false} />
                                <Form.Item
                                    name="statut"

                                    rules={[
                                        {
                                            required: false,
                                            // message: 'Ce champ est obligatoire !',
                                        },
                                    ]}
                                    style={{
                                        marginBottom: '0.5%',

                                    }}

                                >
                                    <Select placeholder=""
                                        mode="multiple"
                                        showSearch
                                        filterOption={filterOption}
                                        maxTagCount='responsive'
                                        sx={{
                                            width: '100%'
                                        }}>
                                        {array_status &&
                                            array_status.filter((item) => item.id !== 7 && item.id !== 5).map((type) => (
                                                <option key={type.id} value={type.id}>
                                                    {type.nom}
                                                </option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </GridItem>
                            <GridItem sx={{ marginLeft: '10px', marginTop: '10px' }} sm={12} md={3} xl={2.95} xxl={2.95}>
                                <H5 title={'Equipement'} requiredInput={false} />
                                <Form.Item
                                    name="equipement"

                                    rules={[
                                        {
                                            required: false,
                                            //  message: 'Ce champ est obligatoire !',
                                        },
                                    ]}
                                    style={{
                                        marginBottom: '0.5%',

                                    }}

                                >
                                    <Select placeholder="" mode="multiple"
                                        maxTagCount='responsive'
                                        showSearch
                                        filterOption={filterOption} >
                                        {array_machine &&
                                            array_machine.map((machine) => (
                                                <option key={machine.id} value={machine.id}>
                                                    {machine.nom}
                                                </option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </GridItem>
                            <GridItem sx={{
                                marginTop: '10px',
                            }} sm={12} md={2.95} xl={2.95} xxl={2.95}>
                                <H5 title={'Priorité'} requiredInput={false} />
                                <Form.Item
                                    name="priorite"

                                    rules={[
                                        {
                                            required: false,

                                        },
                                    ]}
                                    style={{
                                        marginBottom: '0.5%',

                                    }}

                                >
                                    <Select
                                        mode="multiple"
                                        placeholder=""
                                        showSearch
                                        filterOption={filterOption}
                                        maxTagCount='responsive'
                                    >
                                        {array_priorite &&
                                            array_priorite.map((priorite) => (
                                                <option key={priorite.id} value={priorite.id}>
                                                    {priorite.nom}
                                                </option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </GridItem>
                            <GridItem sx={{
                                marginTop: '10px',
                            }} sm={12} md={2.95} xl={2.95} xxl={2.95}>

                                <H5 title={'Intervenant'} requiredInput={false} />
                                <Form.Item
                                    name="intervenantId"

                                    rules={[
                                        {
                                            required: false,
                                            message: 'Ce champ est obligatoire !',
                                        },
                                    ]}
                                    style={{
                                        marginBottom: '0.1%',
                                        marginRight: "0px"

                                    }}

                                >
                                    <Select
                                        variant={'outlined'}
                                        mode="multiple"
                                        showSearch
                                        filterOption={filterOption}
                                        maxTagCount='responsive'
                                        required={true}
                                        sx={{
                                            width: '100%',
                                            border: '1px ',
                                            boxShadow: '0px 1px 2px 0px #1018280D',
                                            color: Colors.grey3,
                                            borderRadius: '8px',

                                            height: 'Hug (44px)',
                                        }}
                                    >
                                        {intervenant.map((option, index) => {
                                            return (
                                                <MenuItem key={index} value={option.id}>{`${option.nom} ${option.prenom}`}</MenuItem>
                                            )
                                        })}

                                    </Select>

                                </Form.Item>
                            </GridItem>
                            <GridItem sx={{
                                marginTop: '10px',
                            }} sm={12} md={2.95} xl={2.95} xxl={2.95}>
                                <H5 title={'Date'} requiredInput={false} />
                                <Form.Item
                                    name="dateCreation"

                                    rules={[
                                        {
                                            required: false,

                                        },
                                    ]}
                                    style={{
                                        marginBottom: '0.5%',

                                    }}

                                >

                                    <RangePicker
                                        allowClear
                                        disabledDate={disabledDate}
                                        mode={['day', 'day']}
                                        needConfirm={true}
                                        size="large"
                                        placeholder="dd/mm/yyyy"
                                        locale={locale}
                                        onChange={handleDateChange}
                                        style={{
                                            width: '100%',
                                        }}
                                    //  renderExtraFooter={renderExtraFooter}
                                    //  defaultValue={today} 
                                    //  format={dateFormat}

                                    />


                                </Form.Item>
                            </GridItem>








                        </GridContainer>
                        <Snackbars severity={'success'} message={message} open={open} setOpen={setOpen} autohideduration={1000} />
                        <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autohideduration={1000} />

                        <br></br>

                        <div
                            style={{
                                position: 'static',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                maxHeight: '20px',

                            }}
                        >


                            <Button
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: 32,
                                    marginRight: '10px',
                                    '&.MuiButtonBase-root': {
                                        color: '#2A66C6',
                                        border: '1px solid #D9D9D9',
                                        borderShadow: '0px 4px 12px -4px #0000001F',

                                        borderRadius: '32px',

                                        borderRadius: '100px',
                                        width: '120px',
                                        height: '40px',
                                        ':disabled': {
                                            backgroundColor: ' #F3F4F6',
                                            color: '#9CA3AF',
                                            borderColor: '#F3F4F6',
                                        },
                                    },
                                }}
                                variant="outlined"
                                onClick={resetForm}

                            >
                                Réinitialiser
                            </Button>
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: 32,
                                    '&.MuiButtonBase-root': {
                                        color: '#FFFFFF',
                                        backgroundColor: '#2A66C6',
                                        width: '128px',
                                        height: '40px',
                                    },
                                    ':disabled': {
                                        backgroundColor: ' #F3F4F6',
                                        color: '#9CA3AF',
                                        borderColor: '#F3F4F6',
                                    },
                                }}

                                type="submit"
                                variant="contained"
                            >
                                {loading ? <><center>
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white' }} spin />} />
                                </center></> : <> Confirmer </>}
                            </Button>
                        </div>

                        <br></br>

                    </Form>

                </div>
            </div> : null}

        </>
    )
}