import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useDispatch } from 'react-redux';
import { getNbrMessagesNonLus } from '../../ReduxTLK/features/Chat/ChatSlice';
import { getNewNotificationsNumber } from '../../ReduxTLK/features/Notifications/NotificationSlice';
import { NavContext } from '../../App';
import { unwrapResult } from "@reduxjs/toolkit";
import EditSAVTicket from '../../Pages/SAV/TicketDetails/Edit/EditSAVTicket';
import SAVIcon from '../IconComponents/SAVIcon';
import { Persistor } from '../..';
import { resetEditTicketDraftByIndex } from '../../ReduxTLK/features/SAV/NewTicketDraftSlice';
import { addTab, removeTabById, setLastActiveTab } from '../../ReduxTLK/features/Tabs/TabsSlice';
import ViewSAVTicket from '../../Pages/SAV/TicketDetails/View/ViewSAVTicket';
/**
 * A custom Alert component that extends the MuiAlert component and adds an elevation of 6 and a filled variant.
 *
 * @param {object} props - The props passed to the Alert component.
 * @param {ref} ref - A ref to the Alert component.
 * @returns {React.Component} The CustomAlert component.
 */
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} sx={{
    background : 'linear-gradient(to right , #2D82B2, #329ABB)',
    width: '100%', minWidth: '320px', maxWidth: '320px'
  }} />;
});

/**
 * A reusable snackbar component that displays a message with a customizable severity level.
 *
 * @param {string} severity - The severity level of the snackbar message.
 * @param {string} message - The message to display in the snackbar.
 * @returns {React.Component} The Snackbars component.
 */


export default function InfoBox({open, severity, message, idNotif, setOpen, header, notifStatus}) {
 
  const [title, setTitle] = useState('Info');
  const [color, setColor] = useState('');
  const context = React.useContext(NavContext)
  /**
   * Function to handle the click event on the Open success snackbar button.
   * Sets the open state to true, which opens the snackbar.
   */
  
  // const handleClick = () => {
  //   setOpen(true);
  // };

  /**
   * Function to handle the close event on the snackbar.
   * Sets the open state to false, which closes the snackbar.
   * @param {object} event - The event object.
   * @param {string} reason - The reason why the snackbar was closed.
   */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

   setOpen(false);
  };

  
  const [paginationModelCustom, setPaginationModelCustom] = useState({

    pageSize: 13,
    page: 1,
    nbPage: 1
  })
  const fetchData = async (paginationModel) => {
   
   // dispatch(fetchTickets(paginationModel))
    //  .then(unwrapResult).then((res) => {
        // dispatch(setPaginationModelSAVTT({
        //   page: res?.data?.pagination?.currentPage,
        //   pageSize: res?.data?.pagination?.pageSize,
        //   nbPage: res?.data?.pagination?.nbPage
        // }))
       // setTicketList(res.data.result.tickets);
       // setLoading(false);
      //  Persistor.persist();
     // }
     // );



  };
  const closeExistingTabAndOpenInView = ({idNotif, composedId, title}) => {
    const updatedTabs = context.tabs.filter((tab) => {
               
      return tab.identifier !== idNotif;
    });

    dispatch(removeTabById(composedId))
  
    context.setTabs([...updatedTabs, {
      id: composedId,
      label: title,
      identifier: idNotif,
      component: <ViewSAVTicket id={idNotif} />,
      closeable: true,
      icon: <SAVIcon />,
    }]);
    dispatch(addTab({
      module: 'SAV',
      action: 'View',
      identifier: idNotif,
      id: composedId,
      label: title,
    }));
    Persistor.persist();

    context.setActiveTab(composedId);
    dispatch(setLastActiveTab(composedId));
  }
  const openNewViewTab = ({idNotif, composedId, title}) => {
    dispatch(addTab({
      module: 'SAV',
      action: 'View',
      identifier: idNotif,
      id: composedId,
      label: title,
    }));

    context.setTabs([...context.tabs, {
      id: composedId,
      label: title,
      identifier: idNotif,
      component: <ViewSAVTicket id={idNotif} fetchData={fetchData} paginationModelCustom={paginationModelCustom} />,
      closeable: true,
      icon: <SAVIcon />,
    }]);
    Persistor.persist();
    // context.setMaxTabIndex(index + 1);
    context.setActiveTab(composedId);
    dispatch(setLastActiveTab(composedId));
  };
  const handleSnackbarClick = ({notifStatus, idNotif}) => {
   
     
      const title = '#' + idNotif;
      const composedId = 'SAV_Ticket_' + idNotif 
      // Find any existing tab with the same ID
      const existingTabIndex = context.tabs.findIndex((tab) => tab.id === composedId);
         // If a tab with the ID already exists, and active force ticket details view.
      
      if (existingTabIndex !== -1 && context.activeTab === composedId) {
        
        if (notifStatus === 6)
        {
          closeExistingTabAndOpenInView({ idNotif, composedId, title})
        } 
        else {
          context.setTabToBeReloadedNotif(idNotif)
        }
      

        return; // Exit the function to avoid creating a duplicate tab
      }
      // If a tab with the ID already exists, activate it
      if (existingTabIndex !== -1) {
        if (notifStatus === 6)
        {
          closeExistingTabAndOpenInView({ idNotif, composedId, title})
        } 
        else {
        context.setActiveTab(composedId);
        dispatch(setLastActiveTab(composedId));
        }
        return; // Exit the function to avoid creating a duplicate tab
      }
     
      // If no existing tab found, create a new one
     
     if(notifStatus === 6 || notifStatus === 0) {
      {
        openNewViewTab({ idNotif, composedId, title})
      
      }
     }
     else {
      dispatch(resetEditTicketDraftByIndex(idNotif));
      dispatch(addTab({
        module : 'SAV',
        action : 'Edit',
        identifier : idNotif,
        id: composedId,
        label: title,
      }));
     
      context.setTabs([...context.tabs, {
        id: composedId,
        label: title,
        identifier : idNotif,
        component: <EditSAVTicket id={idNotif} fetchData={fetchData} paginationModelCustom={paginationModelCustom}/>,
        closeable: true,
        icon: <SAVIcon />,
      }]);
      Persistor.persist();
     // context.setMaxTabIndex(index + 1);
      context.setActiveTab(composedId);
      dispatch(setLastActiveTab(composedId));
     }
    
   
  };
  /**
   * UseEffect hook to set the title of the snackbar based on the severity level.
   * @useEffect
   */
  const dispatch = useDispatch()
  useEffect(() => {
   
   dispatch(getNbrMessagesNonLus())
   dispatch(getNewNotificationsNumber()).then(unwrapResult).then((res) => {
   
    context.setNewNotification(res?.data?.data?.result)
  
})

    // setOpen(true)
    switch (severity) {
      
      case 'error':
        setTitle('Error');
        setColor('#F0863A')
        break;
      case 'alert':
        setTitle('Alert');
        setColor('#F0863A')
        break;
      case 'success':
        setTitle('Success');
        setColor('#43D590');
        break;
      case 'info':
        setTitle('Info');
        setColor('#7BCFED')
        break;
      default:
        return setTitle('Error');
    }
  }, []);

  
  return (
    <div>
       {/* <Button variant="outlined" onClick={handleClick}>
        Open {title} snackbar
      </Button>  */}
      <Snackbar 
      open={open} 
      autoHideDuration={5000}
       onClose={handleClose} 
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClick={() => handleSnackbarClick({notifStatus, idNotif})}
       >
        <Alert backgroundColor={color} onClose={handleClose} severity={'info'} sx={{ width: '100%', minWidth: '320px', maxWidth: '320px' }}>
          <AlertTitle>{title} </AlertTitle>
          {header}
          <br></br>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
