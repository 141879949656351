import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

export const sendMessage = createAsyncThunk('chat/sendMessage', 
async (data, thunkAPI) =>  {
    try {
        const storedToken = localStorage.getItem('TokenStored');

        const timezone = localStorage.getItem('TZ')
    const res = await axios.post(BaseUrl + 
        `/SAV/envoyerMessageAttachement?contenu=${data.get('contenu')}&idTicket=${data.get('idTicket')}&connectionId=${data.get('connectionId')}&isUpdateStatut=${data.get('isUpdateStatut')}`,
          data,
         {
            headers: {
            Accept: 'application/json',  
            'Content-Type': 'multipart/form-data',
            'Authorization': storedToken,
            'Platform': 'web',
            'timezone' : timezone,
          }
         },
         
          );
    return res}
    catch(err)
    {
        return thunkAPI.rejectWithValue({ error: err.message })
    }     
   
});
const initialState = {
    loading : false,
    messages : [],
    messageNonLus : null,
    message : null,
    error: "",
};
export const getMessages = createAsyncThunk('chat/getMessages', 
(data) => {
    const storedToken = localStorage.getItem('TokenStored');
    const timezone = localStorage.getItem('TZ')
    return axios.get(BaseUrl +
         `/SAV/getMessages?idTicket=${data.idTicket}&page=${data.page}&pageSize=${data.pageSize}`, 
    {headers: {
        'Content-Type': 'application/json',
        'Authorization': storedToken,
        'Platform': 'web',
        'timezone' : timezone,
      }});
});
export const getNbrMessagesNonLus = createAsyncThunk('chat/getNbrMessagesNonLus', 
(data) => {
    const storedToken = localStorage.getItem('TokenStored');
    const timezone = localStorage.getItem('TZ')
    return axios.get(BaseUrl +
         `/SAV/getNbrMessagesNonLus?ticketId=${data.idTicket}&page=${data.page}&pageSize=${data.pageSize}`, 
    {headers: {
        'Content-Type': 'application/json',
        'Authorization': storedToken,
        'Platform': 'web',
        'timezone' : timezone,
      }});
});
const chatsSlice = createSlice({
    name: 'chat',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(sendMessage.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(sendMessage.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(sendMessage.rejected, (state, action) => {
            state.loading = false;
            state.message = null;
            state.error = action.error.message;
        }) 
        builder.addCase(getMessages.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getMessages.fulfilled, (state, action) => {
            state.loading = false;
            state.messages = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(getMessages.rejected, (state, action) => {
            state.loading = false;
            state.messages = null;
            state.error = action.error.message;
        }) 
        builder.addCase(getNbrMessagesNonLus.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getNbrMessagesNonLus.fulfilled, (state, action) => {
            state.loading = false;
           
            state.messageNonLus = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(getNbrMessagesNonLus.rejected, (state, action) => {
            state.loading = false;
            state.messages = null;
            state.error = action.error.message;
        }) 
}
});
export default chatsSlice.reducer;