
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function SAVIcon () {
    return (
       
        <SvgIcon>
      
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="life-buoy-01" clipPath="url(#clip0_83_16850)">
<path id="Icon" d="M7.61355 7.61358L4.10744 4.10747M4.10744 15.8926L7.63997 12.3601M12.3842 12.3865L15.8903 15.8926M15.8903 4.10747L12.3573 7.64052M18.3333 10C18.3333 14.6024 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 10C1.66667 5.39763 5.39763 1.66667 10 1.66667C14.6024 1.66667 18.3333 5.39763 18.3333 10ZM13.3333 10C13.3333 11.841 11.8409 13.3333 10 13.3333C8.15905 13.3333 6.66667 11.841 6.66667 10C6.66667 8.15906 8.15905 6.66667 10 6.66667C11.8409 6.66667 13.3333 8.15906 13.3333 10Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_83_16850">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
      </SvgIcon>
    )
}