import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'


export const  loggedUser =  createAsyncThunk('user/loggedUser',
async   (data) => {
        
            try {
                const response = await axios.post(BaseUrl + '/Account/login', data, {
                  headers: {
                    'Content-Type': 'application/json',
    'Platform': 'web'
                  },
                });
                return response
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  // Handle 401 error (e.g., redirect to login, display message)
                
                  return error.response
                } else {
                  // Handle other errors
                 
                  return error.response
               
                }
              }
       

    });
export const logoutUser = createAsyncThunk('user/logoutUser',
 async   (data) => {
        try {
       
        
      
        
        const tokenStored = localStorage.getItem('TokenStored')
       
        const response = await axios.post(BaseUrl + '/Account/logout', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenStored,
                'Platform': 'web'
            }
        });
      
        return response
              
        // Handle successful logout (e.g., clear token, redirect)
      } catch (error) {
       
        if (error.response && error.response.status === 401) {
          // Handle 401 error (e.g., redirect to login, display message)
       
          return error.response
        } else {
          // Handle other errors
          return error.response
          
        }
      }
        

    });

const initialState = {
    loading: false,
    data: [],
    currentLoggedUser: null,
    error: "",
};
const usersSlice = createSlice({
    name: ' user',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(loggedUser.pending, (state) => {
            state.loading = true;
           
        })
        builder.addCase(loggedUser.fulfilled, (state, action) => {
            state.loading = false;
          
            state.currentLoggedUser = action.payload.data.data.result;
            
            state.error = "";
           
        })
        builder.addCase(loggedUser.rejected, (state, action) => {
          
            state.loading = false;
            state.currentLoggedUser = null;
            state.error = action.error.message;
        })
        builder.addCase(logoutUser.pending, (state) => {
            state.loading = true;
           
        })
        builder.addCase(logoutUser.fulfilled, (state, action) => {
            state.loading = false;
        
            state.currentLoggedUser = '';
            
            state.error = "";
            localStorage.removeItem('Token');
            localStorage.removeItem('userId');
            localStorage.removeItem('TokenStored');
            localStorage.removeItem('userlastName');
            localStorage.removeItem('notifToken');
            
            
           
        })
        builder.addCase(logoutUser.rejected, (state, action) => {
          
            state.loading = false;
            state.currentLoggedUser = null;
            state.error = action.error.message;
        })
    }
});
export default usersSlice.reducer;