
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function MobileAndMonitorIcon () {
    return (
       
        <SvgIcon>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M0.833374 1.49984C0.833374 1.0396 1.20647 0.666504 1.66671 0.666504H18.3334C18.7936 0.666504 19.1667 1.0396 19.1667 1.49984V2.33317H2.50004V10.6665H9.58337V12.3332H1.66671C1.20647 12.3332 0.833374 11.9601 0.833374 11.4998V1.49984Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.54057 11.7634L8.70724 14.2634L7.1261 13.7363L7.95943 11.2363L9.54057 11.7634Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.5 13.1665H9.58333V14.8332H2.5V13.1665Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M10.8334 5.24984C10.8334 4.09924 11.7661 3.1665 12.9167 3.1665H17.0834C18.234 3.1665 19.1667 4.09924 19.1667 5.24984V15.2498C19.1667 16.4004 18.234 17.3332 17.0834 17.3332H12.9167C11.7661 17.3332 10.8334 16.4004 10.8334 15.2498V5.24984ZM12.9167 4.83317C12.6866 4.83317 12.5 5.01972 12.5 5.24984V15.2498C12.5 15.48 12.6866 15.6665 12.9167 15.6665H17.0834C17.3135 15.6665 17.5 15.48 17.5 15.2498V5.24984C17.5 5.01972 17.3135 4.83317 17.0834 4.83317H12.9167Z" fill="#2A66C6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.8334 15.2497H14.1667V13.583H15.8334V15.2497Z" fill="#2A66C6"/>
</svg>
</SvgIcon> )}