export const  Colors = {
    
    /*BrandColors  */
    primary: '#2A66C6',
    secondary1 : "#4080D1",
    secondary2 : "#B3CEE2",
    secondary3 : "#6B7280",
    secondary4 : "#F75D5D",
    /*Gray Colors*/
    black : "#344054",
    grey1 : "#1F2937",
    grey2 : "#374151",
    grey3 : "#4B5563",
    grey4: "#6B7280",
    grey5 : "#9CA3AF",
    grey6 : "#979797",
    grey7 : "#D1D5DB",
    grey8 : "#F3F4F6",
    white : "#FFFFFF",
    /* state colors */
    info : "#3092B8",
    success : "#2DA884",
    warning  : '#FDBA2F',
    error : '#DC372A',
    /*****Gardient******/
    gardientsBlue : "linear-gradient(308deg, #78C3EF -112.79%, #6BB4E8 -74.32%, #4C8ED8 0.8%, #2A66C6 70.42%)",
    gardientsGrey : "#6C6C6C",
    /*********place holder text colors************/
    placeHolderTextColor : "#979797",
    btnLoading : '#0D5ED9',
    /********capsule colors**********/
    lightOrange : "#FF9E79",
    warningOrange : "#FFA800",
    strongOrange : '#EE5800',
    lightGreen : '#D8F7E6',
    strongGreen : '#1B7B41',
    velvet : '#392494',
    lightGrey : '#EEEDFF',
    /***********blue light*************/
    blueLight25 : '#F5FBFF',
    blueLightBackrgound : '#F4F7FE',
    blueLight50 : '#F0F9FF',
    blueLight100 : '#E0F2FE',
    blueLight200 : '#B9E6FE',
    blueLight300 : '#7CD4FD',
    blueLight400 : '#36BFFA',
    blueLight500 : '#0BA5EC',
    blueLight600 : '#0086C9',
    blueLight700 : '#026AA2',
    blueLight800 : '#065986',
    blueLight900 : '#0B4A6F',
    /**********blue***********/
    blue25 : '#F5FAFF',
    blue50 : '#EFF8FF',
    blue100 : '#D1E9FF',
    blue200 : '#B2DDFF',
    blue300 : '#84CAFF',
    blue400 : '#53B1FD',
    blue500: '#2E90FA',
    blue600: '#1570EF',
    blue700 : '#175CD3',
    blue750 : '#295DB1',
    blue800 : '#1849A9',
    blue900 : '#194185',
    /**********indigo***********/
    indigo25 : '#F5F8FF',
    indigo50: '#EEF4FF',
    indigo100 : '#E0EAFF',
    indigo200 : '#C7D7FE',
    indigo300 : '#A4BCFD',
    indigo400: '#8098F9',
    indigo500 : '#6172F3',
    indigo600 : '#444CE7',
    indigo700 : '#3538CD',
    indigo800 : '#2D31A6',
    indigo900 : '#2D3282',

}
   
