
import React from 'react';
import { styled } from '@mui/material/styles';
import ChangeLang from './ChangeLanguage';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { Colors } from '../Utility/Colors';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../ReduxTLK/features/User/LoginSlice';
import NotificationList from '../Notification/NotificationList';
import signalRService from '../../GlobalComponents/Utility/SignalRConnection';
import Logout from './Logout';

export default function NavBarButtons () {
   const dispatch = useDispatch();
    
    const [anchorEl, setAnchorEl] = React.useState(null);
   // const [anchorElNotification, setAnchorElNotification] = React.useState(null);
    const [notificationListOpen, setNotificationListOpen] = React.useState(false)
   

    const openNotificationList = (event) => {
      setAnchorEl(event.currentTarget);
      setNotificationListOpen(true);
    };
    const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
   
    dispatch(logoutUser())
    signalRService?.connection?.stop();
    navigate('/');
  };
    return (
      <>
        <GridContainer 
      spacing={-1}
      display='flex'
      justifyContent='flex-end'
      alignItems='flex-end'
      direction='row'
      sx={{padding : 0, margin : 0}}
      >
          {/* <GridItem sx={4} md={4} sm={4} xl={4}>
        
            <ChangeLang />
           
          </GridItem> */}
          
          <GridItem sx={4} md={4} sm={4} xl={4} >
            <NotificationList 
            open={notificationListOpen}
            setNotificationListOpen={setNotificationListOpen} 
            // anchorEl={anchorElNotification} 
            openNotificationList={openNotificationList}/>
          </GridItem>
          <GridItem sx={4} md={4} sm={4} xl={4}>
            {/* <IconButton color={blue} sx={{ paddingTop : '0px' }}  onClick={handleMenu}>
              <StyledBadge
                // badgeContent={4}
                
                
              >
                <ProfileIcon />
              </StyledBadge>
            </IconButton> */}
            <Logout />
          </GridItem>
        </GridContainer>
         <Menu
         id="menu-appbar"
         anchorEl={anchorEl}
         anchorOrigin={{
           vertical: 'top',
           horizontal: 'right',
         }}
         keepMounted
         transformOrigin={{
           vertical: 'top',
           horizontal: 'right',
         }}
         open={Boolean(anchorEl)}
         onClose={handleClose}
       >
         {/* <MenuItem >Profile</MenuItem> */}
         <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
       </Menu>
      
       </>
    )
}