
import React, { useState, useRef, useEffect } from 'react';
import { Button,  Dialog, DialogContent, IconButton, DialogTitle, DialogActions } from "@mui/material";
import GridContainer from "../../../GlobalComponents/Grid/GridContainer";
import GridItem from "../../../GlobalComponents/Grid/GridItem";
import UploadCloudIcon from "../../../GlobalComponents/IconComponents/UploadCloudIcon";
import { Colors } from "../../../GlobalComponents/Utility/Colors";
import H4 from "../../../GlobalComponents/TypoGraphy/H4";
import SmallText from "../../../GlobalComponents/TypoGraphy/SmallText";
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from "../../../GlobalComponents/IconComponents/UploadIcon";
import MobileAndMonitorIcon from '../../../GlobalComponents/IconComponents/MobileAndMonitorIcon';
import "./style.css";
import { AddAttachment} from '../../../ReduxTLK/features/Attachement/AttachementSlice';
import { useDispatch, useSelector } from 'react-redux';

import FileIcon from '../../../GlobalComponents/IconComponents/FileIcon';
import TrashIcon from '../../../GlobalComponents/IconComponents/TrashIcon';
import ImageIcon from '../../../GlobalComponents/IconComponents/ImageIcon';

import Snackbars from "../../../GlobalComponents/Alerts/Snackbars";
import GenericDialogEditTicketSaveConfirmation from '../../../GlobalComponents/Dialog/GenericDialogEditTicketSaveConfirmation';
import { resetEditTicketDraftByIndex, setEditTicketDraft } from '../../../ReduxTLK/features/SAV/NewTicketDraftSlice';
import { Persistor } from '../../..';


export default function UploadFile(props) {
  
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [fileSize, setFileSize] = useState()
  const [fileType, setFileType] = useState()
  const [selected, setSelected] = useState(false)
  const [message, setMessage] = useState()
  const [openErrorBox, setOpenErrorBox] = useState(false)
  const [openSuccessBox, setOpenSuccessBox] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [wantToClose, setWantToClose] = useState(false)
  const [openConfirmCloseModal,setOpenConfirmCloseModal] = useState(true)
  const editedTicketDraft = useSelector((state) => state.draftNewTicket.editedTicketDraft);
  const dispatch = useDispatch()
  const handleClose = () => {
    setWantToClose(true)

   
      setOpen(false)
      setSelected(false)
      setFile()
      setFileSize()
      setFileType()
      setSelectedFiles([])
  
   
  }
  const UploadFileModal = () => {
    setOpen(true)
  }

  const inputFile = useRef(null)
  useEffect(() => {
    
    if (inputFile?.current?.value) {
      setFile(inputFile?.current?.value?.substring(12, inputFile?.current?.value?.length))
      
      setSelected(true)
    }
     
  }, [inputFile?.current?.value])

  const [form, setForm] = useState(new FormData());
  const handleAttach = () => {
    //open file selector
  
    //  open file input box on click of another element
    inputFile.current.click();
   
  //  setFile(inputFile.current.value.substring(12, inputFile.current.value.length))
  //  setSelected(true)
  };
  // Function to generate a random ID
const generateRandomId = () => {
  return Math.random().toString(36).substr(2, 9);
};

// Function to generate a unique random ID not present in selectedFiles
const generateUniqueRandomId = (selectedFiles) => {
  let newId;
  do {
    newId = generateRandomId();
  } while (selectedFiles.some((item) => item.id === newId));
  return newId;
};
  const handleFileChange = (event) => {
    
    const fileObj = {id : generateUniqueRandomId(selectedFiles), file : event.target.files && event.target.files[0]};
    
    if (!fileObj) {
      return;
    }
    /***************unsaved changes  */
   
    setDirty(true)
    
    let editedTicketDraftCopy = [...editedTicketDraft];
   
    const existingIndex = editedTicketDraftCopy.findIndex(ticket => ticket.id === props.ticket);
   
    if (existingIndex === -1) {

        // If the item with the same ID does not exists, 
        editedTicketDraftCopy = [
          ...editedTicketDraftCopy,
          {
              updatedItem: {},
              id: props.ticket
          }
      ];
    } 

    dispatch(setEditTicketDraft(editedTicketDraftCopy));
    Persistor.persist();
    /*********** */
    const newFilesList =[ ...selectedFiles, fileObj];
    setSelectedFiles(newFilesList);
   
    setSelected(true);
  
    setFile(fileObj.name);
    setFileSize(fileObj.size);
    setFileType(fileObj.type);
  
   
  };
  useEffect(() => {
   
   // setDirty(true)
    setForm((prevForm) => {
      const newForm = new FormData();
      selectedFiles.forEach((item, index) => {
        newForm.append(`fichiers`, item.file);
      });
      
      return newForm;
    });
  }, [selectedFiles]);
  const handleSave = () => {
    if (selectedFiles.length === 0) {
    
      return;
    }
  // Check file type
  const allowedFileTypes = ['png', 'jpeg', 'jpg' ,'pdf', 'docx', 'doc', 'xlsx', 'txt'];

// Validate file types before processing
const invalidFiles = selectedFiles.filter(item => !allowedFileTypes.includes(item.file.name?.toLowerCase().split('.').pop()));

if (invalidFiles.length > 0) {
  setMessage(`Type de fichier non valide. Types autorisés : PDF, DOCX, TXT, JPEG, JPG, PNG.`);
  setOpenErrorBox(true);
  return;
}

 
  // Check file size
  const maxFileSizeInMB = fileType?.startsWith('image') ? 5 : 2;
  const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024;
  const invalidSizes = selectedFiles.filter(item => item.file.size > maxFileSizeInBytes)
  if(invalidSizes.length > 0) {
    setMessage(`La taille du fichier dépasse la limite autorisée de ${maxFileSizeInMB} Mo.`)
    setOpenErrorBox(true);
    return;
  }
    const data = { idTicket: props.ticket, array: form};
    
    try {
      dispatch(AddAttachment(data)).then((res) => {
        setWantToClose(false);
        setDirty(false)
        dispatch(resetEditTicketDraftByIndex(props.ticket))
        props.fetchData(props.paginationModelCustom);

      });

      setMessage('Vos fichiers ont été ajouté avec succès')
      setOpenSuccessBox(true)
      
      handleClose();
      
      setSelected([]);
     
    } catch (err) {
     
      setWantToClose(false);
      setDirty(false)
      setMessage('Un problème est survenu lors du sauvgarde des fichiers importés')
      setOpenErrorBox(true)
      handleClose();
      setSelected([]);
  
    }
  };
  const resetSelection = (id) => {
    
    const filteredSelection = selectedFiles.filter((item) => item.id !== id);
   
  
    setSelectedFiles(filteredSelection);
  
    if (filteredSelection.length === 0) {
      setSelected(false);
      setForm(new FormData());
    }
  };
 
  const getFileIcon = (type) => {
    if (type) {
      
      if (type.includes('image')) {
        return <ImageIcon />;
      } 
      else if (type.includes('pdf')) {
        return <FileIcon />;
      } else {
        return <FileIcon />;
      }
    }
    return null;
  };

  return (
    <div style={{

      //backgroundColor : '#F4F7FE',
      height: '55px',
      width: '100%',
      // zIndex : '2',
    }}>
      <GridContainer
        sx={{
          display: 'flex',
          direction: 'row',
          alignItems: 'flex-end',

        }}
      >
        {/* <Card> */}
        <GridItem sx={6} sm={6} md={10.85} xl={10.85} xxl={10.85}>

        </GridItem>
        <GridItem sx={6} sm={6} md={0.75} xl={0.75} xxl={0.75}>
          <Button variant="outlined"
            onClick={UploadFileModal}
            startIcon={<UploadIcon color={Colors.primary} />}
            sx={{
              color: `${Colors.primary}`,
              // margin: '16px',
              marginTop: '3px',
              display: 'flex',
              width: '250px',
              padding: '10px 0px', 
              // marginRight : '-200px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 'var(--spacing-3xs, 8px)',
              borderRadius: 'var(--units-unit-4, 16px)',

              border: '1px solid #D9D9D9',

              background: '#FFF',
                                        
              boxShadow: '0px 4px 12px -4px rgba(0, 0, 0, 0.12)',
              '&.MuiButtonBase-root': {
                borderRadius: '100px',
                textTransform: 'none',
                fontWeight: 'bold'

              }
            }}
          > Importer un fichier</Button>
        </GridItem>
        {/* </Card> */}
      </GridContainer>
     
      <Dialog open={open} fullWidth maxWidth='sm'>
        <DialogTitle>
          <GridContainer
            sx={{
              display: 'flex',
              direction: 'row',
              alignItems: 'stretch',

            }}
          >
            <GridItem sx={11.5} sm={11.5} md={11.5} xl={11.5} xxl={11.5}>
              <H4 title='Ajouter fichier(s)'>

              </H4>


            </GridItem>
            <GridItem sx={0.010} sm={0.010} md={0.010} xl={0.010} xxl={0.010}>
              <IconButton sx={{ marginRight: 0 }} onClick={handleClose}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </GridItem>

           <GridItem sx={12} sm={12} md={12} xl={12} xxl={12}>


              <SmallText title='Importer et attacher un ou plusieurs fichier(s)'></SmallText>
              
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent>

          <>
         {/* { (wantToClose && dirty) ? <>aaaaaaaaaaaaa</> :<></>} */}
            <div className="file-upload-base">
              <div className="content">
                <div className="featured-icon">
                  <UploadCloudIcon className="upload-cloud" />
                </div>
                <div className="text-and-supporting">
                  <div className="action">
                    <button className="button" onClick={handleAttach}>
                      <MobileAndMonitorIcon className="device-mobile-and" />
                      <div className="text">Parcourir vos fichiers</div>
                      <input type='file' id='file' ref={inputFile} style={{ display: 'none' }}  onChange={handleFileChange} />
                    </button>
                  </div>
                  <p className="supporting-text">Fichier Texte  (PDF, DOCX, TXT):  2 MB 
                  <br></br> Images (JPEG, PNG, ): 5 MB
                  </p>
                  {/* <p className="supporting-text">Images (JPEG, PNG, ): 5 MB</p> */}
                </div>
              </div>
            </div>
            <br></br>
          {/* { selected ? ( */}
             
          {selectedFiles.map((item, index) => (
              <div style={{
                gap: '4px',
                width: '100%',
                height: 'auto',
                display: 'flex',
                padding: '16px',
                alignSelf: 'stretch',
                alignItems: 'flex-start',
                flexShrink: 0,
                borderColor: 'rgba(42, 102, 198, 1)',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '12px',
                backgroundColor: 'var(--dl-color-base-white)',
                marginBottom: '5px'
              }}>
                 
                 
              
                 
     <div key={index} style={{ 
      gap: '16px',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'flex-start'
    }}>
      <div style={{
        width: '28px',
        height: '28px',
        display: 'flex',
        position: 'relative',
        alignItems: 'flex-start',
        flexShrink: 0
      }}>
        <div style={{
          top: '-2px',
          left: '-2px',
          width: '32px',
          height: '32px',
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          flexShrink: 0,
          borderColor: 'rgba(248, 249, 251, 1)',
          borderStyle: 'solid',
          borderWidth: '4px',
          borderRadius: '28px',
          justifyContent: 'center',
          backgroundColor: 'var(--dl-color-gray-100)'
        }}>
          {getFileIcon(item.file.type)}
        </div>
      </div>
      <div style={{
        gap: '4px',
        width: '390px',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'flex-start',
        flexShrink: 0,
        flexDirection: 'column',
      }}>
        <div style={{
          display: 'flex',
          alignSelf: 'stretch',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}>
          <span style={{
            color: 'var(--dl-color-black-l2)',
            height: 'auto',
            fontSize: '16px',
            alignSelf: 'stretch',
            fontStyle: 'Regular',
            textAlign: 'left',
            fontFamily: "DM Sans",
            fontWeight: 400,
            lineHeight: '150%',
            fontStretch: 'normal',
            textDecoration: 'none',
          }}>
            <span>{item.file.name}</span>
          </span>
          <span
            style={{
              color: 'var(--dl-color-black-l3)',
              height: 'auto',
              alignSelf: 'stretch',
              textAlign: 'left',
              lineHeight: '20px',
            }}
          >
            {item.file.size} bytes
          </span>
        </div>
      </div>
    </div>
    <div style={{
      gap: '4px',
      height: '52px',
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'center',
      flexShrink: 0,
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <div className="check-instance" />
      <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4.5" y="4.5" width="15" height="15" rx="7.5" fill="#2D874E" />
        <rect x="4.5" y="4.5" width="15" height="15" rx="7.5" stroke="#2D874E" />
        <path d="M15.3334 9.5L10.7501 14.0833L8.66675 12" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <button
        onClick={() => resetSelection(item.id)}
        style={{
          display: 'flex',
          padding: '2px',
          overflow: 'hidden',
          alignItems: 'center',
          borderRadius: '8px',
          justifyContent: 'center',
          borderColor: 'transparent'
        }}>
        <TrashIcon sx={{ width: '50%' }}></TrashIcon>
      </button>
    </div>
  </div>

              
              
 ))}
             
           
           
          </> 


        </DialogContent>
        <DialogActions>


          <Button
            sx={{
              textTransform: "none",
              border: '2px solid #2A66C6',
              color: '#2A66C6',
              borderColor: '#2A66C6',
              borderRadius: '18px',
              width: '93px',
              height: '40px',
            }}
            onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>


          <Button onClick={() => handleSave()} variant="contained" shape="rounded"
            sx={{
              // size : 'large', 
              textTransform: "none",
              borderRadius: 32,
              '&.MuiButtonBase-root': {
                color: '#FFFFFF',
                backgroundColor: '#2A66C6',
                borderRadius: '18px',
                // width: '99px',
                height: '40px',
                width: '217px',
                minWidth: '100px',
                marginLeft: 2

              }
            }}
          //color={Colors.primary}
          >Sauvegarder</Button>





        </DialogActions>
      </Dialog> 
     
      <Snackbars  severity={'success'} message={message} open={openSuccessBox} setOpen={setOpenSuccessBox} autoHideDuration={1000}/>
      <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autoHideDuration={1000} />
    </div>
  )
}