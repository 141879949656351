// formSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for the form data
const initialState = {};

// Create a slice of the Redux store
const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormData: (state, action) => {
      const { formKey, data } = action.payload;
      // Update the state with the new form data
      state[formKey] = data;
    },
    // Add other form-related reducers as needed
  },
});

// Export the actions
export const { setFormData } = formSlice.actions;

// Export the reducer
export default formSlice.reducer;

// Selectors (optional)
export const selectFormData = (state) => state.form;
