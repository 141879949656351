
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function PdfIcon () {
    return (
       
        <SvgIcon>
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="48" viewBox="0 0 40 48" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M3.75 47.75C1.82 47.75 0.25 46.162 0.25 44.21V3.789C0.25 1.838 1.82 0.25 3.75 0.25H27.5C27.768 0.25 28.019 0.354 28.206 0.545L39.456 11.914C39.646 12.106 39.75 12.361 39.75 12.632V44.21C39.75 46.162 38.18 47.75 36.25 47.75H3.75Z" fill="white"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M27.5 0H3.75C1.682 0 0 1.699 0 3.789V44.211C0 46.3 1.682 48 3.75 48H36.25C38.317 48 40 46.3 40 44.211V12.632C40 12.296 39.868 11.976 39.634 11.738L28.384 0.37C28.149 0.131 27.832 0 27.5 0ZM27.5 0.5C27.701 0.5 27.888 0.578 28.028 0.722L39.278 12.09C39.421 12.234 39.5 12.427 39.5 12.632V44.211C39.5 46.024 38.042 47.5 36.25 47.5H3.75C1.958 47.5 0.5 46.024 0.5 44.211V3.789C0.5 1.976 1.958 0.5 3.75 0.5H27.5Z" fill="#EBECEF"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M40.0005 12.632C40.0005 12.296 39.8685 11.976 39.6345 11.738L28.3845 0.37C28.1495 0.131 27.8325 0 27.5005 0H26.6875V9.664C26.6875 11.753 28.3695 13.453 30.4375 13.453H40.0005V12.632Z" fill="#EBECEF"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M16.8574 40.0947C16.8574 39.8847 16.8304 39.6807 16.7764 39.4827C16.7224 39.2847 16.6414 39.1097 16.5334 38.9557C16.4254 38.8037 16.2904 38.6807 16.1284 38.5867C15.9664 38.4937 15.7804 38.4477 15.5704 38.4477C15.1384 38.4477 14.8134 38.5977 14.5944 38.8977C14.3754 39.1987 14.2654 39.5967 14.2654 40.0947C14.2654 40.3287 14.2944 40.5457 14.3514 40.7467C14.4074 40.9487 14.4934 41.1207 14.6074 41.2647C14.7214 41.4087 14.8574 41.5227 15.0174 41.6067C15.1754 41.6907 15.3604 41.7327 15.5704 41.7327C15.8044 41.7327 16.0024 41.6847 16.1644 41.5887C16.3264 41.4927 16.4604 41.3677 16.5654 41.2147C16.6704 41.0627 16.7454 40.8887 16.7904 40.6937C16.8354 40.4977 16.8574 40.2987 16.8574 40.0947ZM13.2754 37.7637H14.2474V38.3937H14.2654C14.4094 38.1237 14.6104 37.9307 14.8684 37.8137C15.1264 37.6967 15.4054 37.6377 15.7054 37.6377C16.0714 37.6377 16.3914 37.7017 16.6644 37.8307C16.9374 37.9597 17.1634 38.1377 17.3434 38.3627C17.5234 38.5867 17.6584 38.8497 17.7484 39.1497C17.8384 39.4497 17.8834 39.7707 17.8834 40.1127C17.8834 40.4247 17.8424 40.7277 17.7624 41.0217C17.6814 41.3157 17.5584 41.5747 17.3934 41.7997C17.2284 42.0257 17.0194 42.2047 16.7674 42.3397C16.5154 42.4757 16.2184 42.5427 15.8764 42.5427C15.7264 42.5427 15.5764 42.5297 15.4264 42.5017C15.2764 42.4757 15.1324 42.4317 14.9944 42.3717C14.8564 42.3117 14.7294 42.2357 14.6114 42.1427C14.4954 42.0487 14.3974 41.9397 14.3194 41.8137H14.3014V44.1357H13.2754V37.7637Z" fill="#F02B04"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M19.7721 40.1402C19.7721 40.3382 19.7971 40.5342 19.8491 40.7252C19.8991 40.9172 19.9781 41.0882 20.0831 41.2382C20.1881 41.3882 20.3211 41.5082 20.4831 41.5982C20.6451 41.6882 20.8371 41.7332 21.0591 41.7332C21.2871 41.7332 21.4831 41.6852 21.6481 41.5892C21.8141 41.4942 21.9491 41.3682 22.0541 41.2112C22.1591 41.0552 22.2371 40.8792 22.2881 40.6852C22.3381 40.4902 22.3641 40.2902 22.3641 40.0862C22.3641 39.5702 22.2491 39.1682 22.0181 38.8802C21.7871 38.5922 21.4731 38.4482 21.0771 38.4482C20.8371 38.4482 20.6351 38.4972 20.4701 38.5972C20.3051 38.6962 20.1691 38.8252 20.0641 38.9842C19.9591 39.1432 19.8851 39.3232 19.8401 39.5242C19.7941 39.7252 19.7721 39.9302 19.7721 40.1402ZM23.3541 42.4172H22.3821V41.7872H22.3641C22.2261 42.0572 22.0251 42.2502 21.7611 42.3682C21.4971 42.4852 21.2181 42.5432 20.9241 42.5432C20.5581 42.5432 20.2391 42.4792 19.9661 42.3502C19.6921 42.2212 19.4661 42.0452 19.2861 41.8232C19.1061 41.6012 18.9711 41.3382 18.8811 41.0362C18.7911 40.7332 18.7461 40.4082 18.7461 40.0592C18.7461 39.6392 18.8031 39.2762 18.9171 38.9702C19.0311 38.6642 19.1831 38.4122 19.3721 38.2142C19.5611 38.0162 19.7771 37.8702 20.0201 37.7782C20.2631 37.6852 20.5101 37.6382 20.7621 37.6382C20.9061 37.6382 21.0531 37.6522 21.2031 37.6792C21.3531 37.7052 21.4971 37.7492 21.6351 37.8092C21.7731 37.8692 21.9011 37.9462 22.0181 38.0382C22.1351 38.1312 22.2321 38.2422 22.3101 38.3682H22.3281V35.9922H23.3541V42.4172Z" fill="#F02B04"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M24.2363 37.7642H25.0013V37.3772C25.0013 37.0832 25.0373 36.8452 25.1093 36.6612C25.1813 36.4792 25.2773 36.3372 25.3973 36.2392C25.5173 36.1402 25.6533 36.0742 25.8063 36.0402C25.9603 36.0082 26.1203 35.9922 26.2883 35.9922C26.6183 35.9922 26.8583 36.0122 27.0083 36.0542V36.8552C26.9423 36.8372 26.8713 36.8242 26.7973 36.8152C26.7213 36.8062 26.6333 36.8012 26.5313 36.8012C26.3933 36.8012 26.2753 36.8342 26.1753 36.9002C26.0763 36.9662 26.0273 37.0952 26.0273 37.2872V37.7642H26.9003V38.5292H26.0273V42.4172H25.0013V38.5292H24.2363V37.7642Z" fill="#F02B04"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M20.5 18.9521H27.5C27.776 18.9521 28 18.7281 28 18.4521C28 18.1761 27.776 17.9521 27.5 17.9521H20.5C20.224 17.9521 20 18.1761 20 18.4521C20 18.7281 20.224 18.9521 20.5 18.9521Z" fill="#F02B04"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M27.5 20.9521H20.5C20.224 20.9521 20 21.1761 20 21.4521C20 21.7281 20.224 21.9521 20.5 21.9521H27.5C27.776 21.9521 28 21.7281 28 21.4521C28 21.1761 27.776 20.9521 27.5 20.9521Z" fill="#F02B04"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M27.5 23.9521H20.5C20.224 23.9521 20 24.1761 20 24.4521C20 24.7281 20.224 24.9521 20.5 24.9521H27.5C27.776 24.9521 28 24.7281 28 24.4521C28 24.1761 27.776 23.9521 27.5 23.9521Z" fill="#F02B04"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M27.5 26.9521H12.5C12.224 26.9521 12 27.1761 12 27.4521C12 27.7281 12.224 27.9521 12.5 27.9521H27.5C27.776 27.9521 28 27.7281 28 27.4521C28 27.1761 27.776 26.9521 27.5 26.9521Z" fill="#F02B04"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M27.5 29.9521H12.5C12.224 29.9521 12 30.1761 12 30.4521C12 30.7281 12.224 30.9521 12.5 30.9521H27.5C27.776 30.9521 28 30.7281 28 30.4521C28 30.1761 27.776 29.9521 27.5 29.9521Z" fill="#F02B04"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M13 23.9521H17V18.9521H13V23.9521ZM12.5 24.9521H17.5C17.777 24.9521 18 24.7291 18 24.4521V18.4521C18 18.1761 17.777 17.9521 17.5 17.9521H12.5C12.224 17.9521 12 18.1761 12 18.4521V24.4521C12 24.7291 12.224 24.9521 12.5 24.9521Z" fill="#F02B04"/>
</svg>
     </SvgIcon>
    )
}