import React, { useState, useEffect } from "react";
import H4 from "../../../GlobalComponents/TypoGraphy/H4";
import { Colors } from "../../../GlobalComponents/Utility/Colors";
import GridItem from "../../../GlobalComponents/Grid/GridItem";
import GridContainer from "../../../GlobalComponents/Grid/GridContainer";
import MenuItem from '@mui/material/MenuItem';
import H5 from '../../../GlobalComponents/TypoGraphy/H5';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select } from 'antd';
import { NavContext } from '../../../App';
import {
  addNewTicket,
  fetchTickets,
} from "../../../ReduxTLK/features/SAV/TicketSlice";

import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { array_machine, array_typeticket, array_impact, array_priorite } from "../../../ReduxTLK/features/SAV/ticketList";
import Snackbars from "../../../GlobalComponents/Alerts/Snackbars";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchUsersAgentSAV } from "../../../ReduxTLK/features/User/UserSlice";
import { fetchChaines } from "../../../ReduxTLK/features/Chaines/ChaineSlice";
import { fetchPventes, fetchPventesIntervenant } from "../../../ReduxTLK/features/Pointdeventes/PointdeventeSlice";
import ConfirmResetForm from "../AddTicket/ConfirmResetForm";
import { Persistor } from "../../..";
import {  resetNewTicketDraft, setNewTicketDraft } from "../../../ReduxTLK/features/SAV/NewTicketDraftSlice";
import { removeTabById, setLastActiveTab } from "../../../ReduxTLK/features/Tabs/TabsSlice";



const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 22,
  },
  size: 'large',

};

export default function New() {
  const { TextArea } = Input;
  const [open, setOpen] = useState(false);
  const [openErrorBox, setOpenErrorBox] = useState(false);

  const dispatch = useDispatch();
  const context = React.useContext(NavContext)
  const [form] = Form.useForm();
  const [etablissement, setEtablissement] = useState()
  const [pointDeVente, setPointDeVente] = useState()
  const [paginationModelCustom, setPaginationModelCustom] = useState({

    pageSize: 10,
    page: 1,
    nbPage: 1
  })

  const newTicketDraft = useSelector((state) => state.draftNewTicket.newTicketDraft);
  const dirtyNewTicketDraft = useSelector((state) => state.draftNewTicket.dirty);

  // useEffect(() => {
  //  // dispatch(setNewTicketDraft(form.getFieldsValue()));
  //    Persistor.persist()
  // }, [context.cancelledReload])
  useEffect(() => {
   
    if(context.confirmedReload === true) {
    dispatch(resetNewTicketDraft());
    }
  }, [context.confirmedReload])
  useEffect(() => {
    
    if (dirtyNewTicketDraft === true) {
      
      form.setFieldsValue(newTicketDraft);
      dispatch(setNewTicketDraft(newTicketDraft));
      const isFormEmpty = () => {
        const fields = form.getFieldsValue();
        form.setFieldsValue(fields);
        if(fields.etablissement !== undefined && fields.etablissement !== null) {
          setEtablissement(fields.etablissement)
        }
        if(fields.pointDeVente !== undefined && fields.pointDeVente !== null) {
          dispatch(fetchPventesIntervenant(fields.pointDeVente));
        }
        delete fields.impact;
        return Object.values(fields).every(
          value => value === '' || value === undefined || value === null
        );
      };
    
      const isEmpty = isFormEmpty();
     
    
      if (isEmpty) {
       
        setAllFieldsEmpty(true);
      } else {
        
      
        setAllFieldsEmpty(false);
      }
    }
    else{
      form.resetFields();
      dispatch(resetNewTicketDraft());
    }
  }, [dirtyNewTicketDraft]);
 
  const [initialValues, setInitialValues] = useState({
    etablissement: "",
    pointDeVente: "",
    type: "",
    Sujet: "",
    priorite: "",
 
    Description: "",
    machine: "",
    idAssignee: "",
    impact: 0,
    intervenantId: "",
  });

  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("")
  const [selectedChaine, setSelectedChaine] = useState(null);
  const [selectedPV, setSelectedPV] = useState(null);

  const [type, setType] = useState()
  const [sujet, setSujet] = useState()
  const [priorite, setPriorite] = useState()
  const [idAssignee, setAssigne] = useState()
  const [machine, setMachine] = useState()
  const [impact, setImpact] = useState()
  const [onePV, setOnePV] = useState(false)
  const [pVName, setPVName] = useState("")
  

  const onFinish = (values) => {


    dispatch(addNewTicket({ ...values })).then(unwrapResult).then((res) => {
      

      if ((res?.data?.status).toLowerCase() === 'success') {
        setSeverity('success');
        setMessage('Le nouveau ticket a été ajouté avec succès');
        setOpen(true);
       

      }

      setSelectedChaine(null);
      setSelectedPV(null);
      form.resetFields();
      dispatch(resetNewTicketDraft())
      setTimeout(() => {
        const tabToDeleteIndex = context.tabs.findIndex(
          tab => tab.id === 2
      );
        const updatedTabs = context.tabs.filter((tab, index) => {
          return index !== tabToDeleteIndex;
      });

      context.setTabs(updatedTabs);
      dispatch(removeTabById(2))
        context.setActiveTab(3);
        dispatch(setLastActiveTab(3));
        Persistor.persist()
    }, 1000);
      

    }).catch((error) => {

      setSeverity('error');
      setMessage('Un problème est survenu lors de la création du ticket !');
      setOpenErrorBox(true);
    });
    dispatch(fetchTickets(paginationModelCustom)).then(unwrapResult).then((res) => {

      setPaginationModelCustom({
        page: res.data.pagination.currentPage,
        pageSize: res.data.pagination.pageSize,
        nbPage: res.data.pagination.nbPage
      })

    }
    );




  };
  useEffect(() => {
    dispatch(fetchUsersAgentSAV());
    dispatch(fetchChaines());



  }, [])
  useEffect(() => {
   
    dispatch(fetchPventes(etablissement));
   


  }, [etablissement])
  const onChangePV = (item) => {

    setPointDeVente(item);
    dispatch(fetchPventesIntervenant(item));
  };

  const agentsSAV = useSelector((state) => state.user.agentsSAV)

  const intervenant = useSelector((state) => state.pointDeVente.pventesIntervenants)
  const chaines = useSelector((state) => state.chaine.chaines)
  const pointDeVentes = useSelector((state) => state.pointDeVente.pventes)

  const getItemDetails = (item) => `${item.firstName} ${item.lastName}`;
  const [openConfirmReset, setOpenConfirmReset] = useState(false);
  const resetForm = () => {

    if (dirtyNewTicketDraft) {
      
      setOpenConfirmReset(true);

    }
    else {

      form.resetFields();
    }
  }
  
  const [dirty, setDirty] = useState(false)
  const [allFieldsEmpty, setAllFieldsEmpty] = useState(true)
// Function to handle form values change
  const onValuesChange = (changedValues, allValues) => {
 
   // dispatch(setNewTicketDraft(allValues));
   // Persistor.persist()
    const [key, value] = Object.entries(changedValues)[0];
   
    if(key === 'priorite') {
     setPriorite(value);

    }
    if(key === 'etablissement') {
      const updatedNewTicketDraft = {
        ...newTicketDraft,
            etablissement : value,
            pointDeVente: null,
            intervenantId: null,
       
      };
    
    //dispatch(setNewTicketDraft(updatedNewTicketDraft));
     
      Persistor.persist()
        form.setFieldsValue({
      etablissement: value,
      pointDeVente: null,
      intervenantId: null,

    })
    dispatch(setNewTicketDraft(allValues));
    dispatch(fetchPventesIntervenant());
  
    setOnePV(false)
    setEtablissement(value);
   
    dispatch(fetchPventes(value)).then(unwrapResult).then((res) => {

      if (res.data.data.result.length === 1) {
        setPointDeVente(res.data.data.result[0].id)
        setPVName(res.data.data.result[0].nom)
        setOnePV(true)
        dispatch(fetchPventesIntervenant(res.data.data.result[0].id));

        form.setFieldsValue({

          pointDeVente: res.data.data.result[0].id,

        })
      }
    });
    }

    if (key !== 'etablissement') {
      setDirty(true);
     
      dispatch(setNewTicketDraft(allValues))
      Persistor.persist()
    }

  };

  const validateNonEmptyContent = (rule, value, callback) => {
    if (!value || value.trim() === '') {
      callback('Veuillez fournir un texte pertinent.');
    } else {
      callback();
    }
  };
 
  const handleClearPrioriteSelection = () => {
    form.setFieldsValue({
      priorite: null,
    });
  
    setPriorite('');
  
    const isFormEmpty = () => {
      const fields = form.getFieldsValue();
      delete fields.impact;
      return Object.values(fields).every(
        value => value === '' || value === undefined || value === null
      );
    };
  
    const isEmpty = isFormEmpty();
 
  
    if (isEmpty) {
    
      setAllFieldsEmpty(true);
    } else {
  
    
      setAllFieldsEmpty(false);
    }
  };
  
  const handleClearAssigneeSelection = () => {
    form.setFieldsValue({

      idAssignee: null,

    }) // Clear selected value
    const isFormEmpty = () => {
      const fields = form.getFieldsValue();
      delete fields.impact;
      return Object.values(fields).every(
        value => value === '' || value === undefined || value === null
      );
    };
  
    const isEmpty = isFormEmpty();
   
    if (isEmpty) {
      
      setAllFieldsEmpty(true);
    } else {
     
    
      setAllFieldsEmpty(false);
    }
  };
  
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  return (
    <>

      <div style={{position: 'relative', minHeight : '100%', backgroundColor : 'white', height : '87vh' , marginRight : '10px'}} >
      <GridContainer sx={{ minHeight: '100%', maxHeight: '100%' }} >
        <GridItem sx={{ mt: '3%', ml: '34px' }} sm={12} md={12} xl={12} xxl={12}>
          <H4 title='Ajouter un Ticket :' color={Colors.primary}></H4>
        </GridItem>
        <GridItem sx={{ mt: '3%', ml: '34px', mr: '10px', minHeight: '100%', maxHeight: '100%' }} sm={12} md={12} xl={12} xxl={12}>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onValuesChange={onValuesChange}
            initialValues={initialValues}
            onFinish={onFinish}
          >
           
            <GridContainer  >
              <GridItem sx={12} sm={6} md={8} xl={8} xxl={8}>
                <GridContainer >
                  <GridItem sx={12} sm={6} md={6} xl={6} xxl={6} >
                    <H5 title={'Etablissement'} requiredInput={true} />
                    <Form.Item
                      name="etablissement"
                     
                      rules={[
                        {
                          required: true,
                          message: 'Ce champ est obligatoire !',
                        },
                      ]}
                      style={{
                        marginBottom: '0.5%',
                     
                      }}
                    >
                      <Select 
                       showSearch
                       filterOption={filterOption}
                      placeholder="" 
             
                      >
                        {chaines &&
                          chaines.map((chaine) => (
                            <option key={chaine.id} value={chaine.id}>
                              {chaine.nom}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>
                    <H5 title={'Machine'} requiredInput={true} />
                    <Form.Item
                      name="machine"
                    
                      rules={[
                        {
                          required: true,
                          message: 'Ce champ est obligatoire !',
                        },
                      ]}
                      style={{
                        marginBottom: '0.5%',
                        
                      }}
                  
                    >
                      <Select placeholder="" 
                       showSearch
                       filterOption={filterOption}
                      >
                        {array_machine &&
                          array_machine.map((machine) => (
                            <option key={machine.id} value={machine.id}>
                              {machine.nom}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem sx={12} sm={6} md={6} xl={6} xxl={6} >
                    <H5 title={'Point de vente'} requiredInput={true} />
                    {onePV ? (<Form.Item
                      name="pointDeVente"
                      initialValue={pointDeVente}
                     
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      style={{
                        marginBottom: '0.5%',
                      
                      }}
                   
                    >
                      <Select placeholder="" onChange={onChangePV}  showSearch
      filterOption={filterOption}>
                        {pointDeVentes &&
                          pointDeVentes.map((pv) => (
                            <option key={pv.id} value={pv.id}>
                              {pv.nom}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>) : (<Form.Item
                      name="pointDeVente"
                     
                      rules={[
                        {
                          required: true,
                          message: 'Ce champ est obligatoire !',
                        },
                      ]}
                      style={{
                        marginBottom: '0.5%',
                       
                      }}


                    >
                      <Select placeholder="" onChange={onChangePV}  showSearch
      filterOption={filterOption}>
                        {pointDeVentes &&
                          pointDeVentes.map((pv) => (
                            <option key={pv.id} value={pv.id}>
                              {pv.nom}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>)}
                    <H5 title={'Type de problème'} requiredInput={true} />
                    <Form.Item
                      name="type"
                    
                      rules={[
                        {
                          required: true,
                          message: 'Ce champ est obligatoire !',
                        },
                      ]}
                      style={{
                        marginBottom: '0.5%',
                       
                      }}

                    >
                      <Select
                       showSearch
                       filterOption={filterOption}
                      placeholder="" sx={{
                        width: '100%'
                      }}>
                        {array_typeticket &&
                          array_typeticket.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.nom}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem sx={12} sm={12} md={12} xl={12} xxl={12}>
                    <H5 title={'Sujet'} requiredInput={true} />
                    <Form.Item
                      name="sujet"
                  
                      rules={[
                        {
                          maxLength: 100,
                          required: true,
                          message: 'Ce champ est obligatoire !',
                          validator: validateNonEmptyContent
                        },
                      ]}
                      style={{
                        marginBottom: '0.5%',
                       
                      }}

                    >
                      <Input style={{ width: "104%", backgroundColor:'white' }} maxLength='100' count={{
                        show: true,
                        max: 100,
                      }} />
                    </Form.Item>
                  </GridItem>
                  <GridItem sx={12} sm={6} md={6} xl={6} xxl={6}>
                    <H5 title={'Impact de ticket'} requiredInput={false} />
                    <Form.Item
                      name="impact"
                     
                      rules={[
                        {
                          required: false,
                       
                        },
                      ]}
                      style={{
                        marginBottom: '0.5%',
                       
                      }}
                    
                    >
                      <Select
                    showSearch
                    filterOption={filterOption}
                       placeholder="" >
                        {array_impact &&
                          array_impact.map((impact) => (
                            <option key={impact.id} value={impact.id}>
                              {impact.nom}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>
                    <H5 title={'Assigner à'} requiredInput={false} />
                    <Form.Item
                      name="idAssignee"
                     
                      rules={[
                        {
                          required: false,
                    
                        },
                      ]}
                      style={{
                        marginBottom: '0.1%',
                       
                      }}
                  
                    >
                      <Select 
                      allowClear
                      showSearch
                      filterOption={filterOption}
                      onClear={handleClearAssigneeSelection}
                      placeholder="" >
                        {agentsSAV &&
                          agentsSAV.map((assigne) => (
                            <option key={assigne.id} value={assigne.id}>
                              {assigne.nom}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>
                    
                  </GridItem>
                  <GridItem sx={12} sm={6} md={6} xl={6} xxl={6}>
                    <H5 title={'Priorité du ticket' } requiredInput={false} />
                    <Form.Item
                      name="priorite"
                     
                      rules={[
                        {
                          required: false,
                        
                        },
                      ]}
                      style={{
                        marginBottom: '0.5%',
                      
                      }}

                    >
                      <Select 
                      allowClear
                      showSearch
                      filterOption={filterOption}
                      onClear={handleClearPrioriteSelection}
                      placeholder="" >
                        {array_priorite &&
                          array_priorite.map((priorite) => (
                            <option key={priorite.id} value={priorite.id}>
                              {priorite.nom}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>


                    <H5 title={'Intervenant'} requiredInput={true} />
                    <Form.Item
                      name="intervenantId"
                     
                      rules={[
                        {
                          required: true,
                          message: 'Ce champ est obligatoire !',
                        },
                      ]}
                      style={{
                        marginBottom: '0.1%',
                        marginRight: "0px"
                      
                      }}

                    >
                      <Select
                       showSearch
                       filterOption={filterOption}
                        variant={'outlined'}
                        required={true}
                        sx={{
                          width: '100%',
                          border: '1px ',
                          boxShadow: '0px 1px 2px 0px #1018280D',
                          color: Colors.grey3,
                          borderRadius: '8px',
                         
                          height: 'Hug (44px)',
                        }}
                      >
                        {intervenant.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.id}>{`${option.nom} ${option.prenom}`}</MenuItem>
                          )
                        })}

                      </Select>

                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem style={{ marginTop: '3%', width: "100%" }} sm={6} md={6} xl={4} xxl={4}>
                <GridContainer>
                  <GridItem sx={12} sm={12} md={12} xl={12} xxl={12}>
                    <H5 title={'Description'} requiredInput={true} />
                    <Form.Item
                      name="description"
                     
                      rules={[
                        {
                          required: true,
                          message: 'Ce champ est obligatoire !',
                          maxLength: 350,
                          validator: validateNonEmptyContent
                        },
                      ]}
                    >
                      <TextArea
                       rows={9} 
                       autoSize={{ minRows: 9, maxRows: 9 }}
                       
                        maxLength='350' count={{
                          show: true,
                          max: 350,
                        }} />
                    </Form.Item>
                  </GridItem>

                </GridContainer>
              </GridItem>
            </GridContainer>
            <Snackbars severity={'success'} message={message} open={open} setOpen={setOpen} autohideduration={1000} />
            <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autohideduration={1000} />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
           

              <br></br>

           
               { !allFieldsEmpty ?
               <div 
               style={{ 
                position: 'absolute', 
                bottom: 0, 
                right: 0, 
                gap : 20,
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'flex-end',
                width: '100%',
                padding: '20px', 
                borderRadius: '16px',
                boxShadow: '0.5px 1px 2px 0.5px rgba(16, 24, 40, 0.3)',
              }}
               >
   
      <Button
        sx={{
          textTransform: 'none',
          borderRadius: 32,
          '&.MuiButtonBase-root': {
            color: '#FFFFFF',
            backgroundColor: '#2A66C6',
            width: '217px',
            height: '40px',
          },
          ':disabled': {
            backgroundColor: ' #F3F4F6',
            color: '#9CA3AF',
            borderColor: '#F3F4F6',
          },
        }}
        disabled={!dirtyNewTicketDraft}
        type="submit"
        variant="contained"
      >
        Sauvegarder
      </Button>
      <Button
        sx={{
          textTransform: 'none',
          borderRadius: 32,
          '&.MuiButtonBase-root': {
            color: '#2A66C6',
            borderColor: '#2A66C6',
            borderRadius: '100px',
            width: '93px',
            height: '40px',
            ':disabled': {
              backgroundColor: ' #F3F4F6',
              color: '#9CA3AF',
              borderColor: '#F3F4F6',
            },
          },
        }}
        variant="outlined"
        onClick={resetForm}
        disabled={!dirtyNewTicketDraft}
      >
        Annuler
      </Button>
  
  </div> : null}
             

          </Form>

        </GridItem>
      </GridContainer>
     </div > 

      <ConfirmResetForm open={openConfirmReset} setOpen={setOpenConfirmReset}
        reset={form} setSelectedChaine={setSelectedChaine} setSelectedPV={setSelectedPV}
        setDirty={setDirty} dirtyNewTicketDraft={dirtyNewTicketDraft} setAllFieldsEmpty={setAllFieldsEmpty}
      />
        </>
    );

}