import React from 'react';
import { Rate } from 'antd';


const NoteRate = ({ rate }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginRight : '20px' }}>
      {rate && (
        <>
          <span
            style={{
              fontFamily: 'DM Sans',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '0em',
              textAlign: 'left',
              marginRight: '10px',
            }}
          >
            Note globale
          </span>{' '}
          <Rate 
           allowHalf
           defaultValue={rate} 
           disabled
           style={{
            fontSize: 20,
            color : '#FDB022'

          }}
          />
        </>
      )}
    </div>
  );
};

export default NoteRate;
