
import React, { useContext, useEffect, useState } from "react";


import { useDispatch, useSelector } from "react-redux";

import { Popover, Typography} from "@mui/material";
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';
import { Button} from "@mui/material";
import signalRService from '../Utility/SignalRConnection';

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { Colors } from '../Utility/Colors';
import Badge from '@mui/material/Badge';
import ProfileIcon from '../../GlobalComponents/IconComponents/ProfileIcon';

import Logo from '../../assets/images/Logo.svg'
import { fetchUser } from "../../ReduxTLK/features/User/UserSlice";
import LogoutIcon from "../IconComponents/LogoutIcon";
import { logoutUser } from '../../ReduxTLK/features/User/LoginSlice';
import { useNavigate } from 'react-router-dom';

import { clearTabs, setLastActiveTab } from "../../ReduxTLK/features/Tabs/TabsSlice";
import { clearPaginations } from "../../ReduxTLK/features/Pagination/PaginationsSlice";
import { resetNewTicketDraft } from "../../ReduxTLK/features/SAV/NewTicketDraftSlice";
import { NavContext } from "../../App";
import { closeFilterBox, resetFilter, resetFilterCounter, resetFilterModel } from "../../ReduxTLK/features/SAV/FilterSlice";

const StyledBadge = styled(Badge) ({
  
    color :  '#295DB1',
     backgroundColor: 'white'
   
 
});
export default function Logout(props) {
  const blue = Colors.blue750
  const dispatch = useDispatch()
  const navigate = useNavigate();
  //const [newNotification, setNewNotification] = useState()
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState();
  const context = useContext(NavContext)
  const openModal = (event) => {
    
    setOpen(true);
   

  };
  
  
  const handleDrawerClose = () => {
    setOpen(false);
  };
 
  useEffect(() => {
    dispatch(fetchUser()).then((res) => 
    
    setUser(res?.payload?.data?.data?.result?.user)
    )
  },[]);
  
  const handleLogout = async () => {
    
   
    dispatch(logoutUser()).then(() => {
      context.setNotifcationHub()
      dispatch(setLastActiveTab());
      dispatch(clearTabs())
      dispatch(clearPaginations())
      dispatch(resetNewTicketDraft())
      dispatch(resetFilterModel())
      dispatch(resetFilter())
      dispatch(closeFilterBox())
      dispatch(resetFilterCounter())
    //  signalRService.onDisconnectUser();
      signalRService?.connection?.stop();
    navigate('/');
    });
    
   

    
  };
  return (
    <>
       
       <IconButton color={blue} sx={{ paddingTop : '0px' }}  onClick={openModal}>
              <StyledBadge
                // badgeContent={4}
                
                
              >
                <ProfileIcon />
              </StyledBadge>
            </IconButton>
           
        {/* <Box sx={{ position: 'relative', bottom: 0, right: 0 ,
        // width : '30%'
         }} >
       */}
 <Popover
   // onClick={(event) => {event.stopPropagation()}}
    open={open}
    onClose={
        (reason) => {
        if (reason === 'backdropClick') {
          // Handle backdrop click specifically
          handleDrawerClose();
        } else {
            handleDrawerClose();
          // Handle other closing reasons (e.g., escape key, close button)
        }
      }
    }
  //  variant='temporary'
 //   hideBackdrop
    keepMounted={false}
   // width="50%"
    anchor="right"
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    PaperProps={{
      sx: {
        height: '320px',
        width: '340px',
      //  position: 'fixed',
        marginLeft: "auto",
        marginTop : 5,
        padding : 0,
        borderRadius : '16px',
        // scrollbarColor : 'blue',
        // scrollBehavior : "smooth",
        marginRight: 2,
        overflowY: 'auto',
      },
    }}
    ModalProps={{
        keepMounted: true,
        slots: { backdrop: "div" },
        slotProps: {
          root: { //override the fixed position + the size of backdrop
            style: {
              position: "absolute",
              top: "unset",
              bottom: "unset",
              
              left: "unset",
              right: "unset",
            },
          },
        },
       
      }}
  >

   
     
   
     
            <GridContainer 
             display='flex'
    //   justifyContent='center'
    //   alignItems='center'
      direction='Column'

      >
             <GridItem sx={{marginLeft : 8, marginTop : 2}} ><img src={Logo} alt='Logo' width="70%" /> </GridItem>
             <GridItem sx={{ marginTop : '35px'}} >
                <Typography
                sx={{
                    color: '#252525',

                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                   // fontStyle: 'normal',
                    fontWeight: "bold",
                   lineHeight: "normal",
                    // weight : "24px",
                    // height : "20px",
                    textTransform: "none",
                  }}
                >
                    {user?.lastName} {''} {user?.firstName}
                </Typography>
             </GridItem>
             <GridItem sx={{ marginTop : '25px'}} >
             <Typography
              sx={{
                color: '#26235C',

                textAlign: "center",
                fontFamily: "DM Sans",
                fontSize: "14px",
               // fontStyle: 'normal',
                fontWeight: "400",
               lineHeight: "16.8px",
               alignSelf : "center",
                // weight : "24px",
                // height : "20px",
                textTransform: "none",
              }}
             >
                    {user?.email} 
                </Typography>
             </GridItem>
             <GridItem sx={{ marginTop : '20px'}} >
                <Button 
                variant="contained"

                onClick={handleLogout}
                sx={{
                    '&.MuiButtonBase-root': {
                      // color: '#2A66C6',
                      // borderColor: '#2A66C6',
                      borderRadius: '12px',
                      width: '217px',
                      height: '40px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '48px',
                      marginLeft: '60px',
                      marginBottom : '-10px',
                      textTransform : 'none'
                     
                    }
                  }}
                
                >
                  <Typography sx={{
                    color: '#FFF',

                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: "17px",
                   // fontStyle: 'normal',
                   // fontWeight: "700",
                   lineHeight: "24px",
                    // weight : "24px",
                    // height : "20px",
                    textTransform: "none",
                    marginRight : '10px'
                  }} >
                   
                   Se déconnecter
                  </Typography>  <LogoutIcon ></LogoutIcon>  
                   </Button>
             </GridItem>
            
            </GridContainer>
            
  
  
    
        </Popover>
       
       
       
    </>
  )
}