import { createSlice } from '@reduxjs/toolkit';


const initialState = {

    filter: null,
    dirty: false,
    filterModel: {
        paginationModel: {
            page: 1,
            pageSize: 12,
            nbPage: null
        },
        body: {

        },
    },
    filterBox: false,
    counter: null

};

export const FilterSlice = createSlice({
    name: 'Filter',
    initialState,
    reducers: {
        setFilterDraft: (state, action) => {

            state.filter = action.payload;
            state.dirty = true;

        },
        setFilterModel: (state, action) => {
            state.filterModel = action.payload;
        },
        setFilterCounter: (state, action) => {
           
            state.counter = action.payload;
        },
        resetFilterCounter: (state, action) => {
            state.counter = null;
        },
        openFilterBox: (state, action) => {
            state.filterBox = true;
        },
        closeFilterBox: (state, action) => {
            state.filterBox = false;
        },

        resetFilterModel: (state, action) => { 
            state.filterModel = {
                paginationModel: {
                    page: 1,
                    pageSize: 12,
                    nbPage: null
                },
                body: {

                },
            };
        },

        getFilterDraft: (state, action) => {
            // This function doesn't modify the state, just returns the newTicketDraft value
            return state.filter;
        },
        resetFilter: (state, action) => {
            state.filter = null;
            state.dirty = false;


        },

    },
});

export const { setFilterDraft, getFilterDraft, resetFilter,
    setFilterModel, resetFilterModel,
    openFilterBox, closeFilterBox,
    resetFilterCounter, setFilterCounter
} = FilterSlice.actions;
export default FilterSlice.reducer;