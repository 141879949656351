import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function ExecuteIcon () {
    return (
       
        <SvgIcon>
 <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
<g id="Icon">
<path d="M7.50016 14.1666C11.1821 14.1666 14.1668 11.1818 14.1668 7.49992C14.1668 3.81802 11.1821 0.833252 7.50016 0.833252C3.81826 0.833252 0.833496 3.81802 0.833496 7.49992C0.833496 11.1818 3.81826 14.1666 7.50016 14.1666Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.16683 4.83325L10.1668 7.49992L6.16683 10.1666V4.83325Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>
     </SvgIcon>
    )
}