import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

/**
 * A custom Alert component that extends the MuiAlert component and adds an elevation of 6 and a filled variant.
 *
 * @param {object} props - The props passed to the Alert component.
 * @param {ref} ref - A ref to the Alert component.
 * @returns {React.Component} The CustomAlert component.
 */
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} 
  sx={{
    background : 'linear-gradient(to right , #F6743E, #D42525)'}} />;
});

/**
 * A reusable snackbar component that displays a message with a customizable severity level.
 *
 * @param {string} severity - The severity level of the snackbar message.
 * @param {string} message - The message to display in the snackbar.
 * @returns {React.Component} The Snackbars component.
 */


export default function ErrorBox({open, severity, message, setOpen, autohideduration, header}) {
 
  const [title, setTitle] = useState('Error');
  const [color, setColor] = useState('');
  /**
   * Function to handle the click event on the Open success snackbar button.
   * Sets the open state to true, which opens the snackbar.
   */
  
  // const handleClick = () => {
  //   setOpen(true);
  // };

  /**
   * Function to handle the close event on the snackbar.
   * Sets the open state to false, which closes the snackbar.
   * @param {object} event - The event object.
   * @param {string} reason - The reason why the snackbar was closed.
   */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

   setOpen(false);
  };

  /**
   * UseEffect hook to set the title of the snackbar based on the severity level.
   * @useEffect
   */
  useEffect(() => {
   

    // setOpen(true)
    switch (severity) {
      
      case 'error':
        setTitle('Error');
        setColor('#F0863A')
        break;
      case 'alert':
        setTitle('Alert');
        setColor('#F0863A')
        break;
      case 'success':
        setTitle('Success');
        setColor('#43D590');
        break;
      case 'info':
        setTitle('Info');
        setColor('#7BCFED')
        break;
      default:
        return setTitle('Error');
    }
  }, []);
  
  return (
    <div>
       {/* <Button variant="outlined" onClick={handleClick}>
        Open {title} snackbar
      </Button>  */}
      <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert backgroundColor={color} onClose={handleClose} severity={severity? severity : 'error'} sx={{ width: '100%', minWidth: '320px', maxWidth: '320px' }}>
          <AlertTitle>{title} </AlertTitle>
          {header}
          <br></br>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
