import { Colors } from "../../../GlobalComponents/Utility/Colors"

export const array_priorite = [

    {
        id: 2,
        nom: "Faible"
    },

    {
        id: 1,
        nom: "Majeur"
    },
    {
        id: 0,
        nom: "Critique"
    },
]

export const array_impact = [

    {
        id: 0,
        nom: "Aucun Impact"
    },
    {
        id: 1,
        nom: "Mineur"
    },
    {
        id: 2,
        nom: "Majeur"
    },
    {
        id: 4,
        nom: "Problème_Serveur"
    },
    {
        id: 5,
        nom: "Crise"
    },
    {
        id: 3,
        nom: "Site_Désactivé"
    },
]
export const array_typeticket = [

    {
        id: 0,
        nom: "Logiciel"
    },
    {
        id: 1,
        nom: "Matériel"
    },

]
export const array_machine = [

    {
        id: 0,
        nom: "Borne"
    },
    {
        id: 1,
        nom: "Caisse"
    },
    {
        id: 2,
        nom: "Ecran"
    },
    {
        id: 3,
        nom: "Imprimante"
    },
    {
        id: 4,
        nom: "Autre"
    },
]
export const array_status = [
    {
        id: 0,
        nom: "Ouvert",
        backgroundColor: Colors.velvet,
    },
    {
        id: 1,
        nom: "En attente",
        backgroundColor: Colors.strongOrange,
    },
    {
        id: 2,
        nom: "En cours",
        backgroundColor: Colors.primary,
    },
    {
        id: 3,
        nom: "Resolu",
        backgroundColor: Colors.strongGreen,
    },
    {
        id: 4,
        nom: "Non resolu",
        backgroundColor: Colors.warningOrange,
    },
    {
        id: 5,
        nom: "Annule",
        backgroundColor: Colors.error,
    },
    {
        id: 6,
        nom: "Clôturé",
        backgroundColor: Colors.gardientsGrey,
    },
    {
        id: 7,
        nom: "Nouveau",
        backgroundColor: Colors.velvet,
    },
    {
        id: 8,
        nom: "Résolu",
        backgroundColor: Colors.strongGreen,
    },

]
export const array_causes =
    [
        {
            id: 0,
            nom: `En attente d'une réponse du client`

        },
        {
            id: 1,
            nom: `En attente d'une réponse du technicien`
        }
    ]