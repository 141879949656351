
import React from 'react'


import SvgIcon from '@mui/material/SvgIcon';
export default function DotIcon () {
    return (
<SvgIcon>
<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
<path d="M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z" fill="currentColor"/>
</svg> </SvgIcon>
)}