
import React from 'react'

import SvgIcon from '@mui/material/SvgIcon';
import { Colors } from '../Utility/Colors';
export default function ChevronRightIcon () {
    return (
       
        <SvgIcon>
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
<path d="M6.02827 11.5279C5.76792 11.7883 5.76792 12.2104 6.02827 12.4708C6.28862 12.7311 6.71073 12.7311 6.97108 12.4708L10.9711 8.47075C11.2314 8.2104 11.2314 7.78829 10.9711 7.52795L6.97108 3.52795C6.71073 3.2676 6.28862 3.2676 6.02827 3.52795C5.76792 3.78829 5.76792 4.2104 6.02827 4.47075L9.55687 7.99935L6.02827 11.5279Z" fill="currentColor"/>
</svg>
     </SvgIcon>
    )
}