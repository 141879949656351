

import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
import { Colors } from '../Utility/Colors';
export default function ChatIcon () {
    return (
       
        <SvgIcon>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.47714 0 0 4.17124 0 9.31677C0 12.2488 1.45416 14.864 3.72671 16.5719V20.1242L7.13161 18.2439C8.04031 18.497 9.00298 18.6335 10 18.6335C15.5229 18.6335 20 14.4623 20 9.31677C20 4.17124 15.5229 0 10 0ZM10.9934 12.5466L8.44679 9.81367L3.47785 12.5466L8.94369 6.70808L11.5524 9.44099L16.4592 6.70808L10.9934 12.5466Z" fill={Colors.primary}/>
</svg>
     </SvgIcon>
    )
}