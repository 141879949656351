
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function LogoutIcon () {
    return (
       
        <SvgIcon>

<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="log-out-02">
<path id="Icon" d="M13.8333 14.5197L18 10.353M18 10.353L13.8333 6.18636M18 10.353H8M10.5 14.5197C10.5 15.2947 10.5 15.6822 10.4148 16.0001C10.1836 16.8628 9.50978 17.5367 8.64705 17.7678C8.32913 17.853 7.94164 17.853 7.16667 17.853H6.75C5.58515 17.853 5.00272 17.853 4.54329 17.6627C3.93072 17.409 3.44404 16.9223 3.1903 16.3097C3 15.8503 3 15.2679 3 14.103V6.60303C3 5.43817 3 4.85575 3.1903 4.39632C3.44404 3.78375 3.93072 3.29706 4.54329 3.04333C5.00272 2.85303 5.58515 2.85303 6.75 2.85303H7.16667C7.94164 2.85303 8.32913 2.85303 8.64705 2.93821C9.50978 3.16938 10.1836 3.84325 10.4148 4.70598C10.5 5.0239 10.5 5.41138 10.5 6.18636" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>
  </SvgIcon>
  )
}