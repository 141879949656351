
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function BellIcon () {
    return (
    <SvgIcon>

<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 2.39999C10.0905 2.39999 8.25912 3.15856 6.90886 4.50883C5.55859 5.85909 4.80002 7.69044 4.80002 9.59999V13.9032L3.95163 14.7516C3.78385 14.9194 3.66961 15.1332 3.62332 15.366C3.57704 15.5987 3.60081 15.8399 3.69161 16.0592C3.78242 16.2784 3.93618 16.4658 4.13347 16.5977C4.33077 16.7295 4.56272 16.7999 4.80002 16.8H19.2C19.4373 16.7999 19.6693 16.7295 19.8666 16.5977C20.0639 16.4658 20.2176 16.2784 20.3084 16.0592C20.3992 15.8399 20.423 15.5987 20.3767 15.366C20.3304 15.1332 20.2162 14.9194 20.0484 14.7516L19.2 13.9032V9.59999C19.2 7.69044 18.4415 5.85909 17.0912 4.50883C15.7409 3.15856 13.9096 2.39999 12 2.39999ZM12 21.6C11.0452 21.6 10.1296 21.2207 9.45444 20.5456C8.77931 19.8704 8.40002 18.9548 8.40002 18H15.6C15.6 18.9548 15.2207 19.8704 14.5456 20.5456C13.8705 21.2207 12.9548 21.6 12 21.6Z" fill="#2A66C6"/>
</svg>

</SvgIcon>
    )
}