
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function SuspendIcon () {
    return (
       
        <SvgIcon>
 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M6.99935 9.99967V5.99967M9.66602 9.99967V5.99967M14.9993 7.99967C14.9993 11.6816 12.0146 14.6663 8.33268 14.6663C4.65078 14.6663 1.66602 11.6816 1.66602 7.99967C1.66602 4.31778 4.65078 1.33301 8.33268 1.33301C12.0146 1.33301 14.9993 4.31778 14.9993 7.99967Z" stroke="#E05F03" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
     </SvgIcon>
    )
}