
import React, { useEffect } from "react";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF, useGridApiContext, gridClasses } from '@mui/x-data-grid';
import { IconButton, SvgIcon } from "@mui/material";

import { Pagination, Button, Box } from '@mui/material';
import GridContainer from './GridContainer';
import GridItem from './GridItem';
import DataGridPaginationTitle from './DataGridPaginationTitle';
import ChevronRightIcon from "../IconComponents/ChevronRightIcon";
import ChevronLeftIcon from "../IconComponents/ChevronLeftIcon";
import { Colors } from "../Utility/Colors";

// function CustomPagination({fetchData, page, pageSize,nbPage, setPage }) {





//   return (

//     <GridContainer alignItems="flex">
//       <GridItem sm={8} sx={8}>

//         <DataGridPaginationTitle page={page} count={nbPage} />
//       </GridItem>
//       <GridItem sm={4} sx={4}>

//         <Pagination
//           color="primary"
//           count={nbPage}
//           page={page}
//           pageSize={pageSize}

//          // onChange={handlePageChange}
//         /></GridItem>
//     </GridContainer>




//   );
// }
/**
 * Customized DataGrid component with custom checkbox selection and pagination.
 * It renders a DataGrid with checkbox selection, custom pagination, and custom styling.
 *
 * @param {array} props.rows - The data to be displayed in the grid.
 * @param {array} props.columns - The columns to be displayed in the grid.
 * @param {number} props.pageSize - The number of rows to display per page.
 * @param {array} props.pageSizeOptions - The available page sizes for the pagination.
 * @param {boolean} props.disableRowSelectionOnClick - Whether to disable row selection on click.
 * @returns {React.Component} The CustumDataGrid component.
 */


export default function CustumDataGrid(props) {
  /**
     * Create a custom checkbox selection column with radio buttons instead of checkboxes.
     */
  const columns = React.useMemo(
    () => [
      // {
      //   ...GRID_CHECKBOX_SELECTION_COL_DEF,
      //   width: 50,
      //   renderCell: (cellValues) => {
      //     return (
      //       <div>
      //         <input type="radio" id="html" name="fav_language" value="HTML" />
      //       </div>
      //     )
      //   }
      // },
      ...props.columns,

    ],
    [props.columns],
  );


  const fetchData = props.fetchData

  const handlePageChangeNext = (event) => {

    props.setPaginationModelCustom({
      page: props.paginationModelCustom.page + 1,
      pageSize: props.paginationModelCustom.pageSize,
      nbPage: props.paginationModelCustom.nbPage

    })

    fetchData(props.paginationModelCustom);
  }
  // useEffect(() => {
  //   fetchData(props.paginationModelCustom);
  // }, [props.paginationModelCustom])
  const handlePageChangePrevious = (event) => {

    props.setPaginationModelCustom({
      page: props.paginationModelCustom.page - 1,
      pageSize: props.paginationModelCustom.pageSize,
      nbPage: props.paginationModelCustom.nbPage

    });
    fetchData(props.paginationModelCustom);
  }
  function styleRows(rows, background_color_even = "white", background_color_odd = "gray") {


    const styled_rows = [];

    for (const row of rows) {
      const style = {};
      if (row.id % 2 === 0) {
        style.backgroundColor = background_color_even;
      } else {
        style.backgroundColor = background_color_odd;
      }
      styled_rows.push({ ...row, style });
    }

    return styled_rows;
  }
  const handleChangePage = (event) => {
    props.setPaginationModelCustom({
      page: parseInt(event.target.textContent),
      pageSize: props.paginationModelCustom.pageSize,
      nbPage: props.paginationModelCustom.nbPage

    });
    fetchData(props.paginationModelCustom);
   }
  function CustomFooterComponent(props) {
    return (
      <GridContainer
        display='flex-end'
        justifyContent='flex-end'
        alignItems='end'
        direction='row'
        position='absolute'
        overflow='scroll'
      >
        <GridItem sx={0} sm={6} md={6} lg={6} xl={8} xxl={8}>
          <DataGridPaginationTitle count={props.props.paginationModelCustom?.nbPage} page={props.props.paginationModelCustom?.page} />
        </GridItem>
        <GridItem sx={12} sm={6} md={6} lg={6} xl={0.5} xxl={0.5}>
          <Button disabled={props.props.paginationModelCustom?.page === 1} onClick={() => handlePageChangePrevious()} >
            <IconButton>
              <ChevronLeftIcon ></ChevronLeftIcon>
            </IconButton>
          </Button>
        </GridItem>
        <GridItem sx={0} sm={6} md={6} lg={6} xl={3} xxl={3}>


          <Pagination color="primary"
            shape='rounded' page={props.props.paginationModelCustom?.page} count={props.props.paginationModelCustom?.nbPage} sx={{
              height: '38px',
            }}
            hidePrevButton
            hideNextButton 
            onChange={handleChangePage}
            />
        </GridItem>
        <GridItem sm={6} md={6} lg={6} xl={0.35} xxl={0.35} sx={{
          //paddingTop : '5px',
          position: 'absolute',
          //  bottom : 0
        }}>

          <Button disabled={props.props.paginationModelCustom?.page === props.props.paginationModelCustom?.nbPage} onClick={() => handlePageChangeNext()} >

            <IconButton disabled={props.props.paginationModelCustom?.page === props.props.paginationModelCustom?.nbPage}>
              <ChevronRightIcon ></ChevronRightIcon>

            </IconButton>

          </Button>

        </GridItem>

      </GridContainer>
    );
  }
  return (
    <>
      <DataGrid
        scroll="paper"
        rows={props.rows}
        //hideFooter
        columns={columns}
        page={props.paginationModelCustom?.page}
        setPage={props.setPage}
       // fetchData={props.fetchData}
        pageSize={props.paginationModelCustom?.pageSize}
        //checkboxSelection
      //  disableRowSelectionOnClick={props.disableRowSelectionOnClick}
      //  disableColumnFilter
        editMode={false}
        disableColumnSelector={true}
        slots={{
          pagination: false,
          footer: CustomFooterComponent,
        }}
        slotProps={{
          footer: { props },
        }}
        rowHeight={53}
        columnHeaderHeight={42}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        sx={{
          [`& .${gridClasses.row}.even`]: {
            backgroundColor: '#FCFCFC',
            '&:hover, &.Mui-hovered': {
              backgroundColor: '#FCFCFC',
              '@media (hover: none)': {
                backgroundColor: 'transparent',
              },
            },
          },
          [`& .${gridClasses.row}.odd`]: {
            backgroundColor: Colors.white,
            '&:hover, &.Mui-hovered': {
              backgroundColor: Colors.white,
              '@media (hover: none)': {
                backgroundColor: 'transparent',
              },
            },
          },

          // '&.Mui-selected': {
          //   backgroundColor: alpha(
          //     theme.palette.primary.main,
          //     ODD_OPACITY + theme.palette.action.selectedOpacity,
          //   ),
          //   '&:hover, &.Mui-hovered': {
          //     backgroundColor: alpha(
          //       theme.palette.primary.main,
          //       ODD_OPACITY +
          //         theme.palette.action.selectedOpacity +
          //         theme.palette.action.hoverOpacity,
          //     ),
          // Reset on touch devices, it doesn't add specificity
          // '@media (hover: none)': {
          //   backgroundColor: alpha(
          //     theme.palette.primary.main,
          //     ODD_OPACITY + theme.palette.action.selectedOpacity,
          //   ),
          // },

          //////
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            height: '42x',
            margin: 0,
            padding: 0,
            //  color: var(--Grey-700, #344054);
            
            fontFamily: 'DM Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '145%',
      letterSpacing: '-0.06px',
      
          },
      "&.MuiDataGrid-columnHeaders MuiDataGrid-withBorderColor css-1iyq7zh-MuiDataGrid-columnHeaders" : {
        height: '0px',
      minHeight: '0px',
      maxHeight : '0px',
      alignItems: 'center',
    
          },

      "&.MuiDataGrid-columnHeaderDraggableContainer" : {
        height: '0px',
      minHeight: '0px',
      maxHeight : '0px',
          },
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: '#FFFFFF',
      color: "#344054",
      color: "var(--grey-700, #344054)",
      fontFamily: 'DM Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      
      lineHeight: '17.4px',
      letterSpacing: '-0.05%',
      
      height: '42px'
          },

      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
          },
      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
        display: "none"
          },
      ".MuiDataGrid-grid-scrollContainer": {
        overflow: "scroll !important",
      "&::-webkit-scrollbar-track": {
        width: "3px",
      backgroundColor: "#f5f5f5",
      borderRadius: "10px"
            },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
      "WebkitBoxShadow": "inset 0 0 6px rgba(0,0,0,.3)",
      backgroundColor: "#f5f5f5"
            }
          }
        }}

      />

    </>
  );


}