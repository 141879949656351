
import React, { useState,} from 'react';
import { Button,  Dialog, DialogContent, IconButton, DialogTitle, DialogActions } from "@mui/material";
import GridContainer from "../../../GlobalComponents/Grid/GridContainer";
import GridItem from "../../../GlobalComponents/Grid/GridItem";
import H4 from "../../../GlobalComponents/TypoGraphy/H4";
import CloseIcon from '@mui/icons-material/Close';
import "./style.css";
import {deleteAttachementById,   } from '../../../ReduxTLK/features/Attachement/AttachementSlice';
import { useDispatch } from 'react-redux';

import H5 from '../../../GlobalComponents/TypoGraphy/H5';
import Snackbars from '../../../GlobalComponents/Alerts/Snackbars';
import { unwrapResult } from '@reduxjs/toolkit';

export default function DeleteConfirmationDialog(props) {
  
  
  const [message, setMessage] = useState()
  const [openErrorBox, setOpenErrorBox] = useState(false)
  const [openSuccessBox, setOpenSuccessBox] = useState(false)
  const dispatch = useDispatch()
  const handleClose = () => {
    props.setOpenDeleteModal(false)
    
  }
  const handleDelete = () => {
  
    try {
      dispatch(deleteAttachementById(props.itemToDelete))
      .then(unwrapResult).then(() => {
        
        const filtredItems = props.attachmentList?.filter((item) => item.id !== props.itemToDelete)
        props.setAttachmentList(filtredItems)
      
      setMessage('Votre attachement a été supprimé avec succès')
      setOpenSuccessBox(true)
      handleClose();
      
      });
    
    } catch (err) {
    
      setMessage('Un problème est survenu lors du suppression du fichiers')
     setOpenErrorBox(true)
      handleClose();
    }
  }

  return (
   <>
      
      <Dialog 
      open={props.open} onClose={handleClose} maxWidth='sm'
      
      >
        <DialogTitle>
          <GridContainer
            sx={{
              display: 'flex',
              direction: 'row',
              alignItems: 'stretch',

            }}
          >
            <GridItem sx={11.5} sm={11.5} md={11.5} xl={11.5} xxl={11.5}>
              <H4 title='Etes vous sûr de vouloir supprimer cet attachement ?'>

              </H4>


            </GridItem>
            <GridItem sx={0.010} sm={0.010} md={0.010} xl={0.010} xxl={0.010}>
              <IconButton sx={{ marginRight: 0 }} onClick={handleClose}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </GridItem>
            <GridItem sx={12} sm={12} md={12} xl={12} xxl={12}>


              <H5 title='Vous êtes sur le point de supprimer la pièce jointe.'></H5>
              <H5 title='Cette action est irréversible.'></H5>
              <H5 title='Confirmez vous la suppression de la pièce jointe?'></H5>
              
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent>

          <>
           
          </>


        </DialogContent>
        <DialogActions>


          <Button
            sx={{
              textTransform: "none",
              border: '2px solid #2A66C6',
              color: '#2A66C6',
              borderColor: '#2A66C6',
              borderRadius: '18px',
              width: '93px',
              height: '40px',
            }}
            onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>


          <Button
           onClick={() => handleDelete()}
            variant="contained" shape="rounded"
            sx={{
              // size : 'large', 
              textTransform: "none",
              borderRadius: 32,

              '&.MuiButtonBase-root': {
                color: '#FFFFFF',
                backgroundColor: '#2A66C6',
                borderRadius: '18px',
                // width: '99px',
                height: '40px',
                width: '120px',
                minWidth: '120px',
                marginLeft: 2

              }
            }}
          //color={Colors.primary}
          >Supprimer</Button>





        </DialogActions>
      </Dialog>
      <Snackbars  severity={'success'} message={message} open={openSuccessBox} setOpen={setOpenSuccessBox} autoHideDuration={1000}/>
      <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autoHideDuration={1000} />
      </>
  )
}