

import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function UploadIcon () {
    return (
       
        <SvgIcon>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<path d="M13.6452 14.1774C12.0968 15.7258 10.2151 16.5 8 16.5C5.78495 16.5 3.89247 15.7258 2.32258 14.1774C0.774194 12.6075 0 10.7151 0 8.5C0 6.28495 0.774194 4.40323 2.32258 2.85484C3.89247 1.28495 5.78495 0.5 8 0.5C10.2151 0.5 12.0968 1.28495 13.6452 2.85484C15.2151 4.40323 16 6.28495 16 8.5C16 10.7151 15.2151 12.6075 13.6452 14.1774ZM12.5484 3.95161C11.3011 2.6828 9.78495 2.04839 8 2.04839C6.21505 2.04839 4.68817 2.6828 3.41935 3.95161C2.17204 5.19892 1.54839 6.71505 1.54839 8.5C1.54839 10.2849 2.17204 11.8118 3.41935 13.0806C4.68817 14.328 6.21505 14.9516 8 14.9516C9.78495 14.9516 11.3011 14.328 12.5484 13.0806C13.8172 11.8118 14.4516 10.2849 14.4516 8.5C14.4516 6.71505 13.8172 5.19892 12.5484 3.95161ZM8.64516 12.629H7.35484C7.09677 12.629 6.96774 12.5 6.96774 12.2419V8.5H4.80645C4.63441 8.5 4.51613 8.42473 4.45161 8.27419C4.3871 8.10215 4.4086 7.96237 4.51613 7.85484L7.74194 4.62903C7.91398 4.45699 8.08602 4.45699 8.25806 4.62903L11.4839 7.85484C11.5914 7.96237 11.6129 8.10215 11.5484 8.27419C11.4839 8.42473 11.3656 8.5 11.1935 8.5H9.03226V12.2419C9.03226 12.5 8.90323 12.629 8.64516 12.629Z" fill="#2A66C6"/>
</svg>
</SvgIcon>
 )
}