import { Button } from "@mui/material";
import React from "react";



export default function StatusTicket ({foundStatut}) {
    if (!foundStatut) {
        return '';
      }
return (
    <Button
    variant='outlined' 
    disabled= {true}
    sx={{
     //   marginTop: '10px', 
     //marginRight: "500px",
     textTransform: "none",
     borderRadius: 32,
    
     //  padding:'12px, 5px, 12px, 10px',
     '&.MuiButtonBase-root': {
       color: foundStatut.backgroundColor,
       borderColor: foundStatut.backgroundColor,
       borderRadius: '100px',
       width: '100px',
       height: '30px',
       border : '2px solid',
       fontWeight : 'bold'
       
     }
   }}
   //color="primary"
 > {foundStatut.nom}
   </Button>
)
}
