/**
 * Imports React, createTheme, ThemeProvider, CssBaseline, Box, Card, and NavContext from their respective libraries.
 *
 * @returns {React.Component} The MainContainer component.
 */



import React, { useState, useEffect } from "react"

import { NavContext } from '../../App';
import Tabs from '../TabsContainer/Tabs';

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height
//   };
// }

// export  function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return windowDimensions;
// }

export default function MainContainer(props) {
  /**
   * Access the NavContext object using React.useContext hook.
   */
  const context = React.useContext(NavContext);
  const tabs = context && context.tabs;
  const setTabs = context && context.setTabs;
  /**
            * Tabs component for displaying navigation tabs.
            */
      
  return (
  
            <Tabs tabs={tabs} selectedTab={context.activeTab} setTabs={setTabs} fullScreen={props.fullScreen} setFullScreen={props.setFullScreen}/>
        
  );
}