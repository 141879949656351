import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

export const addNewTicket = createAsyncThunk(
    'ticket/addNewTicket',
    async (data, { rejectWithValue }) => {
        try {
            const tokenStored = localStorage.getItem('TokenStored')
            const timezone = localStorage.getItem('TZ')
            const resultAction = await axios.post(BaseUrl + '/SAV/ajouterTicket', data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': tokenStored,
                        'Platform': 'web',
                        'timezone': timezone,
                    }
                })
            return resultAction
        }

        catch (error) {
            return rejectWithValue(error.response.status)
        }
    }
);

export const fetchFiltredTickets = createAsyncThunk('ticket/fetchFiltredTickets',
    (data) => {
       
        if (data.body === undefined || data.body === null) {
            const obj = {
                paginationModel: {
                    page: data.page,
                    pageSize: data.pageSize
                },
                body: {
                    values: {}
                }
            }
       
        
        const values = obj.body;
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')

        return axios.post(BaseUrl + `/SAV/getListTicketsFiltres?page=${obj.paginationModel.page}&pageSize=${obj.paginationModel.pageSize}`,

            values,

            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokenStored,
                    'Platform': 'web',
                    'timezone': timezone,
                },
            }
        )
            .then((res) => {

                return res.data;

            }


            );
        }
        else {
        
        const values = data.body;
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')

        return axios.post(BaseUrl + `/SAV/getListTicketsFiltres?page=${data.paginationModel.page}&pageSize=${data.paginationModel.pageSize}`,

            values,

            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokenStored,
                    'Platform': 'web',
                    'timezone': timezone,
                },
            }
        )
            .then((res) => {

                return res.data;

            }


            );
        }
    });
export const fetchTickets = createAsyncThunk('ticket/fetchTickets',
    (paginationModel) => {
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')

        return axios.get(BaseUrl + `/SAV/getListTickets?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokenStored,
                    'Platform': 'web',
                    'timezone': timezone,

                }

            }
        )
            .then((res) => {

                return res.data;

            }


            );
    });
export const fetchTicketById = createAsyncThunk('ticket/fetchTicketById',
    (id) => {
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')
        return axios.get(BaseUrl + `/SAV/getTicketParId?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenStored,
                'Platform': 'web',
                'timezone': timezone,

            }

        })
            .then((res) => {
                return res.data.data.result;
            }

            );
    });

export const updateTicketState = createAsyncThunk(
    'ticket/updateTicketState',
    (data) => {
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')
        return axios.put(BaseUrl + `/SAV/updateStatutTicket/v2`
            // ?id=${data.openTicket}&status=${data.status}
            , data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenStored,
                'Platform': 'web',
                'timezone': timezone,


            }
        });
    });

export const executeTicket = createAsyncThunk(
    'ticket/executeTicket',
    (data) => {
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')
        return axios.put(BaseUrl + `/SAV/executerTicket?id=${data.id}&connectionId=${data.connectionId}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenStored,
                'Platform': 'web',
                'timezone': timezone,


            }
        });
    });
export const updateTicketAssignedTo = createAsyncThunk(
    'ticket/updateTicketAssignedTo',
    (data) => {
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')
        return axios.put(BaseUrl + `/SAV/updateAssignementTicket?idTicket=${data.openTicket}&assigneeId=${data.assigneeId}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenStored,
                'Platform': 'web',
                'timezone': timezone,


            }
        });
    });
export const updateTicket = createAsyncThunk(
    'ticket/updateTicket',
    (data) => {
        const tokenStored = localStorage.getItem('TokenStored')
        const timezone = localStorage.getItem('TZ')
        return axios.put(BaseUrl + `/SAV/updateTicket?id=${data.id}`, data.modified, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenStored,
                'Platform': 'web',
                'timezone': timezone,
            }
        });
    });
//async (initialTicket, { rejectWithValue }) => {
//    const token=localStorage.getItem('token')
//    try {
//     const response = await axios.post(BaseUrl+`/addNewTicket/create/`, initialTicket, {
//       headers: {
//         'Authorization': `token ${token}`
//       }
//     })
//     return response.data;
//   } catch (err) {
//     let error = err;
//     if (!error.response) {
//       throw err;
//     }

//     return rejectWithValue(error.response);
//   }
// }
//);
const initialState = {
    loading: false,
    data: [],
    tickets: [],
    filtredTickets: [],
    ticket: null,
    error: "",
};

const ticketsSlice = createSlice({
    name: ' ticket',
    initialState
    ,

    extraReducers: (builder) => {
        builder.addCase(addNewTicket.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(addNewTicket.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        })
        builder.addCase(addNewTicket.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        })
        builder.addCase(fetchTickets.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchTickets.fulfilled, (state, action) => {
            state.loading = false;
            state.tickets = action.payload.data;
            state.error = "";
        })
        builder.addCase(fetchTickets.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        })
        builder.addCase(fetchTicketById.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchTicketById.fulfilled, (state, action) => {
            state.loading = false;

            state.ticket = action.payload;
            state.error = "";
        })
        builder.addCase(fetchTicketById.rejected, (state, action) => {
            state.loading = false;
            state.ticket = null;
            state.error = action.error.message;
        })
        builder
            .addCase('api_error', (state, action) => {



                if (action?.payload?.response?.data?.failureResponse?.codeFI?.code === 808) {
                    // Trigger refresh token logic

                    //  window.location.reload()
                    state.error = '';

                    //   dispatch(refreshToken());
                } else {
                    // Handle other API errors as needed
                    state.error = action?.payload?.message; // Example of handling other errors
                }
            });

        builder.addCase(fetchFiltredTickets.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchFiltredTickets.fulfilled, (state, action) => {
            state.loading = false;
            state.filtredTickets = action.payload.data;
            state.error = "";
        })
        builder.addCase(fetchFiltredTickets.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        })
        builder.addCase(updateTicketAssignedTo.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateTicketAssignedTo.fulfilled, (state, action) => {
            state.loading = false;

            state.error = "";
        })
        builder.addCase(updateTicketAssignedTo.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        })
        builder.addCase(executeTicket.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(executeTicket.fulfilled, (state, action) => {
            state.loading = false;

            state.error = "";
        })
        builder.addCase(executeTicket.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        })

    }
});
export default ticketsSlice.reducer;

