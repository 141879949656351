import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
/* The `resources` object is used to define the translations for different languages in the
application. It contains nested objects for each language, with the `translation` key holding the
actual translations. */
const resources = {
  en: {
    translation: {
      "SAV Dashboard": "SAV Dashboard en",
      "language": "english",
      "Changer la langue": "Change language",
      "Mes Tickets": "My Tickets",
      "Tous les Tickets": "All Tickets",
      "Ajout Tickets": "Add  Tickets",
    }
  },
  sp: {
    translation: {
      "SAV Dashboard": "react-i18next es un poderoso marco de internacionalización para React / React Native que se basa en i18next.",
      "language": "spanish",
    }
  },
 
  fr : {
    translation: {
      "SAV Dashboard": "SAV Dashboard fr",
      "language": "français",
      "Mes Tickets": "Mes Tickets",
      "Tous les Tickets": "Tous les Tickets",
      "Ajout Tickets": "Ajout Tickets",
      "Changer la langue" : "Changer la langue"
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;