import Logo from '../../assets/images/Logo.svg';

export default function NavBarLogo () {
    return (
        // <GridContainer justifyContent="flex-start"  direction="row"
        
        // alignItems="flex-start">
        // <GridItem sx={12} sm={12} md={10} xl={10}  xxl={10}>
        <img src={Logo} alt='Logo' width="40%"
          marginleft="-10px"
            />
    //    </GridItem>
    //    <GridItem>
      
    //    </GridItem>
    //     </GridContainer>
    )
};