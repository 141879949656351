
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function EditIcon () {
    return (
       
        <SvgIcon>
 <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
<g id="edit">
<path id="Icon" d="M7.83398 3.16812H3.16732C2.8137 3.16812 2.47456 3.3086 2.22451 3.55864C1.97446 3.80869 1.83398 4.14783 1.83398 4.50145V13.8348C1.83398 14.1884 1.97446 14.5275 2.22451 14.7776C2.47456 15.0276 2.8137 15.1681 3.16732 15.1681H12.5007C12.8543 15.1681 13.1934 15.0276 13.4435 14.7776C13.6935 14.5275 13.834 14.1884 13.834 13.8348V9.16812M12.834 2.16812C13.0992 1.9029 13.4589 1.75391 13.834 1.75391C14.2091 1.75391 14.5688 1.9029 14.834 2.16812C15.0992 2.43334 15.2482 2.79305 15.2482 3.16812C15.2482 3.54319 15.0992 3.9029 14.834 4.16812L8.50065 10.5015L5.83398 11.1681L6.50065 8.50145L12.834 2.16812Z" stroke="#344054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>
     </SvgIcon>
    )
}