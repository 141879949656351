import React, {  useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {  Popover, Typography} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { NavContext } from '../../App';
import { Colors } from '../Utility/Colors';
import Badge from '@mui/material/Badge';


import NotificationItemStack from "./NotificationItemStack";
import "./style.css";
import { getNewNotificationsNumber } from "../../ReduxTLK/features/Notifications/NotificationSlice";
import BellIcon from "../IconComponents/BellIcon";


export default function NotificationList(props) {
  const blue = Colors.blue750
  const dispatch = useDispatch()
  const context = React.useContext(NavContext)
  const [notificationListOpen, setNotificationListOpen] = React.useState(false)

  // const [open, setOpen] = useState(false);

  // const handleClose = () => {
  //   setOpen(false)
  // }
  const getNotification = () => {
    dispatch(getNewNotificationsNumber()).then(unwrapResult).then((res) => {
      
      context.setNewNotification(res?.data?.data?.result)
  })
  }
  useEffect(() => {
    getNotification();
  }, [context.newNotification])
  const openNotificationList = (event) => {
    //setAnchorElNotification(event.currentTarget);
    //event.stopPropagation();
    setNotificationListOpen(true);
    //getNotification();
    // setTimeout(() => {
    //     handleClickAway();
    // }, 10000)

  };
  // const handleClickAway = () => {
  //   setNotificationListOpen(false); // Close the drawer
  // };
  const handleDrawerClose = () => {
    setNotificationListOpen(false);
  };
  return (
    <>
       
    <IconButton color={blue} sx={{ paddingTop : '0px' }} onClick={openNotificationList}>
              <Badge badgeContent={context.newNotification}  color="error">
               <BellIcon /> 
              </Badge>
            </IconButton>
 <Popover
    open={notificationListOpen}
    onClose={
        (reason) => {
        if (reason === 'backdropClick') {
          // Handle backdrop click specifically
          handleDrawerClose();
        } else {
            handleDrawerClose();
          // Handle other closing reasons (e.g., escape key, close button)
        }
      }
    }
    //keepMounted={false}
   // width="50%"
    anchor="right"
   
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    PaperProps={{
      sx: {
      //  height: '545px',
        width: '370px',
      //  position: 'fixed',
        marginLeft: "-4.5%",
      //  marginRight : '500px',
        marginTop : 5,
        paddingTop : 1,
        // scrollbarColor : 'blue',
        // scrollBehavior : "smooth",
       
      // overflowY: 'auto',
      },
    }}
  >
    <div 
     style={{
        // ml : 10,
        // mr : 150 ,
        // position: "sticky",
        // top: 0,
      //  marginBottom: '-25px',
        }}>
    {/* <Card> */}
      {/* <CardContent> */}
      <div  style={{
         ml : 10,
         mr : 1.5 ,
         position: "sticky",
        top: 0,
      // marginBottom: '-25px',
        }}>
          <Typography sx={{
            color: 'var(--Text-Title, #40464A)',
            fontFamily: "DM Sans",
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '28px',
            marginLeft: '24px',
            marginTop: '24px',
            marginBottom : '24px'
          }}>Notifications</Typography>
          </div>
     
      {/* </CardContent> */}
   {/* <CardContent  */}
  
   {/* > */}

    <NotificationItemStack  newNotification={context.newNotification}/> 
    
    {/* </CardContent>
    </Card> */}
    </div>
        </Popover>
    </>
  )
}