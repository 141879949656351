

import React from 'react'

import SvgIcon from '@mui/material/SvgIcon';

export default function DownloadIcon () {
    return (
       
        <SvgIcon>

<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9.271" cy="9.5" r="8.5" stroke="#344054"/>
<path d="M13.1712 10.7163V12.1113C13.1712 12.6249 12.7548 13.0413 12.2412 13.0413H6.6612C6.14758 13.0413 5.7312 12.6249 5.7312 12.1113V10.7163" stroke="#344054" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.59131 8.85645L9.45131 10.7164L11.3113 8.85645" stroke="#344054" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.45117 10.7162V5.13623" stroke="#344054" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
 </SvgIcon>
 )
}