import React, { useContext, useState } from "react";
import "./style.css";
import { useEffect } from "react";
import { getNotifications } from "../../ReduxTLK/features/Notifications/NotificationSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import SAVIcon from "../IconComponents/SAVIcon";
import moment from "moment";
import { NavContext } from "../../App";
import { Persistor } from "../..";
import { addTab, removeTabById, setLastActiveTab } from "../../ReduxTLK/features/Tabs/TabsSlice";
import { resetEditTicketDraftByIndex } from "../../ReduxTLK/features/SAV/NewTicketDraftSlice";
import EditSAVTicket from "../../Pages/SAV/TicketDetails/Edit/EditSAVTicket";
import { Avatar, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import DotBlackIcon from "../IconComponents/DotBlackIcon";
import ViewSAVTicket from "../../Pages/SAV/TicketDetails/View/ViewSAVTicket";


export default function NotificationItemStack() {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const context = useContext(NavContext)
  const dispatch = useDispatch();
  const [notifArray, setNotifications] = useState([]);
  // const [notif_array, setNotfications] = useState([])

  const [notSeen, setNotSeen] = useState([])

  const [pageSize, setPageSize] = useState(20)


  useEffect(() => {
    const element = document.querySelector("div#scroll-box");
    if (!element) {
     
      return;
    }
    
    
    loadNotifications(pageSize);
  }, [pageSize]);

  const loadNotifications = async (pageSize) => {
    if(pageSize > 20) {
      setLoadingMore(true)
    }
    const data = {
      page: 1,
      pageSize: pageSize,
    };
    const element = document.querySelector("div#scroll-box");
    try {
      const res = await dispatch(getNotifications(data)).then(unwrapResult);
  
      setLoading(false);
      setNotSeen(res?.data?.data?.result?.notifications.slice(0,context.newNotification));
      setNotifications(res?.data?.data?.result?.notifications.slice(context.newNotification))
      // setAllNotifications((prevNotifications) => [
      //   ...prevNotifications,
      //   ...res?.data?.data?.result?.notifications,
      // ]);
      setLoadingMore(false);
      
  
      element.scrollTop = element.scrollHeight;
    } catch (error) {
     
    } finally {
     context.setNewNotification(0);
    }
  };


  const logScroll = () => {
    const element = document.querySelector("div#scroll-box");
    if (!element) {
      
      return;
    }
    const containerHeight = element.scrollHeight;
    const scrollPosition = element.scrollTop;
    const remainingSpace = containerHeight - (scrollPosition + element.clientHeight);
    const threshold = 0;
  
    if (remainingSpace <= threshold) {
      setPageSize((size) => size + 20); // Increase pageSize by 20
     // setLoading(true);
     setLoadingMore(true)
    }
  };
  useEffect(() => {
    const element = document.querySelector("div#scroll-box");
    element.addEventListener("scroll", logScroll);
    return () => {
      element.removeEventListener("scroll", logScroll);
    };
  }, []);


  const [paginationModelCustom, setPaginationModelCustom] = useState({

    pageSize: 13,
    page: 1,
    nbPage: 1
  })
  const fetchData = async (paginationModel) => {

    // dispatch(fetchTickets(paginationModel))
    //  .then(unwrapResult).then((res) => {
    // dispatch(setPaginationModelSAVTT({
    //   page: res?.data?.pagination?.currentPage,
    //   pageSize: res?.data?.pagination?.pageSize,
    //   nbPage: res?.data?.pagination?.nbPage
    // }))
    // setTicketList(res.data.result.tickets);
    // setLoading(false);
    //  Persistor.persist();
    // }
    // );



  };
  const handleStackClick = (notif) => {
    // Handle the onClick event for StackNotSeen

    const title = '#' + notif.idTicket;
    const composedId = 'SAV_Ticket_' + notif.idTicket
    // Find any existing tab with the same ID
    const existingTabIndex = context.tabs.findIndex((tab) => tab.id === composedId);
    // If a tab with the ID already exists, and active force ticket details view.
    
    if (existingTabIndex !== -1 && context.activeTab === composedId) {


      context.setTabToBeReloadedNotif(notif.idTicket)
      if (notif.statut === 6)
      {
        const updatedTabs = context.tabs.filter((tab) => {
             
          return tab.identifier !== notif.idTicket;
        });

        dispatch(removeTabById(composedId))
      
        context.setTabs([...updatedTabs, {
          id: composedId,
          label: title,
          identifier: notif.idTicket,
          component: <ViewSAVTicket id={notif.idTicket} />,
          closeable: true,
          icon: <SAVIcon />,
        }]);
        dispatch(addTab({
          module: 'SAV',
          action: 'View',
          identifier: notif.idTicket,
          id: composedId,
          label: title,
        }));
        Persistor.persist();

        context.setActiveTab(composedId);
        dispatch(setLastActiveTab(composedId));
      }
      return; // Exit the function to avoid creating a duplicate tab
    }
    // If a tab with the ID already exists, activate it
    if (existingTabIndex !== -1) {
      context.setActiveTab(composedId);
      dispatch(setLastActiveTab(composedId));
      if (notif.statut === 6)
      {
        const updatedTabs = context.tabs.filter((tab) => {
             
          return tab.identifier !== notif.idTicket;
        });

        dispatch(removeTabById(composedId))
      
        context.setTabs([...updatedTabs, {
          id: composedId,
          label: title,
          identifier: notif.idTicket,
          component: <ViewSAVTicket id={notif.idTicket} />,
          closeable: true,
          icon: <SAVIcon />,
        }]);
        dispatch(addTab({
          module: 'SAV',
          action: 'View',
          identifier: notif.idTicket,
          id: composedId,
          label: title,
        }));
        Persistor.persist();

        context.setActiveTab(composedId);
        dispatch(setLastActiveTab(composedId));
      }
      return; // Exit the function to avoid creating a duplicate tab
    }

    // If no existing tab found, create a new one
    // setIndex(composedId);
    dispatch(resetEditTicketDraftByIndex(notif.idTicket));
    if(notif.statut === 6 || notif.statut === 0) 
    {
      dispatch(addTab({
        module: 'SAV',
        action: 'View',
        identifier: notif.idTicket,
        id: composedId,
        label: title,
      }));
  
      context.setTabs([...context.tabs, {
        id: composedId,
        label: title,
        identifier: notif.idTicket,
        component: <ViewSAVTicket id={notif.idTicket} fetchData={fetchData} paginationModelCustom={paginationModelCustom} />,
        closeable: true,
        icon: <SAVIcon />,
      }]);
      Persistor.persist();
      // context.setMaxTabIndex(index + 1);
      context.setActiveTab(composedId);
      dispatch(setLastActiveTab(composedId));
    }
    else {
      dispatch(addTab({
        module: 'SAV',
        action: 'Edit',
        identifier: notif.idTicket,
        id: composedId,
        label: title,
      }));
  
      context.setTabs([...context.tabs, {
        id: composedId,
        label: title,
        identifier: notif.idTicket,
        component: <EditSAVTicket id={notif.idTicket} fetchData={fetchData} paginationModelCustom={paginationModelCustom} />,
        closeable: true,
        icon: <SAVIcon />,
      }]);
      Persistor.persist();
      // context.setMaxTabIndex(index + 1);
      context.setActiveTab(composedId);
      dispatch(setLastActiveTab(composedId));
    }
   

  };

  return (
    <>
      <div id="scroll-box" style={{ maxHeight: "545px", minHeight: "545px", overflowY: "scroll" }}>
        {loading ? (

          <center>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
          </center>
        ) : (
          <div  >
            {notSeen.map((notif, key) => (

              <StackNotSeen key={key} notif={notif} onClick={() => handleStackClick(notif)} />

            ))
            }
            {notifArray?.slice(context.newNotification).map((notif, key) => (

              <Stack key={key} notif={notif} onClick={() => handleStackClick(notif)} />

            ))
            }
            { loadingMore ? 
            <center>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
          </center> : <></> }
            {/* {notifArray.map((notif, key) => (
        <Stack key={key} notif={notif} />
      ))} */}
          </div>
          
           )} 
      </div>
    </>
  );
};
function getTimeDifference(pastTime) {
  const now = new moment();
  const left = moment(pastTime)

  const difference = now.diff(left, 'milliseconds');

  let differenceInSeconds = Math.floor(difference / 1000);
  let differenceInMinutes = Math.floor(differenceInSeconds / 60);
  let differenceInHours = Math.floor(differenceInMinutes / 60);
  let differenceInDays = Math.floor(differenceInHours / 24);

  if (differenceInSeconds < 30) {
    return `${differenceInSeconds} s`;
  } 
  else if (differenceInSeconds < 60) {
    return `${differenceInSeconds} s`;
  }
  else if (differenceInMinutes < 60) {
    return `${differenceInMinutes} m`;
  } else if (differenceInHours < 24) {
    return `${differenceInHours} h`;
  } else {
    return `${differenceInDays} j`;
  }
}

export const StackNotSeen = (props) => {
  const [textParts, setTextParts] = useState([]);
  const timeDifference = getTimeDifference(props.notif.date);
  useEffect(() => {
    const originalText = props.notif?.contenu;
    const splitText = originalText?.split(","); // Split by comma
    setTextParts(splitText);
  }, []);

  return (
    <>
      {/* <Card sx={{mb : 5}}> */}

      <div className="stack" style={{
        marginBottom: 5, marginLeft: '10px', marginRight: '5px',
        display: 'flex',
        width: '335px',
        height: '99px',
        borderRadius: '12px',
       
        flexShrink: 0,
        overflow: 'hidden',
      }}>
       <div className="frame" onClick={() => props.onClick(props.notif.idTicket)}>
          <div className="comment">
            <div className="avatar-info">
            
              <Avatar size={50}
                style={{
                  backgroundColor: '#EDEDED',
                  color: '#101928',
                  fontFamily: 'DM Sans',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  minWidth: '50px', 
                }} >SAV</Avatar>
             
              <div className="comment-info">
                <div className="name-time">
                  <div className="group">
                    <div className="user">
                      <div className="div">
                        
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
    <span style={{  }}>{props.notif?.titre}</span>
    <span style={{ marginLeft: '120px',  }}>
        {timeDifference}
    </span>
</div>
                      </div>
                    </div>
                    <p className="ID">
                      <span className="span"></span>
                    </p>
                  </div>

                </div>

                <div className="product-title">
                  <div className="product-title-2">
                    <div className="text-wrapper-3">{textParts[0]?.trim()} </div>
                  </div>
                </div>
                <div className="product-title">
                  <div className="product-title-2">
                    <div className="text-wrapper-3"><DotBlackIcon />
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.notif.chaine}</span> <DotBlackIcon />
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {props.notif.pv?.slice(0, 14)}  {props.notif.pv?.length > 14 ? '...' : ''}</span></div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    
      {/* </Card> */ }

    </>
  );
}
export const Stack = (props) => {

  const [textParts, setTextParts] = useState([]);
  const timeDifference = getTimeDifference(props.notif.date);
 
  useEffect(() => {
    const originalText = props.notif?.contenu;
    const splitText = originalText?.split(","); // Split by comma
    setTextParts(splitText);
  }, []);

  return (
    <>

      {/* <Card> */}
      <div className="stack_seen" style={{
        marginBottom: 5, marginLeft: '10px', marginRight: '5px', display: 'flex',
        width: '335px',
        height: '99px',
        borderRadius: '12px',
        justifyContent: 'space-between',
        overflow: 'hidden',
        //flexShrink: 0
      }}>
        <div className="frame" onClick={() => props.onClick(props.notif.idTicket)}>
          <div className="comment">
            <div className="avatar-info">
            
              <Avatar size={50}
                style={{
                  backgroundColor: '#EDEDED',
                  color: '#101928',
                  fontFamily: 'DM Sans',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  minWidth: '50px', 
                }} >SAV</Avatar>
             
              <div className="comment-info">
                <div className="name-time">
                  <div className="group">
                    <div className="user">
                      <div className="div">
                        
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
    <span style={{  }}>{props.notif?.titre}</span>
    <span style={{ marginLeft: '120px',  }}>
        {timeDifference}
    </span>
</div>
                      </div>
                    </div>
                    <p className="ID">
                      <span className="span"></span>
                    </p>
                  </div>

                </div>

                <div className="product-title">
                  <div className="product-title-2">
                    <div className="text-wrapper-3">{textParts[0]?.trim()} </div>
                  </div>
                </div>
                <div className="product-title">
                  <div className="product-title-2">
                    <div className="text-wrapper-3"><DotBlackIcon />
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.notif.chaine}</span> <DotBlackIcon />
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {props.notif.pv?.slice(0, 14)}  {props.notif.pv?.length > 14 ? '...' : ''}</span></div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>

      {/* </Card> */}


    </>
  );
};
