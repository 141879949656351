
import React, { useEffect,  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from 'antd';
import {  getAttachementById, getAttachementList } from "../../../../ReduxTLK/features/Attachement/AttachementSlice";
import GridContainer from "../../../../GlobalComponents/Grid/GridContainer";
import GridItem from "../../../../GlobalComponents/Grid/GridItem";
import { Button, Dialog, DialogContent, IconButton, Pagination, Typography, CircularProgress  } from "@mui/material";
import H5 from "../../../../GlobalComponents/TypoGraphy/H5";
import { unwrapResult } from "@reduxjs/toolkit";
import DownloadIcon from "../../../../GlobalComponents/IconComponents/DownloadIcon";
import moment from "moment/moment";
import fileSize from "file-size";
import { DataGrid, GridToolbar, gridClasses  } from "@mui/x-data-grid";
import DataGridPaginationTitle from "../../../../GlobalComponents/Grid/DataGridPaginationTitle";
import ChevronLeftIcon from "../../../../GlobalComponents/IconComponents/ChevronLeftIcon";
import ChevronRightIcon from "../../../../GlobalComponents/IconComponents/ChevronRightIcon";
import { LoadingOutlined } from '@ant-design/icons';

import { setPaginationModelSAVTA } from "../../../../ReduxTLK/features/Pagination/PaginationsSlice";
import { Persistor } from "../../../../";
import TrashIcon from "../../../../GlobalComponents/IconComponents/TrashIcon";

import { Colors } from "../../../../GlobalComponents/Utility/Colors";




export default function AttachmentListView(props) {

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [itemToDelete, setItemToDelete] = useState()
  
  
  const paginationModelSAVTA = useSelector((state) => state.pagination.paginationModelSAVTA);
  const [paginationModelCustom, setPaginationModelCustom] = useState({pageSize: 7,
    page: 1,
    nbPage : 0,
    idTicket: props.idTicket,
    view : true,
  })

  const [page, setPage] = useState();
  const columns = [
    {
      field: 'nom',
      headerName: 'Nom  ',
      // minWidth: 111 ,
      flex: 0.25,
      align: 'left',
      renderCell: ((params) => {
        if(params.row.type === 'application/pdf'){
        return (<>
          
<Button onClick={()=> PreviewPdf(params.row.id)}>
          <Typography sx={{
            color: 'var(--Grey-900, #101928)',
            fontFamily: "DM Sans",
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '120%', /* 16.8px */
            letterSpacing: '0.07px',
          }}

          >
            {params?.value.substring(0, params?.value?.indexOf("."))}
          </Typography>
          </Button>
          {/* <PreviewPDFComponent 
  attachement={params.row.id}
   openPreview={openPreview}
    setOpenPreview={setOpenPreview} 
    /> */}
        </>)
        }
        if (params.row.type.substring(0, params.row.type.indexOf("/")) === 'audio')
        {
         
          return(<>
           <Button onClick={() => PreviewAudio(params.row.id)}>
                      <Typography sx={{
                        color: 'var(--Grey-900, #101928)',
                        fontFamily: "DM Sans",
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: '120%', /* 16.8px */
                        letterSpacing: '0.07px',
                      }}
            
                      >
                        {params?.value.substring(0, params?.value?.indexOf("."))}
                      </Typography>
                      </Button>
          </>)
        }
        if (params.row.type.substring(0, params.row.type.indexOf("/")) === 'image')
        {
          
          return (<>
          
            <Button onClick={() => PreviewImage(params.row.id)}>
                      <Typography sx={{
                        color: 'var(--Grey-900, #101928)',
                        fontFamily: "DM Sans",
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: '120%', /* 16.8px */
                        letterSpacing: '0.07px',
                      }}
            
                      >
                        {params?.value.substring(0, params?.value?.indexOf("."))}
                      </Typography>
                      </Button>
                    
                    </>)
        }
      })
    },
    {
      field: 'type',
      headerName: 'Type',
      // minWidth: 250,
      // editable: true,
      flex: 0.1,

      renderCell: (params) => {
        // const agentSAV = agentsSAV.find(item => item.id === params.row.assigne);


        return (
          <H5 title={params?.value.substring(params?.value?.indexOf("/") + 1, params?.value?.length)}></H5>

        )
      },
    },

    {
      field: 'date',
      headerName: 'Date',
      //type: 'number',
      // minWidth: 150,
      // editable: true,

      flex: 0.1,
      renderCell: ((params) => {
        const date_creation = moment(params?.value).format("DD-MM-YYYY");

        return (<>

          <H5 title={date_creation} />
        </>)
      })
    },
    {
      field: 'taille',
      headerName: 'Taille ',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      // minWidth: 168,
      flex: 0.1,

      renderCell: (params) => {
        // const agentSAV = agentsSAV.find(item => item.id === params.row.assigne);

        return (<>
          {/* {agentSAV ?  */}
          <H5 title={fileSize(params?.value).human('jedec')} />

          {/* : ''} */}
        </>)

      }

    },

    {
      field: 'action',
      align: 'center',
      headerName: '',
      editable: true,
      flex: 0.05,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const obj = params.row;

        //OUVERT
        return (
          <>
            <GridContainer
              // display='flex'
              justifyContent='center'
              alignItems='center'
              direction='row'

            >
              {/* <GridItem  sm={4} md={4} xl={4} xxl={4}> </GridItem> */}
              <GridItem sm={3} md={3} xl={3} xxl={3}>
             


              </GridItem>
              <GridItem sm={3} md={3} xl={3} xxl={3}>
            

              </GridItem>
              {/* <GridItem sx={8} sm={8} md={8} xl={8} xxl={8}>
                 
                </GridItem>  */}
            </GridContainer>
          </>)
      }



    },
  ];
  // useEffect(() => {
  //   if (paginationModelSAVTA) {
  //     setPaginationModelCustom(paginationModelSAVTA);
  //   }
  // }, []);
  const fetchData = async (paginationModelSAVTA) => {

    dispatch(getAttachementList(paginationModelSAVTA))
      .then(unwrapResult).then((res) => {
        props.setAttachmentList(res?.data?.data?.result)
        setPaginationModelCustom({
          pageSize: res?.data?.data?.pagination?.pageSize,
          page: res?.data?.data?.pagination?.currentPage,
          nbPage: res?.data?.data?.pagination?.nbPage,
          idTicket: props.idTicket,
          view : true,
        })
        dispatch(setPaginationModelSAVTA({
          page: res?.data?.pagination?.currentPage,
          pageSize: res?.data?.pagination?.pageSize,
          nbPage: res?.data?.pagination?.nbPage,
          idTicket: props.idTicket,
          view : true,
        }))
        Persistor.persist();
      }
      );



  };

  useEffect(() => {
    setIsLoading(true);
    
    dispatch(getAttachementList(paginationModelCustom)).then((res) => {
      props.setAttachmentList(res?.payload?.data?.data?.result)
      setPaginationModelCustom({
        pageSize: res?.payload?.data?.data?.pagination?.pageSize,
        page: res?.payload?.data?.data?.pagination?.currentPage,
        nbPage: res?.payload?.data?.data?.pagination?.nbPage,
        idTicket: props.idTicket,
        view : true,
      })
      dispatch(setPaginationModelSAVTA({
        page: res?.data?.pagination?.currentPage,
        pageSize: res?.data?.pagination?.pageSize,
        nbPage: res?.data?.pagination?.nbPage,
        idTicket: props.idTicket,
        view : true,
      }))
      setIsLoading(false);
      Persistor.persist();
   
   
    })


  }, [])
  const handleDownload = (obj) => {
    dispatch(getAttachementById(obj.id)).then((res) => {
      var a = document.createElement("a");
      a.href = "data:image/pdf;base64," + res?.payload?.data?.data?.result.contenu;
      a.download = res?.payload?.data?.data?.result?.nom;
      a.click();

    })

  };
  const handleDeleteModal = (obj) => {
  
   setOpenDeleteModal(true)
   setItemToDelete(obj.id);
  
   
 
 
  

  };
  const [openPreview, setOpenPreview] = useState(false)
  const [attachment, setAttachmentToPreview] = useState()
  const [openPreviewImage, setOpenPreviewImage] = useState(false)
  const [openPreviewAudio, setOpenPreviewAudio] = useState(false)
 
  const [rowSelectionModel, setRowSelectionModel] = React.useState();
  const handleRowClick = (value) => {
    setRowSelectionModel(value[0])
}
useEffect(() => {
  if(rowSelectionModel ==! null && rowSelectionModel ==! undefined)
 { 
  setAttachmentToPreview(rowSelectionModel)
 
}

}, [rowSelectionModel])
 const PreviewPdf = (value) => {
  setAttachmentToPreview(value)
  setOpenPreview(true)
 }
 const PreviewImage = (value) => {
  setAttachmentToPreview(value)
  setOpenPreviewImage(true)
 }
 const PreviewAudio = (value) => {
  setAttachmentToPreview(value)
  setOpenPreviewAudio(true)
 }

 const handlePageChangeNext = (event) => {
  
   const data = {
     page: paginationModelCustom.page + 1,
     pageSize: paginationModelCustom.pageSize,
     nbPage: paginationModelCustom.nbPage,
     idTicket : paginationModelCustom.idTicket,
     view : true,

   };
   fetchData(data);

 }

 const handlePageChangePrevious = (event) => {
  
   const data = {
     page: paginationModelCustom.page - 1,
     pageSize: paginationModelCustom.pageSize,
     nbPage: paginationModelCustom.nbPage,
     idTicket : paginationModelCustom.idTicket,
     view : true,
   };
   fetchData(data);
 }
 const handleChangePage = (event) => {
  const data = {
    page: parseInt(event.target.textContent),
    pageSize: paginationModelCustom.pageSize,
    nbPage: paginationModelCustom.nbPage,
    idTicket : paginationModelCustom.idTicket,
    view : true,

  };
  fetchData(data);
 }
 function CustomFooterComponent(props) {

  return (
    <GridContainer
      display='flex-end'
      justifyContent='flex-end'
      alignItems='end'
      direction='row'
      position='absolute'
      overflow='scroll'
    >

       { paginationModelCustom && paginationModelCustom?.nbPage >= 1 ?
       <>
      <GridItem sx={0} sm={6} md={6} lg={6} xl={8} xxl={8}>
        <DataGridPaginationTitle count={paginationModelCustom?.nbPage} page={paginationModelCustom?.page} />
      </GridItem>
      <GridItem sx={12} sm={6} md={6} lg={6} xl={0.5} xxl={0.5}>
        <Button disabled={paginationModelCustom?.page === 1} onClick={() => handlePageChangePrevious()} >
          <IconButton sx={{color : Colors.primary}}>
            <ChevronLeftIcon ></ChevronLeftIcon>
          </IconButton>
        </Button>
      </GridItem>
      <GridItem sx={0} sm={6} md={6} lg={6} xl={3} xxl={3}>
      <Pagination color="primary"
          shape='rounded' page={paginationModelCustom?.page} count={paginationModelCustom?.nbPage} sx={{
            height: '38px',
          }}
          hidePrevButton
          hideNextButton 
          onChange={handleChangePage}
          /> 

       
      </GridItem>
      <GridItem sm={6} md={6} lg={6} xl={0.35} xxl={0.35} sx={{
        //paddingTop : '5px',
        position: 'absolute',
        //  bottom : 0
      }}>

        <Button disabled={paginationModelCustom?.page === paginationModelCustom?.nbPage} onClick={() => handlePageChangeNext()} >

          <IconButton disabled={paginationModelCustom?.page === paginationModelCustom?.nbPage} sx={{color : Colors.primary}}>
            <ChevronRightIcon ></ChevronRightIcon>

          </IconButton>

        </Button>

      </GridItem>
      </>
      : <></>  } 
       {paginationModelCustom && paginationModelCustom.nbPage === 0 ? <>
 <GridItem sx={0} sm={6} md={6} lg={10} xl={11} xxl={11}>
        <DataGridPaginationTitle count={paginationModelCustom?.nbPage} page={0} />
      </GridItem>
     
     
          <IconButton   sx={{color : Colors.primary}}>
            <ChevronLeftIcon ></ChevronLeftIcon>
          </IconButton>
          <div style={{shape : 'rounded', width : '25px',
           height : '25px', backgroundColor : '#EEEEEE', 
           color :'black',
           borderRadius : '4px',
           marginBottom : '9px', display :'flex', 
           alignContent : 'center', alignItems : 'center'}}>
           <span style={{marginLeft : '8px', fontStyle:'DM Sans', fontWeight : 500, fontSize : '12px'}}>0</span> 
            </div>
          <IconButton  sx={{color : Colors.primary}}>
            <ChevronRightIcon ></ChevronRightIcon>

          </IconButton>
    
      </> : <></>
}
    </GridContainer>
  );
}

const [unseenAttachmentIds, setUnseenAttachmentIds] = useState([])
useEffect(() => {
  setIsLoading(true);
    
  dispatch(getAttachementList(paginationModelCustom)).then((res) => {
    props.setAttachmentList(res?.payload?.data?.data?.result)
    for (let i = 0; i < props.nbreAttachementsNonLus && i < res?.payload?.data?.data?.result?.length; i++) {
      
      unseenAttachmentIds.push(res?.payload?.data?.data?.result[i].id);
    }
    setUnseenAttachmentIds(unseenAttachmentIds)
    setPaginationModelCustom({
      pageSize: res?.payload?.data?.data?.pagination?.pageSize,
      page: res?.payload?.data?.data?.pagination?.currentPage,
      nbPage: res?.payload?.data?.data?.pagination?.nbPage,
      idTicket: props.idTicket,
      view : true,
        })
    dispatch(setPaginationModelSAVTA({
      page: res?.data?.pagination?.currentPage,
      pageSize: res?.data?.pagination?.pageSize,
      nbPage: res?.data?.pagination?.nbPage,
      idTicket: props.idTicket,
      view : true,
    }))
    setIsLoading(false);
    Persistor.persist();
 
 
  })
  
}, [props.nbreAttachementsNonLus])


  return (
    <>

    <div style={{ minHeight: '2px', height: '2px', backgroundColor: '#F4F7FE', width: '110%', zIndex : 2 , position: 'relative',}}>
    </div>
    <div style={{ minHeight: '580px', height: '580px',
    maxHeight : '580px',
      backgroundColor: 'white', width: '109%',  marginLeft: '-10px' }}>
     
      <DataGrid
        rows={props.attachmentList ? props.attachmentList : []}
        columns={columns}
        page={paginationModelCustom?.page}
        setPage={setPage}
        fetchData={fetchData}
        disableColumnSelector={true}
        pageSize={paginationModelCustom?.pageSize}
        loading={isLoading}
        onRowSelectionModelChange={handleRowClick}
        rowSelectionModel={rowSelectionModel}
        getRowId={(row) => row.id}
        editMode={false}
        getRowClassName={(params) => {
          const isUnseen = unseenAttachmentIds.includes(params.id);
          return isUnseen ? 'even' : 'odd';
        }}
        slots={{
          pagination: false,
          footer: CustomFooterComponent,
        }}
        slotProps={{
          footer: { props },
        }}
        components={{
          Toolbar: GridToolbar,
        }}
        sx={{
          paddingTop: '0px',
          paddingRight: '0px',
          paddingLeft: '0px',
          minHeight: '500px',
          marginTop: '00px',
          backgroundColor: 'white',
          height: '570px',
          display: 'flex',
          width: '100%',
          [`& .${gridClasses.row}.even`]: {
            backgroundColor: 'white',
            '&:hover, &.Mui-hovered': {
              backgroundColor: 'white',
              '@media (hover: none)': {
                backgroundColor: 'transparent',
              },
            },
          },
          [`& .${gridClasses.row}.odd`]: {
            backgroundColor: Colors.white,
            '&:hover, &.Mui-hovered': {
              backgroundColor: Colors.white,
              '@media (hover: none)': {
                backgroundColor: 'transparent',
              },
            },
          },
        }}
        localeText={{
          noRowsLabel: isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Typography variant="body1">Aucun attachement</Typography>
          ),
        }}
        
      >
         {isLoading && (
      <CircularProgress />
  )}
        </DataGrid>
    {attachment ? <PreviewPDFComponent id={attachment} openPreview={openPreview} setOpenPreview={setOpenPreview}/> : <></>}  
    {attachment ?  <PreviewImageComponent id={attachment} openPreviewImage={openPreviewImage} setOpenPreviewImage={setOpenPreviewImage}/>: <></>} 
     {attachment ?  <AudioPlayerComponent id={attachment} openPreviewAudio={openPreviewAudio} setOpenPreviewAudio={setOpenPreviewAudio}/>: <></>} 
    </div>
    </>
    

  )
}
const PreviewImageComponent = ({ id, openPreviewImage, setOpenPreviewImage }) => {
  const [img, setImg] = useState(null);
  const [isLoading, setIsLoading] = useState(true); 
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenPreviewImage(false);
  };

  useEffect(() => {
    setIsLoading(true)
    dispatch(getAttachementById(id))
      .then(unwrapResult)
      .then((res) => {
        setImg(res?.data?.data?.result?.contenu);
      })
      .finally(() => setIsLoading(false)); 
  }, [id]);

  return (
    <div style={{ minWidth: '800px', minHeight: '1000px' }}>
    <Dialog open={openPreviewImage} onClose={handleClose}
    
      maxWidth="sm" 
      fullWidth
      PaperProps={{
       style: {
         minHeight: '900px', 
         minWidth: '800px',
       },
     }}
    >
      <DialogContent>
        {isLoading ? (
        <LoaderComponent />
        ) : img ? (
          <img src={`data:image/jpeg;base64,${img}`} width= '700px' height= '820px' />
        ) : (
          <p>Un problème est survenu lors du téléchargement.</p>
        )}
      </DialogContent>
    </Dialog>
    </div>
  );
};

const PreviewPDFComponent = ({ id, openPreview, setOpenPreview }) => {
 
  const [pdf, setPDF] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenPreview(false);
  };

  useEffect(() => {
    setIsLoading(true)
    dispatch(getAttachementById(id))
      .then((res) => {
        const indexDot = res?.payload?.data?.data?.result.nom.indexOf(".") + 1;
        const length = res?.payload?.data?.data?.result?.length;
        const type = res?.payload?.data?.data?.result.nom.substring(indexDot, length);
        setPDF(res?.payload?.data?.data?.result?.contenu);
      })
      .finally(() => setIsLoading(false)); // Set loading to false after completion
  }, [id]);

  return (
    <>
    <div style={{ minWidth: '750px', minHeight: '1000px' , display : 'flex', alignContent : 'center'}}>
      <Dialog open={openPreview} onClose={handleClose}
       maxWidth="sm" 
       fullWidth
       PaperProps={{
        style: {
          minHeight: '850px', 
          minWidth: '750px',
        },
      }}
      >
        <DialogContent>
          {isLoading ? (
           <LoaderComponent />
          ) : pdf ? (
            <object data={`data:application/pdf;base64,${pdf}`} type="application/pdf"  width= '700px' height= '820px'/>
          ) : (
            <p>Error loading PDF.</p>
          )}
        </DialogContent>
      </Dialog>
      </div>
    </>
  );
};

const AudioPlayerComponent = ({ id, openPreviewAudio, setOpenPreviewAudio }) => {
  
  
  const [audio, setAudio] = useState(null);
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenPreviewAudio(false);
  };

  useEffect(() => {
    setIsLoading(true)
    dispatch(getAttachementById(id))
      .then(unwrapResult)
      .then((res) => {
        setAudio(res?.data?.data?.result?.contenu);
        setType(res?.data?.data?.result?.type);
      })
      .finally(() => setIsLoading(false)); // Set loading to false after completion
  }, [id]);

  return (
    <>
      <Dialog open={openPreviewAudio} onClose={handleClose}
       maxWidth="sm" 
       fullWidth
      >
        <DialogContent>
          <div>
            {isLoading ? (
              <LoaderComponent />
            ) : audio ? (
              <audio controls>
                <source src={`data:audio/ogg;base64,${audio}`} type={type} />
                Votre navigateur ne supporte pas l'élément audio.
              </audio>
            ) : (
              <p>Un problème est survenu lors du téléchargement du l'audio</p>
            )}
          </div>
        </DialogContent>
      </Dialog>

    </>
  );
};
const LoaderComponent = () => {
  return (
    <><center>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
    </center>
   
    </>)
}