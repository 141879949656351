
import React  , {useState} from "react";
import { Typography } from '@mui/material';
/**
 * The SmallText component is a React component that renders a Typography element with customizable
 * font style, color, and title.
 * @returns a Typography component with the following props and styles:
 */
export default function SmallText (props){
    const [fontStyle, setFontStyle] = useState(props.fontStyle ? props.fontStyle : "Regular");
    const [color, setColor] = useState(props.color ? props.color : "balck");
    return (
        <Typography sx={{
            color: {color},
            textAlign: "left",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "DM Sans",
            fontSize: "12px",
            fontStyle: {fontStyle},
            fontWeight: "900",
            lineHeight: "10px", 
            weight : "24px",
            height : "20px"
        }} >
            {props.title}
            </Typography>
    )
}