// signalRService.js
import { HubConnectionBuilder } from '@microsoft/signalr';
import { random } from 'lodash';
import BaseUrl from '../../assets/utils/accessToken'
const hubUrl = BaseUrl + "/chatHub"
const userId = localStorage.getItem('userId');
const connectionId = localStorage.getItem('connectionId');

const signalRService = {
  connection: null,
  
  startConnection: (hubUrl) => {
    signalRService.connection = new HubConnectionBuilder()
      .withUrl(hubUrl)
      .withAutomaticReconnect()
      .build();

    signalRService.connection.start().then(() => {
      localStorage.setItem('connectionId', signalRService?.connection?.connectionId)
    
    }).catch((err) => {
      
    });
  },

 

  onLeaveRoom : (id) => {
    const room = '' + id;
    signalRService.connection.invoke('leaveRoom', room)
            .then(() => {
         
            })
         
  },
  onJoinRoom: (id) => {
    
    const retryJoin = () => {
     
      switch (signalRService?.connection?._connectionState) {
        case 'Connected':
          // Join room immediately
          const room = '' + id;
          signalRService.connection.invoke('joinRoom', room)
            .then(() => {
            
            })
            .catch((err) => {
             
              setTimeout(retryJoin, 2000); 
            });
          break;
  
        case 'Connecting':
           // Wait and retry
           setTimeout(retryJoin, 2000); 
           break;
        case 'Reconnecting':
          // Wait and retry
          setTimeout(retryJoin, 2000); 
          break;
  
        case 'Disconnected':
          setTimeout(retryJoin, 2000); 
          break;
        case 'Disconnecting':
          setTimeout(retryJoin, 2000); 
          break;
        case 'Failed':
         
          signalRService.startConnection(hubUrl) 
            .then(retryJoin)
            .catch((err) => {
           
            });
          break;
  
        default:
         
          
      }
    };
  
    retryJoin(); // Attempt joining initially
  },
  onReceiveMessage: (callback) => {
   
    signalRService?.connection?.on('RecieveMessage', (message) => {
     
      callback(message);

    });
  },

  onReceiveNotification: (callback) => {
   
    signalRService?.connection?.on('ReceiveNotification', (notif) => {
     
      callback(notif);

    });
  },
  onConnectUser : () => {
    
    
    const retryConnect = () => {
     
      switch (signalRService?.connection?._connectionState) {
        case 'Connected':
          // connect user immediately
          const object = {
            userId: userId,
            connectionId: signalRService?.connection?.connectionId,
            isMobile : false
          }
          signalRService.connection.invoke('connectUser', object)
            .then(() => {
           
            })
            .catch((err) => {
             
              setTimeout(retryConnect, 2000); 
            });
          break;
  
        case 'Connecting':
           // Wait and retry
           setTimeout(retryConnect, 2000); 
           break;
        case 'Reconnecting':
          // Wait and retry
          setTimeout(retryConnect, 2000); 
          break;
  
        case 'Disconnected':
          setTimeout(retryConnect, 2000); 
          break;
        case 'Disconnecting':
          setTimeout(retryConnect, 2000); 
          break;
      
  
        default:
        
        
          
      }
    };
  
    retryConnect();
  },
  onDisconnectUser: () => {
    signalRService?.connection?.invoke('disconnectUser',  userId, false) 
      
      
  },

};

export default signalRService;