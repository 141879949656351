import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

/**
 * A custom Alert component that extends the MuiAlert component and adds an elevation of 6 and a filled variant.
 *
 * @param {object} props - The props passed to the Alert component.
 * @param {ref} ref - A ref to the Alert component.
 * @returns {React.Component} The CustomAlert component.
 */
const Alert = React.forwardRef(function Alert(props, ref) {
  const severity = props.severity
  const getBackgroundColor = (severity) => {
    switch (severity) {
      case 'info' :
        return 'linear-gradient(to right , #2D82B2, #329ABB)';
      case 'error':
        return 'linear-gradient(to right , #F6743E, #D42525)';
      case 'success':
        return 'linear-gradient(to right , #32BB71, #2A9D8F)';
      case 'alert':
        return 'linear-gradient(to right , #F6743E, #FFD02B)';
      default:
        return 'linear-gradient(to right , #2D82B2, #329ABB)';
    }
  };

  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} 
  sx={{
   background: getBackgroundColor(severity),
  }}
  />;
});

/**
 * A reusable snackbar component that displays a message with a customizable severity level.
 *
 * @param {string} severity - The severity level of the snackbar message.
 * @param {string} message - The message to display in the snackbar.
 * @returns {React.Component} The Snackbars component.
 */


export default function Snackbars(props) {

  const [title, setTitle] = useState('');
 
  const handleClose = (event, reason) => {
    setSnackbarCount((prevCount) => prevCount - 1);
    if (reason === 'clickaway') {
      return;
    }

   props.setOpen(false);
  };

  useEffect(() => {
    handleSnackbarOpen();
    switch (props.severity) {
      
      case 'error':
        setTitle('Erreur');
        
        break;
      case 'alert':
        setTitle('Alerte');
        
        break;
      case 'success':
        setTitle('Succes');
        break;
      case 'info':
        setTitle('Info');
       
        break;
      default:
        return setTitle('Erreur');
    }

  }, [props.open]);
  const [snackbarCount, setSnackbarCount] = useState(0);

  const handleSnackbarOpen = () => {
    setSnackbarCount((prevCount) => prevCount + 1);
  };
  return (
    <div>
       {/* <Button variant="outlined" onClick={handleClick}>
        Open {title} snackbar
      </Button>  */}
        {[...Array(snackbarCount)].map((_, index) => (
      <Snackbar sx={{
            zIndex: 1000,
          //  bottom: index + 16,
      }}
      key={index}
      open={props.open} 
      autoHideDuration={props.autoHideDuration? props.autoHideDuration :1000}
       onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert  onClose={handleClose} severity={props.severity? props.severity : 'error'} sx={{ width: '100%', minWidth: '320px', maxWidth : '320px'}}>
          <AlertTitle>{title} </AlertTitle>
          {props.header}
          <br></br>
          {props.message}
        </Alert>
      </Snackbar>
       ))}
    </div>
  );
}
