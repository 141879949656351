import { Box, CardContent } from '@mui/material';
import React from 'react';
import H5 from '../TypoGraphy/H5';
import {Card} from '@mui/material';
/**
 * The TitleCard component is a React component that renders a card with a title.
 * @returns a JSX element. It is a TitleCard component that renders a Box component, which contains a
 * Card component. Inside the Card component, there is a CardContent component that contains an H5
 * component. The H5 component has a title prop that is passed from the props of the TitleCard
 * component.
 */
export default function TitleCard(props) {
    return (
        <Box >
            <Card>
                <CardContent>
                    <H5 title={props.title}></H5>
                </CardContent>
            </Card>
        </Box>
    )
};