import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

const initialState = {
    loading : false,
    notifications : [],
    notificationsNonLus : [],
    //message : null,
    error: "",
};
export const getNotifications = createAsyncThunk('notification/getNotifications', 
(data) => {
    const storedToken = localStorage.getItem('TokenStored');
    const timezone = localStorage.getItem('TZ')
    return axios.get(BaseUrl +
         `/SAV/getNotifications?page=${data.page}&pageSize=${data.pageSize}`, 
    {headers: {
        'Content-Type': 'application/json',
        'Authorization': storedToken,
        'Platform': 'web',
        'timezone' : timezone,
      }});
});
export const getNewNotificationsNumber = createAsyncThunk('notification/getNewNotificationsNumber', 
(data) => {
    const storedToken = localStorage.getItem('TokenStored');
    const timezone = localStorage.getItem('TZ')
    return axios.get(BaseUrl +
         `/SAV/getNbrNotificationsNonLus`, 
    {headers: {
        'Content-Type': 'application/json',
        'Authorization': storedToken,
        'Platform': 'web',
        'timezone' : timezone,
      }});
});
const notificationsSlice = createSlice({
    name: 'notification',
    initialState,
    extraReducers: (builder) => {
        
        builder.addCase(getNotifications.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            state.loading = false;
            state.notifications = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(getNotifications.rejected, (state, action) => {
            state.loading = false;
            state.messages = null;
            state.error = action.error.message;
        }) 
        
        builder.addCase(getNewNotificationsNumber.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getNewNotificationsNumber.fulfilled, (state, action) => {
            state.loading = false;
            state.notificationsNonLus = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(getNewNotificationsNumber.rejected, (state, action) => {
            state.loading = false;
            state.messages = null;
            state.error = action.error.message;
        }) 
}
});
export default notificationsSlice.reducer;