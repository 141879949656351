import { createSlice } from '@reduxjs/toolkit';


const initialState = {
   
    newTicketDraft : null,
    dirty : false,
    dirtyEdit : false,
    editedTicketDraft : []
};

export const NewTicketDraftSlice = createSlice({
  name: 'NewTicketDraft',
  initialState ,
  reducers: {
    setNewTicketDraft: (state, action) => { 
        
        state.newTicketDraft = action.payload;
        state.dirty = true;
    },
    setEditTicketDraft: (state, action) => {
     // const { id , updatedItem } = action.payload; // Assuming action.payload has an 'id' property
      //state.editedTicketDraft = action.payload;
     
      return {
        ...state,
        editedTicketDraft: action.payload,
        dirtyEdit: true,
    };
    },
  getEditedTicketDraft: (state, action) => {
     // Assuming action.payload has an 'id' property
  
    // Find the edited ticket draft with the matching ID
    const editedTicket = state.editedTicketDraft.findIndex((item, index) => item.id === action.payload);
    
    return {
      ...state,
      // You can store the found edited ticket in a property for further use
      selectedEditedTicket: editedTicket,
    };
  },
  
    getNewTicketDraft: (state, action) => {
        // This function doesn't modify the state, just returns the newTicketDraft value
        return state.newTicketDraft;
      },
    resetNewTicketDraft: (state, action) => {
        state.newTicketDraft = null;
        state.dirty = false;


    },
    resetEditTicketDraftByIndex: (state, action) => {
      
   
      state.editedTicketDraft = state.editedTicketDraft.filter((item) => item.id !== action.payload);
      
  },
  resetAllEditDraftTicket : (state, action) => {
      
    state.editedTicketDraft = [];
    
}
  },
});

export const { setNewTicketDraft, resetNewTicketDraft, getNewTicketDraft , setEditTicketDraft,resetAllEditDraftTicket,  getEditedTicketDraft, resetEditTicketDraftByIndex} = NewTicketDraftSlice.actions;
export default NewTicketDraftSlice.reducer;