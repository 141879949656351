import React, { useState, useEffect } from "react";
import H4 from "../../../../GlobalComponents/TypoGraphy/H4";
import { Colors } from "../../../../GlobalComponents/Utility/Colors";
import GridItem from "../../../../GlobalComponents/Grid/GridItem";
import GridContainer from "../../../../GlobalComponents/Grid/GridContainer";
import H5 from '../../../../GlobalComponents/TypoGraphy/H5';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, InputNumber } from 'antd';
import {
    fetchTicketById,
    updateTicket
} from "../../../../ReduxTLK/features/SAV/TicketSlice";
import { Button, CircularProgress, MenuItem } from "@mui/material";

import { array_machine, array_typeticket, array_impact, array_priorite } from "../../../../ReduxTLK/features/SAV/ticketList";
import Snackbars from "../../../../GlobalComponents/Alerts/Snackbars";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchUsersAgentSAV } from "../../../../ReduxTLK/features/User/UserSlice";
import { fetchChaines } from "../../../../ReduxTLK/features/Chaines/ChaineSlice";
import { fetchPventes } from "../../../../ReduxTLK/features/Pointdeventes/PointdeventeSlice";

import { resetEditTicketDraftByIndex, setEditTicketDraft, } from "../../../../ReduxTLK/features/SAV/NewTicketDraftSlice";

import AddNotesInternes from "../../NotesInternes/AddNotesInternes";
import NotesList from "../../NotesInternes/NotesList";
import { Persistor } from "../../../..";



const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 22,
    },
};


export default function TicketDetails({ ticket, setTicket, onCheckRequiredFields, formRef, ...props }) {

    const [loading, setLoading] = useState(false);
    const [openErrorBox, setOpenErrorBox] = useState(false);
    const [dirty, setDirty] = useState(false)
    const [onePV, setOnePV] = useState(false)

    const { TextArea } = Input;
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
   
    // const id  = context.selectedTicket
    const dispatch = useDispatch();

    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("")


    useEffect(() => {
        dispatch(fetchUsersAgentSAV());
        dispatch(fetchChaines());
    }, []);
    const symbol = '#';
    const [pageTitle, setPageTitle] = useState()
    const [etablissement, setEtablissement] = useState()
    const [pointDeVente, setPointDeVente] = useState()

    useEffect(() => {

        const dirtyEditIndex = editedTicketDraft.findIndex((item) => item.id === ticket?.id);
     
        if (dirtyEditIndex !== -1) {

            setDirty(true)
            form.setFieldsValue(editedTicketDraft[dirtyEditIndex]?.updatedItem)
        }


        else {
            setLoading(true)
            dispatch(fetchTicketById(ticket?.id))
                .then(unwrapResult)
                .then((res) => {

                    const getintervenant = res?.intervenantFirstname + ' ' + res?.intervenantLastname

                    form.setFieldsValue({
                        etablissement: res?.idChaine,
                        pointDeVente: res?.idPv,
                        machine: res?.machine,
                        type: res?.type,
                        sujet: res?.sujet,
                        priorite: res?.priorite,
                        intervenant: getintervenant,
                        impact: res?.impact,
                        idAssignee: res?.idAssigne,
                        telephone: res?.intervenantPhone,
                        description: res?.description

                    })


                }).then(() => { setLoading(false) })

        }
    }, [ticket])
    /***********persisting edited form  */
    const editedTicketDraft = useSelector((state) => state.draftNewTicket.editedTicketDraft);

    const fetchData = async () => {

        dispatch(fetchTicketById(ticket?.id))
            .then(unwrapResult)
            .then((res) => {

                setTicket(res);
                setPageTitle(`${symbol}${res?.id}`);
            });
    }

    useEffect(() => {
        if (etablissement == !undefined || etablissement == ! null) {
            dispatch(fetchPventes(etablissement));
        }
    }, [etablissement])
    const agentsSAV = useSelector((state) => state.user.agentsSAV)
    const [pVName, setPVName] = useState("")
    const chaines = useSelector((state) => state.chaine.chaines)
    const pointDeVentes = useSelector((state) => state.pointDeVente.pventes)


    const onFinishUpdate = async (values) => {

        if (dirty) {
            onCheckRequiredFields(form)

            dispatch(
                updateTicket({
                    id: ticket?.id,
                    modified: {
                        ...values,
                    },
                })
            )
                .then((res) => {

                    const dirtyEditIndex = editedTicketDraft.findIndex((item) => item.id === res?.payload?.data?.data?.result?.id);
                    // const draftList = editedTicketDraft.filter((item, index) => {
                    //     return index !== dirtyEditIndex
                    // })

                    dispatch(resetEditTicketDraftByIndex(res?.payload?.data?.data?.result?.id))
                    setDirty(false)
                    setTicket(res?.payload?.data?.data?.result)
                    props.setDirty(false);
                    Persistor.persist();

                    const getintervenant = res?.payload?.data?.data?.result?.intervenantFirstname + ' ' + res?.payload?.data?.data?.result?.intervenantLastname
                    form.setFieldsValue({
                        etablissement: res?.payload?.data?.data?.result?.idChaine,
                        pointDeVente: res?.payload?.data?.data?.result?.idPv,
                        machine: res?.payload?.data?.data?.result?.machine,
                        type: res?.payload?.data?.data?.result?.type,
                        sujet: res?.payload?.data?.data?.result?.sujet,
                        priorite: res?.payload?.data?.data?.result?.priorite,
                        intervenant: getintervenant,
                        impact: res?.payload?.data?.data?.result?.impact,
                        idAssignee: res?.payload?.data?.data?.result?.idAssigne,
                        telephone: res?.payload?.data?.data?.result?.intervenantPhone,
                        description: res?.payload?.data?.data?.result?.description

                    })

                    if ((res?.payload?.data?.status).toLowerCase() === 'success') {

                        setSeverity('success');
                        setMessage('Les détails du ticket ont été mis à jour');
                        setOpen(true);

                    }
                }).catch((error) => {

                    setSeverity('error');
                    setMessage('Un problème est survenu lors de la mise à jours du ticket!');
                    setOpenErrorBox(true);
                });

        }

    };
    const onChangePV = (item) => {

        setPointDeVente(item);
    };
    const onChangeEtablissement = (item) => {
        form.setFieldsValue({

            pointDeVente: null,
            intervenantId: null,

        })


        setOnePV(false)
        setEtablissement(item);
        dispatch(fetchPventes(item)).then(unwrapResult).then((res) => {

            if (res.data.data.result.length === 1) {
                setPointDeVente(res.data.data.result[0].id)
                setPVName(res.data.data.result[0].nom)
                setOnePV(true)


                form.setFieldsValue({

                    pointDeVente: res.data.data.result[0].id,

                })
            }
        });
    };

    const validateNonEmptyContent = (rule, value, callback) => {
        if (!value || value.trim() === '') {
            callback('Veuillez fournir un texte pertinent.');
        } else {
            callback();
        }
    };

    const handleClearPrioriteSelection = () => {
        form.setFieldsValue({

            priorite: null,

        }) // Clear selected value
    };



    // Function to handle form values change

    const onValuesChange = (changedValues, allValues) => {
        props.setDirty(true);
      
        if (changedValues) {
            setDirty(true);
            let editedTicketDraftCopy = [...editedTicketDraft];
           
            const existingIndex = editedTicketDraftCopy.findIndex(ticket => ticket.id === ticket?.id);
           
            if (existingIndex !== -1) {

                // If the item with the same ID exists, replace it by creating a new array
                editedTicketDraftCopy = editedTicketDraftCopy.map(item => {
                    if (item.id === ticket?.id) {
                        // Keep old values for unchanged fields
                        const updatedItem = { ...item.updatedItem, ...changedValues };
                        return { ...item, updatedItem };
                    }
                    return item;
                });
            } else {
                // If the item with the same ID does not exist, add it to the array
                editedTicketDraftCopy = [
                    ...editedTicketDraftCopy,
                    {
                        updatedItem: allValues,
                        id: ticket?.id
                    }
                ];
            }

            dispatch(setEditTicketDraft(editedTicketDraftCopy));
            Persistor.persist();
        }

    };
    const filterOption = (input, option) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return (<>
        <div
            style={{
                width: '110%',
                height: '63vh',
                overflowY: 'auto',
                backgroundColor: 'white'

            }}
        >
          

            <div
                style={{
                    //  display: 'flex',
                    direction: 'row',
                    // alignItems: 'flex-end',
                    // justifyContent: 'flex-end',
                    backgroundColor: 'white',
                    marginTop: '-52px',




                }}
            >
                {loading ? <>  <CircularProgress size={24} /></> : <>
                    <div style={{ marginLeft: '25px' }}>
                        <Form
                            {...layout}
                            form={form}
                            name="control-hooks"
                            onFinish={onFinishUpdate}
                            onValuesChange={onValuesChange}
                            ref={formRef}
                        >
                            <Form.Item>
                                <div style={{
                                    display: 'flex',
                                    direction: 'row',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                    backgroundColor: 'white',
                                    height: '60px',
                                    gap: '5px',
                                    marginLeft: '10px',
                                    marginRight: 'auto',

                                }}

                                ><H4 title='Détails du ticket:' color={Colors.primary} /></div>
                                <span style={{

                                    marginLeft: '10px',
                                    marginRight: 'auto',
                                    color: Colors.black,

                                    textAlign: "left",
                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                    fontFamily: "DM Sans",
                                    fontSize: "18px",
                                    fontStyle: 'bold',
                                    fontWeight: 700,
                                    lineHeight: "16px",
                                    weight: "24px",
                                    height: "20px",
                                    textTransform: "none",

                                }}>Détails du ticket:</span>
                                <div
                                    style={{
                                        display: 'flex',
                                        direction: 'row',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end',
                                        backgroundColor: 'white',
                                        height: '60px',
                                        gap: '5px',
                                        marginLeft: 'auto',
                                        marginRight: '-100px'
                                    }}
                                >
                                    <AddNotesInternes idTicket={ticket?.id} fetchData={fetchData} />

                                    <Button
                                        sx={{
                                            borderRadius: 32,
                                            textTransform: "none",
                                            color: '#FFF',
                                            height: '50px',
                                            weight: '500px',
                                            padding: '20px',
                                            letterSpacing: '1px',
                                            textAlign: 'center',
                                            fontFamily: "DM Sans",
                                            fontSize: '13px',
                                            fontStyle: 'normal',
                                            fontWeight: 'semi-bold',
                                            //  lineHeight: '12%',
                                        }}
                                        type="submit"
                                        color="primary"
                                        variant='contained'
                                    // ref={formRef}
                                    >
                                        Sauvegarder
                                    </Button>
                                </div>
                            </Form.Item>
                            <GridContainer >
                                <GridItem sx={12} sm={6} md={4} xl={4} xxl={4} >
                                    <H5 title={'Etablissement'} requiredInput={true} />
                                    <Form.Item
                                        name="etablissement"
                                        // onChange={handleChange}

                                        // label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ce champ est obligatoire !',
                                            },
                                        ]}

                                    >
                                        <Select
                                            showSearch
                                            filterOption={filterOption}
                                            disabled
                                            placeholder=""
                                            className="selectTypeProblem"
                                            style={{


                                                backgroundColor: '#F1F4FA',

                                                width: '100%'
                                            }}
                                            onChange={onChangeEtablissement}>
                                            {chaines &&
                                                chaines.map((chaine) => (
                                                    <option key={chaine.id} value={chaine.id}>
                                                        {chaine.nom}
                                                    </option>
                                                ))}

                                        </Select>
                                    </Form.Item>
                                    <H5 title={'Machine'} requiredInput={true} />
                                    <Form.Item
                                        name="machine"
                                        // label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ce champ est obligatoire !',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            filterOption={filterOption}
                                            placeholder="" >
                                            {array_machine &&
                                                array_machine.map((machine) => (
                                                    <option key={machine.id} value={machine.id}>
                                                        {machine.nom}
                                                    </option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <H5 title={'Sujet'} requiredInput={true} />
                                    <Form.Item
                                        name="sujet"
                                        // label="Name"
                                        rules={[
                                            {
                                                maxLength: 100,
                                                required: true,
                                                message: 'Ce champ est obligatoire !',
                                                validator: validateNonEmptyContent
                                            },
                                        ]}

                                    >
                                        <Input style={{ width: "100%" }}
                                            maxLength='100' count={{
                                                show: true,
                                                max: 100,
                                            }}
                                        />
                                    </Form.Item>
                                    <H5 title={'Priorité'} requiredInput={true} />
                                    <Form.Item
                                        name="priorite"
                                        // label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ce champ est obligatoire !',
                                            },
                                        ]}
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            filterOption={filterOption}
                                            onClear={handleClearPrioriteSelection}
                                            placeholder="" >
                                            {array_priorite &&
                                                array_priorite.map((priorite) => (
                                                    <option key={priorite.id} value={priorite.id}>
                                                        {priorite.nom}
                                                    </option>
                                                ))}
                                        </Select>
                                    </Form.Item>


                                    <H5 title={'Intervenant'} requiredInput={false} />
                                    <Form.Item
                                        name="intervenant"
                                    // label="Name"
                                    //readOnly

                                    >
                                        <Input
                                            style={{
                                                width: "100%",
                                                fontWeight: 400,
                                                color: 'black',
                                                backgroundColor: 'rgb(232, 234, 240)',
                                            }}
                                            disabled={true} placeholder="" />

                                    </Form.Item>
                                </GridItem>
                                <GridItem sx={12} sm={6} md={4} xl={4} xxl={4} >
                                    <H5 title={'Point de vente'} requiredInput={true} />
                                    {onePV ? (<Form.Item
                                        name="pointDeVente"
                                        initialValue={pointDeVente}
                                        // value={pointDeVente}
                                        // label="Name"
                                        rules={[
                                            {
                                                // disabled : true,
                                                required: true,
                                                message: 'Ce champ est obligatoire !',
                                            },
                                        ]}
                                    // style={{
                                    //   marginBottom: '0.5%',
                                    //   // marginRight: "32px"
                                    // }}
                                    // style={{ marginRight: "19px" }}
                                    >
                                        <Select
                                            style={{ backgroundColor: '#D9E1EC' }}
                                            showSearch
                                            filterOption={filterOption}
                                            placeholder="" onChange={onChangePV}>
                                            {pointDeVentes &&
                                                pointDeVentes.map((pv) => (
                                                    <option key={pv.id} value={pv.id}>
                                                        {pv.nom}
                                                    </option>
                                                ))}
                                        </Select>
                                    </Form.Item>) : (<Form.Item
                                        name="pointDeVente"
                                        // label="Name"

                                        //readOnly
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ce champ est obligatoire !',
                                            },
                                        ]}
                                    // style={{
                                    //   marginBottom: '0.5%',
                                    //   //  marginRight: "19px" 
                                    //   // marginRight: "32px"
                                    // }}


                                    >
                                        <Select
                                            showSearch
                                            filterOption={filterOption}
                                            placeholder="" onChange={onChangePV}>
                                            {pointDeVentes &&
                                                pointDeVentes.map((pv) => (
                                                    <option key={pv.id} value={pv.id}>
                                                        {pv.nom}
                                                    </option>
                                                ))}
                                        </Select>
                                    </Form.Item>)}
                                    <H5 title={'Type de problème'} requiredInput={true} />
                                    <Form.Item
                                        name="type"
                                        // label="Name"
                                        //readOnly
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ce champ est obligatoire !',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            filterOption={filterOption}
                                            placeholder="" sx={{
                                                width: '100%'
                                            }}>
                                            {array_typeticket &&
                                                array_typeticket.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.nom}
                                                    </option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <H5 title={'Impact de ticket'} requiredInput={false} />
                                    <Form.Item
                                        name="impact"
                                        // label="Name"
                                        //readOnly
                                        rules={[
                                            {
                                                required: false,

                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            filterOption={filterOption}
                                            placeholder="" >
                                            {array_impact &&
                                                array_impact.map((impact) => (
                                                    <option key={impact.id} value={impact.id}>
                                                        {impact.nom}
                                                    </option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <H5 title={'Assigner à'} requiredInput={true} />
                                    <Form.Item
                                        name="idAssignee"
                                        // label="Name"
                                        //readOnly
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ce champ est obligatoire !',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            filterOption={filterOption}
                                            placeholder="" >
                                            {agentsSAV.map((option, index) => {
                                                return (
                                                    <MenuItem key={index} value={option.id}>{`${option.nom} ${option.prenom}`}</MenuItem>
                                                )
                                            })}
                                            {/* {agentsSAV &&
                                agentsSAV.map((assigne) => (
                                    <option key={assigne.id} value={assigne.id}>
                                        {assigne.nom} {' '} {assigne.prenom}
                                    </option>
                                ))} */}
                                        </Select>
                                    </Form.Item>
                                    <H5 title={'Télephone'} requiredInput={false} />
                                    <Form.Item
                                        name="telephone"
                                        // label="Name"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            disabled={true}
                                            style={{
                                                width: "100%",
                                                fontWeight: 400,
                                                color: 'black',
                                                backgroundColor: 'rgb(232, 234, 240)',
                                            }}

                                        />
                                    </Form.Item>
                                </GridItem>
                                <GridItem style={{ width: "100%" }} sx={12} sm={6} md={4} xl={4} xxl={4}>
                                    <H5 title={'Description'} requiredInput={true} />
                                    <Form.Item
                                        name="description"
                                        // label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ce champ est obligatoire !',
                                                maxLength: 350,
                                                validator: validateNonEmptyContent
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 14, maxRows: 14 }}
                                            rows={14} maxLength='350' count={{
                                                show: true,
                                                max: 350,
                                            }} />
                                    </Form.Item>
                                </GridItem>

                            </GridContainer>
                            {/* <Snackbars severity={severity} message={message} open={open} setOpen={setOpen}
            autoHideDuration={1000}
        //fetchNotReadMessages={fetchNotReadMessages}
        /> */}
                            <Snackbars severity={'success'} message={message} open={open} setOpen={setOpen} autoHideDuration={1000} />
                            <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autoHideDuration={1000} />

                        </Form >
                    </div>
                    <NotesList notes={ticket?.notesInternes} />
                </>
                }
            </div>
        </div>
    </>);
}