import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'


export const fetchUsersAgentSAV = createAsyncThunk('user/fetchUsersAgentSAV', 
() => {
    const tokenStored = localStorage.getItem('TokenStored')
    const timezone = localStorage.getItem('TZ')
    return axios.get(BaseUrl + '/SAV/getListAgentsSAV', 
    {headers:  {
        'Content-Type': 'application/json',
        'Authorization': tokenStored,
        'Platform': 'web',
        'timezone' : timezone,
        
     }});
   

   
  });
  export const fetchUser = createAsyncThunk('user/fetchUser', 
() => {
    const tokenStored = localStorage.getItem('TokenStored')
    const timezone = localStorage.getItem('TZ')
    return axios.get(BaseUrl + '/Account/getUser', 
    {headers:  {
        'Content-Type': 'application/json',
        'Authorization': tokenStored,
        'Platform': 'web',
        'timezone' : timezone,
        
     }});
   

   
  });
const initialState = {
    loading : false,
    data : [],
    agentsSAV : [],
    connectedUser : null,
    error: "",
};
const usersSlice = createSlice({
    name: ' user',
    initialState
    ,
    extraReducers: (builder) => {
        builder.addCase(fetchUsersAgentSAV.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchUsersAgentSAV.fulfilled, (state, action) => {
            state.loading = false;
            state.agentsSAV = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(fetchUsersAgentSAV.rejected, (state, action) => {
            state.loading = false;
            state.agentsSAV = [];
            state.error = action.error.message;
        }) 
        builder.addCase(fetchUser.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.loading = false;
            state.connectedUser = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.loading = false;
            state.connectedUser = null;
            state.error = action.error.message;
        }) 
}
});
export default usersSlice.reducer;