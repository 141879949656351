
import React from 'react'

import SvgIcon from '@mui/material/SvgIcon';
export default function ChevronLeftIcon () {
    return (
       
        <SvgIcon>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M10.4717 4.47206C10.7321 4.21171 10.7321 3.7896 10.4717 3.52925C10.2114 3.2689 9.78927 3.2689 9.52892 3.52925L5.52892 7.52925C5.26857 7.7896 5.26857 8.21171 5.52892 8.47205L9.52892 12.4721C9.78927 12.7324 10.2114 12.7324 10.4717 12.4721C10.7321 12.2117 10.7321 11.7896 10.4717 11.5292L6.94313 8.00065L10.4717 4.47206Z" fill="currentColor"/>
</svg>
     </SvgIcon>
    )
}