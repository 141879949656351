import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ticketReducer from "./features/SAV/TicketSlice";
import userReducer from "./features/User/UserSlice";
import chaineReducer from "./features/Chaines/ChaineSlice";
import pointDeVenteReducer from "./features/Pointdeventes/PointdeventeSlice";
import chatReducer from "./features/Chat/ChatSlice";
import attachementReducer from "./features/Attachement/AttachementSlice";
import axios from "axios";
import tabReducer from "./features/Tabs/TabsSlice"
import {  persistReducer  } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import paginationReducer from './features/Pagination/PaginationsSlice'
import NewTicketDraftReducer from './features/SAV/NewTicketDraftSlice'
import formReducer from './features/Forms/FormSlice';
import noteReducer from './features/NotesInternes/NotesInternesSlice';
import filterReducer from './features/SAV/FilterSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['tabs', 'pagination', 'draftNewTicket', 'forms', 'filter'],
}
const rootReducer = combineReducers( {
  //paginations
  pagination : paginationReducer,
  //tabs
  tabs: tabReducer,
    //SAV
  draftNewTicket: NewTicketDraftReducer,
  ticket: ticketReducer,
  user : userReducer,
  chaine : chaineReducer,
  pointDeVente : pointDeVenteReducer,
  chat : chatReducer,
  attachement : attachementReducer,
  forms : formReducer,
  note : noteReducer,
  filter : filterReducer,
 
})
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const Store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
    
  });
  axios.interceptors.response.use(function (response) {
   
    return response;
  }, function (error) {
   
    Store.dispatch({
        type: 'api_error',
        payload: error,
  
    })
  
    return Promise.reject(error);
  });

 

export default Store;