
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function RedDotIcon () {
    return (
       
        <SvgIcon>

<svg width="8" height="8" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="3" cy="3" r="3" fill="#FF2424"/>
</svg>  </SvgIcon>
    )
}