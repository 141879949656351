import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import IconGlobalization from '../IconComponents/IconGlobalization';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
const StyledBadge = styled(Badge) ({
  
    color :  '#295DB1',
     backgroundColor: 'white'
   
 
});
export default function DialogSelectLanguage() {
    /**
     * Access translation functions and current language using the useTranslation hook.
     */
    const { t, i18n } = useTranslation();

    /**
     * State variable to manage the open/closed state of the dialog.
     */
    const [open, setOpen] = React.useState(false);

    /**
     * State variable to store the selected language.
     */
    const [lng, setLng] = React.useState(localStorage.getItem('i18nextLng'));

    /**
     * Handle change event for the language select dropdown.
     * Updates the state variable for the selected language and changes the application's language.
     */
    const handleChange = (event) => {
        setLng(event.target.value || 'fr');
        i18n.changeLanguage(event.target.value);
        localStorage.setItem('language', event.target.value);
        handleClose();
    };

    /**
     * Handles opening the dialog.
     */
    const handleClickOpen = () => {
        setOpen(true);
    };

    /**
     * Handles closing the dialog.
     * Prevents closing on backdrop click to avoid accidental language changes.
     */
    const handleClose = (event, reason) => {
        // if (reason !== 'backdropClick') {
        setOpen(false);
        // }
    };

    return (
        <div>
            <IconButton onClick={handleClickOpen} size='meduim' sx={{
                // padding : '0px',
                // margin : '0px',
                paddingTop : '0px',
                marginTop : '0px',
            }}>
                <StyledBadge>
                
                 <IconGlobalization  /> 
           
            </StyledBadge>
            </IconButton>
            <Dialog
                // disableEscapeKeyDown
                hideBackdrop
                sx={{
                    // left: 1300,
                    // top: -675,
                    minWidth: 500,
                }}
                open={open}
                onClose={handleClose}>
                <DialogTitle align="center">{t('Changer la langue')}</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 350 }}>
                            <InputLabel htmlFor="demo-dialog-native">{t('language')}</InputLabel>
                            <Select
                                native
                                value={lng}
                                onChange={handleChange}
                                input={<OutlinedInput label="Langue" id="dialog" />}
                            >
                                <option value={'fr'}>Fr</option>
                                <option value={'en'}>En</option>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}
