import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
import { Colors } from '../Utility/Colors';
export default function FiltreIcon () {
    return (
       
        <SvgIcon>

<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.833313 1.50008C0.833313 1.03984 1.20641 0.666748 1.66665 0.666748H13.3333C13.7936 0.666748 14.1666 1.03984 14.1666 1.50008C14.1666 1.96032 13.7936 2.33341 13.3333 2.33341H1.66665C1.20641 2.33341 0.833313 1.96032 0.833313 1.50008Z" fill="#2A66C6"/>
<path d="M2.49998 6.50008C2.49998 6.03984 2.87308 5.66675 3.33331 5.66675H11.6666C12.1269 5.66675 12.5 6.03984 12.5 6.50008C12.5 6.96032 12.1269 7.33341 11.6666 7.33341H3.33331C2.87308 7.33341 2.49998 6.96032 2.49998 6.50008Z" fill="#2A66C6"/>
<path d="M4.99998 10.6667C4.53974 10.6667 4.16665 11.0398 4.16665 11.5001C4.16665 11.9603 4.53974 12.3334 4.99998 12.3334H9.99998C10.4602 12.3334 10.8333 11.9603 10.8333 11.5001C10.8333 11.0398 10.4602 10.6667 9.99998 10.6667H4.99998Z" fill="#2A66C6"/>
</svg></SvgIcon>)}