
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function CheckedIcon () {
    return (
       
        <SvgIcon sx={{width : '15px', marginRight : '10px', marginLeft : '10px'}}>
<svg width="1" height="1" viewBox="0 -2 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.50007 5.08516L1.76507 3.35016C1.57007 3.15516 1.25507 3.15516 1.06007 3.35016C0.865068 3.54516 0.865068 3.86016 1.06007 4.05516L3.15007 6.14516C3.34507 6.34016 3.66007 6.34016 3.85507 6.14516L9.14507 0.855156C9.34007 0.660156 9.34007 0.345156 9.14507 0.150156C8.95007 -0.0448437 8.63507 -0.0448437 8.44007 0.150156L3.50007 5.08516Z" fill="#036D37"/>
</svg></SvgIcon>
    )
}