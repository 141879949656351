
import React, { useContext, useState, } from 'react';
import { Button,  Dialog, DialogContent, IconButton, DialogTitle, DialogActions } from "@mui/material";
import GridContainer from "../../GlobalComponents/Grid/GridContainer";
import GridItem from "../../GlobalComponents/Grid/GridItem";
import H4 from "../../GlobalComponents/TypoGraphy/H4";
import CloseIcon from '@mui/icons-material/Close';
// import "./style.css";
import { useDispatch } from 'react-redux';

import Snackbars from '../../GlobalComponents/Alerts/Snackbars';

import { NavContext } from '../../App';
import { Persistor } from '../..';
import { removeTab, setLastActiveTab } from '../../ReduxTLK/features/Tabs/TabsSlice';
import { resetNewTicketDraft } from '../../ReduxTLK/features/SAV/NewTicketDraftSlice';



export default function GenericDialogAddTicketSaveConfirmation(props) {

    //const [open, setOpen] = useState(false)
    const [message,] = useState()
    const [openErrorBox, setOpenErrorBox] = useState(false)
    const [openSuccessBox, setOpenSuccessBox] = useState(false)
    
    const context = useContext(NavContext)
    const dispatch = useDispatch()
   
    const handleClose = () => {
        props.setOpen(false)

    }
   
    const handleConfirm = () => {

        const tabToDeleteIndex = context.tabs.findIndex(
            tab => tab.id === props.tabToBeDelete.id
        );
        const updatedTabs = context.tabs.filter((tab, index) => {
            return index !== tabToDeleteIndex;
        });
        context.setTabs(updatedTabs);


        const previousTab =
            context.tabs[tabToDeleteIndex - 1] ||
            context.tabs[tabToDeleteIndex + 1] ||
            {};
        props.setActiveTabs(updatedTabs);
        context.setActiveTab(previousTab.id);

        dispatch(setLastActiveTab(previousTab.id));
        dispatch(resetNewTicketDraft())
        dispatch(removeTab(props.tabToBeDelete))
        Persistor.persist()
        props.setOpen(false)
        return;
    }

    return (
        <>
           
            <Dialog
                open={props.open} onClose={handleClose} maxWidth='sm'

            >
                <DialogTitle>
                    <GridContainer
                        sx={{
                            display: 'flex',
                            direction: 'row',
                            alignItems: 'stretch',

                        }}
                    >
                        <GridItem sx={11.5} sm={11.5} md={11.5} xl={11.5} xxl={11.5}>
                            <H4 title={`Êtes vous sur de vouloir  quitter sans sauvegarder les informations ajoutées ?`}>

                            </H4>


                        </GridItem>
                        <GridItem sx={0.010} sm={0.010} md={0.010} xl={0.010} xxl={0.010}>
                            <IconButton sx={{ marginRight: 0 }} onClick={handleClose}>
                                <CloseIcon></CloseIcon>
                            </IconButton>
                        </GridItem>
                        <GridItem sx={12} sm={12} md={12} xl={12} xxl={12}>


                            

                        </GridItem>
                    </GridContainer>
                </DialogTitle>
                <DialogContent>

                    <>

                    </>


                </DialogContent>
                <DialogActions>


                    <Button
                        sx={{
                            textTransform: "none",
                            border: '2px solid #2A66C6',
                            color: '#2A66C6',
                            borderColor: '#2A66C6',
                            borderRadius: '18px',
                            width: '93px',
                            height: '40px',
                        }}
                        onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>


                    <Button
                        onClick={() => handleConfirm()}
                        variant="contained" shape="rounded"
                        sx={{
                            // size : 'large', 
                            textTransform: "none",
                            borderRadius: 32,

                            '&.MuiButtonBase-root': {
                                color: '#FFFFFF',
                                backgroundColor: '#2A66C6',
                                borderRadius: '18px',
                                // width: '99px',
                                height: '40px',
                                width: '120px',
                                minWidth: '120px',
                                marginLeft: 2

                            }
                        }}
                    //color={Colors.primary}
                    >Confirmer</Button>
                </DialogActions>
            </Dialog>
            <Snackbars severity={'success'} message={message} open={openSuccessBox} setOpen={setOpenSuccessBox} autoHideDuration={1000} />
            <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autoHideDuration={1000} />
        </>
    )
}