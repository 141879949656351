/**
 * Imports React component from 'react' library.
 * Imports H5 component from '../TypoGraphy/H5' module.
 *
 * @param {number} props.page - The current page number.
 * @param {number} props.count - The total page count.
 * @returns {React.Component} The DataGridPaginationTitle component.
 */
import React from "react";
import H5 from "../TypoGraphy/H5";

export default function DataGridPaginationTitle(props) {
    /**
     * Constructs the pagination title string with page number and page count information.
     */
    const title = ' ' + 'Page' + ' ' + props.page + ' ' + 'de' + ' ' + props.count;

    return (
        <H5 title={title} />
    );
}