import React ,  { Suspense }from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MemoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import "./i18next";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import Store from './ReduxTLK/store'
/**
 * The main entry point for the React application.
 */

const root = ReactDOM.createRoot(document.getElementById('root'));

export const Persistor = persistStore(Store);


/**
 * Render the React application.
 */
root.render(
  
  <Provider store={Store}>
       <PersistGate loading={null}
       persistor={Persistor}
       >  
  <Suspense fallback="loading">
  {/* <React.StrictMode> */}
    <MemoryRouter initialEntries={['/']}>
     
          <App />
      
    </MemoryRouter>
  {/* </React.StrictMode> */}
  </Suspense>
  </PersistGate>  
  </Provider> 

  );


reportWebVitals();
