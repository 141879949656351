import React, { useState, useEffect, useContext } from "react"

import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import routes from '../../routes';
import InputBase from '@mui/material/InputBase';
import { IconButton} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import ListSubheader from '@mui/material/ListSubheader';

import SAVIcon from "../IconComponents/SAVIcon";

import { Layout, Menu, theme } from 'antd';
import ListAllTickets from "../../Pages/SAV/TicketList/ListAllTickets";
import { Persistor } from "../..";
import { NavContext } from "../../App";
import { useDispatch } from "react-redux";
import { addTab, setLastActiveTab } from "../../ReduxTLK/features/Tabs/TabsSlice";

import New from "../../Pages/SAV/AddTicket/NewSAVTicket";
import DotIcon from "../IconComponents/DotIcon";
import SidebarFooter from "./SidebarFooter";


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(

  ({ theme, open, drawerwidth, height }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerwidth,
      marginBottom: 10,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),

        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',
  borderRadius: '20px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  //padding: '12px 16px',
  border: '1px solid var(--Light-Gray, #F1F1F1)',
  background: 'var(--White, #FFF)',
  // '&:hover': {
  //   backgroundColor: '#F0F9FF',
  // },
  marginRight: theme.spacing(2),
  marginleft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginleft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const CustomIconSAV = () => {
  return (

    <div style={{ display: 'flex', alignItems: 'center', marginTop: 0 ,
    // backgroundColor : 'white'
     }}>

      <SAVIcon style={{ marginRight: 10 }} />
      <span style={{ marginLeft: 27, fontWeight: '600', fontSize: '14px' }}>Support SAV</span>
    </div>
  )


}
const CustomIconDotTT = ({mode}) => {
  
  return (
    
    <div style={{ marginRight: mode === 'inline' ? 5  : 0, display: 'flex', alignItems: 'center', marginTop: -10  }}>
     
    <span style={{ marginTop: mode === 'inline' ? 32  : 30}}><DotIcon sx={{ width: '10px'}} /></span>

      <span style={{ marginLeft: mode === 'inline' ? 0  : -10, fontWeight: '600', fontSize: '14px' }}>Tous Les Tickets</span>
       </div> 
      
  )
}
const CustomIconDotAT = ({mode}) => {
  return (
    
    <div style={{ marginRight: mode === 'inline' ? 5  : 0, display: 'flex', alignItems: 'center', marginTop: -10 }}>
    
     
      <span style={{ marginTop: mode === 'inline' ? 32  : 30 }}><DotIcon sx={{ width: '10px'}} /></span>
      <span style={{ marginLeft: mode === 'inline' ? 0  : -10, fontWeight: '600', fontSize: '14px' }}>Ajout Ticket</span>
      
    </div>
  )


}
const { Sider , Footer} = Layout;
export function useWindowDimensions() {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default function Sidebar({ toggleDrawer, drawerwidth, open, sidebarMinimize, sidebarMaximize, miniActive, mode }) {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { height, width } = useWindowDimensions();
  const drawerFooterHeight = (height / 100) * 7;
  const sideBarWidth = (width / 100) * 16;
  const context = useContext(NavContext)
  const dispatch = useDispatch()
  const [openCollapse, setOpenCollapse] = React.useState(false);
  
 
  const { SubMenu } = Menu;
  function openNewTabTT(props) {


    // Find any existing tab with the same ID
    const existingTabIndex = context.tabs.findIndex((tab) => tab.id === 3);

    // If a tab with the ID already exists, activate it
    if (existingTabIndex !== -1) {
      context.setActiveTab(3);
      dispatch(setLastActiveTab(3));
      return; // Exit the function to avoid creating a duplicate tab
    }

    // If no existing tab found, create a new one
    // setIndex(composedId);
    dispatch(addTab({
      module: 'SAV',
      action: 'Tous les tickets',
      identifier: 3,
      id: 3,
      label: 'Tous les tickets',
    }));

    context.setTabs([...context.tabs, {
      id: 3,
      label: 'Tous les tickets',
      identifier: 3,
      component: <ListAllTickets />,
      closeable: true,
      icon: <SAVIcon />,
    }]);
    Persistor.persist();
    // context.setMaxTabIndex(index + 1);
    context.setActiveTab(3);
    dispatch(setLastActiveTab(3));
    // context.setSelectedTicket(selectedTicket);
  }
  function openNewTabAT(props) {


    // Find any existing tab with the same ID
    const existingTabIndex = context.tabs.findIndex((tab) => tab.id === 2);

    // If a tab with the ID already exists, activate it
    if (existingTabIndex !== -1) {
      context.setActiveTab(2);
      dispatch(setLastActiveTab(2));
      return; // Exit the function to avoid creating a duplicate tab
    }

    // If no existing tab found, create a new one
    // setIndex(composedId);
    dispatch(addTab({
      module: 'SAV',
      action: 'Ajout Ticket',
      identifier: 2,
      id: 2,
      label: 'Ajout Ticket',
    }));

    context.setTabs([...context.tabs, {
      id: 2,
      label: 'Ajout Ticket',
      identifier: 2,
      component: <New />,
      closeable: true,
      icon: <SAVIcon />,
    }]);
    Persistor.persist();
    // context.setMaxTabIndex(index + 1);
    context.setActiveTab(2);
    dispatch(setLastActiveTab(2));
    // context.setSelectedTicket(selectedTicket);
  }
  const handleClick = () => {
    setOpenCollapse(!openCollapse);
  };

  const [searchInput, setSearchInput] = useState('');
  const [Routes, setRoutes] = useState([]);
  const [filtredMenu, setFiltredMenu] = useState([]);

  useEffect(() => {

    if (routes[0].pages.length > 0) {
      setRoutes(routes[0].pages);
      setFiltredMenu(routes[0].pages);
    }

  }, []);


  function handleSearchMenu(e) {
    var lowerCase = e.target.value.toLowerCase();
    setSearchInput(lowerCase);
    const filtredMenu = Routes.filter((item) => {
      if (searchInput === '') {

        return item;

      }
      else {

        return item.name.toLowerCase().includes(searchInput)
      }
    }
    )
    setFiltredMenu(filtredMenu);


  }

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", minHeight: "calc(100vh)" , alignItems : 'normal', alignContent: 'center'}}>
      <Drawer
        variant="permanent"
        open={open}
        drawerwidth={drawerwidth}
      >
       
        <div 
          //  style={{ 
          //   height: 820,
          //  marginTop: '20px' 
          //  }}
           style={{ display: "flex", flexDirection: "column", minHeight: "calc(90vh)" , alignItems : 'normal', alignContent: 'center'}}
           >
        
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'white' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {open ?

                  <Search>
                    <SearchIconWrapper>

                      <SearchIcon sx={{
                        color: '#7D7D7D',
                      }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchInput}
                      onChange={handleSearchMenu}
                    />
                  </Search>

                  :
                  <IconButton
                    component="div"
                    //onClick={toggleDrawer}
                    sx={{
                      color: '#7D7D7D',
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                }
              </ListSubheader>
            }
          >
          
            <Layout
           
              style={{
                padding: '24px 0',
                background: 'white',
                borderRadius: borderRadiusLG,
              }}
            >
              {/* {open ?  */}
              <Sider
             
                style={{
                  background: colorBgContainer,
                }}
                width={mode === 'inline' ? 260 : 70}
              >
                  <Menu
                  
                  mode={mode}
                  // defaultSelectedKeys={['1']}
                  // defaultOpenKeys={['sub1']}
                  style={{
                    height: '100%',
                    backgroundColor :'white'
                  
                  }}
                // items={items}
                // onClick={({ key }) => handleClick(items.find((item) => item.key === key))}
                >
                  <SubMenu
                 
                   style={{
                      width : mode === 'inline' ? '100%' : '60px',
                    //  backgroundColor : 'white'
                     // marginBottom : '200px'
                  }}
                  key="1" 
                  title={<div style={{
                   // backgroundColor : 'white'
                  }}><CustomIconSAV /> </div>}
                  >
                    <Menu.Item key="1-1" onClick={() => openNewTabTT()}>
                      <div> <CustomIconDotTT mode={mode}/></div>
                    </Menu.Item>
                    <Menu.Item 
                    style={
                      {
                        marginBottom : '20px'
                      }
                    }
                    key="1-2" onClick={() => openNewTabAT()}>
                      <div><CustomIconDotAT mode={mode}/></div>
                    </Menu.Item>
                  </SubMenu>
                </Menu> 
                </Sider>
                {/* : <Sider
                style={{
                  background: colorBgContainer,
                  
                }}
                width={1000}
                trigger={null} 
                collapsible 
                collapsed={true}
              > <Menu
                 mode={mode}
                  // defaultSelectedKeys={['1']}
                  // defaultOpenKeys={['sub1']}
                  style={{
                    height: '100%',
                    
                  }}
                
                // items={items}
                // onClick={({ key }) => handleClick(items.find((item) => item.key === key))}
                >
                  <SubMenu  key="1" title={<div ><SAVIcon /> </div>}>
                    <Menu.Item key="1-1" onClick={() => openNewTabTT()}>
                      <div> <CustomIconDotTT /></div>
                    </Menu.Item>
                    <Menu.Item key="1-2" onClick={() => openNewTabAT()}>
                      <div><CustomIconDotAT /></div>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
                
               </Sider>
             
                }
                */}

 
            </Layout>

          
          </List>
        </div>
        <footer style={{display: "flex", flexDirection: "column" , bottom : 0 }}>
            <SidebarFooter 
             //toggleDrawer={toggleDrawer}
             open={open}
             mode={mode}
             //drawerwidth={drawerwidth}
             sidebarMinimize={sidebarMinimize}
             sidebarMaximize={sidebarMaximize}
             miniActive={miniActive}
            />
          </footer> 
      </Drawer>
      </div>

    </>
  )
}
