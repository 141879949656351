
import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
export default function ViewIcon () {
    return (
       
        <SvgIcon>
             {/* <img  src="../../assets/icons/eye.svg"/> */}
 <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
<g clipPath="url(#clip0_1_34313)">
<path d="M0.834473 8.49935C0.834473 8.49935 3.50114 3.16602 8.16781 3.16602C12.8345 3.16602 15.5011 8.49935 15.5011 8.49935C15.5011 8.49935 12.8345 13.8327 8.16781 13.8327C3.50114 13.8327 0.834473 8.49935 0.834473 8.49935Z" stroke="#344054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.16781 10.4993C9.27237 10.4993 10.1678 9.60392 10.1678 8.49935C10.1678 7.39478 9.27237 6.49935 8.16781 6.49935C7.06324 6.49935 6.16781 7.39478 6.16781 8.49935C6.16781 9.60392 7.06324 10.4993 8.16781 10.4993Z" stroke="#344054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1_34313">
<rect width="16" height="16" fill="white" transform="translate(0.166504 0.5)"/>
</clipPath>
</defs>
</svg> 
     </SvgIcon>
    )
}