import React, { useState, useEffect } from "react";


import { Colors } from "../../../../GlobalComponents/Utility/Colors";
import GridItem from "../../../../GlobalComponents/Grid/GridItem";
import GridContainer from "../../../../GlobalComponents/Grid/GridContainer";
import H5 from '../../../../GlobalComponents/TypoGraphy/H5';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, InputNumber } from 'antd';
import {
  fetchTicketById,
  updateTicketAssignedTo,

} from "../../../../ReduxTLK/features/SAV/TicketSlice";
import { Button, MenuItem } from "@mui/material";

import { array_machine, array_typeticket, array_impact, array_priorite } from "../../../../ReduxTLK/features/SAV/ticketList";
import Snackbars from "../../../../GlobalComponents/Alerts/Snackbars";

import NotesList from "../../NotesInternes/NotesList";
import { resetEditTicketDraftByIndex, setEditTicketDraft } from "../../../../ReduxTLK/features/SAV/NewTicketDraftSlice";
import { Persistor } from "../../../..";
import AddNotesInternes from "../../NotesInternes/AddNotesInternes";
import { unwrapResult } from "@reduxjs/toolkit";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 22,
  },
};

export default function TicketDetailsView({ id, ticket, setTicket, ...props }) {
  const { TextArea } = Input
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false);
  const [openErrorBox, setOpenErrorBox] = useState(false);
  const [canExecute, setCanExecute] = useState(false);
  const [loading, setLoading] = useState(false);
  const agentsSAV = useSelector((state) => state.user.agentsSAV)
  const editedTicketDraft = useSelector((state) => state.draftNewTicket.editedTicketDraft);
  const chaines = useSelector((state) => state.chaine.chaines)
  const pointDeVentes = useSelector((state) => state.pointDeVente.pventes)
  useEffect(() => {
   
    if (ticket?.statut === 0) {
      setCanExecute(true);
    }
    const dirtyEditIndex = editedTicketDraft.findIndex((item) => item.id === ticket?.id);

    if (dirtyEditIndex !== -1) {

      setDirty(true)

      form.setFieldsValue(editedTicketDraft[dirtyEditIndex]?.updatedItem)
    }
    else {

      const getintervenant = ticket?.intervenantFirstname + ' ' + ticket?.intervenantLastname
      
      form.setFieldsValue({
        etablissement: ticket?.idChaine,
        pointDeVente: ticket?.idPv,
        machine: ticket?.machine,
        type: ticket?.type,
        sujet: ticket?.sujet,
        priorite: ticket?.priorite,
        intervenant: getintervenant,
        impact: ticket?.impact,
        idAssignee: ticket?.idAssigne,
        telephone: ticket?.intervenantPhone,
        description: ticket?.description

      })
    }
  }, [ticket])
  const fetchData = async () => {

    dispatch(fetchTicketById(ticket?.id))
        .then(unwrapResult)
        .then((res) => {

            setTicket(res);
           // setPageTitle(`${symbol}${res?.id}`);
        });
}
  const onFinishUpdate = async (values) => {
    if (dirty) {
      dispatch(
        updateTicketAssignedTo({
          openTicket: id, assigneeId: values.idAssignee,
        })
      )
        .then((res) => {

          dispatch(resetEditTicketDraftByIndex(res?.payload?.data?.data?.result?.id))
          setDirty(false)
         // setTicket(res?.payload?.data?.data?.result)
          props.setDirty(false);
          Persistor.persist();
          const getintervenant = res?.payload?.data?.data?.result?.intervenantFirstname + ' ' + res?.payload?.data?.data?.result?.intervenantLastname
          form.setFieldsValue({
            etablissement: res?.payload?.data?.data?.result?.idChaine,
            pointDeVente: res?.payload?.data?.data?.result?.idPv,
            machine: res?.payload?.data?.data?.result?.machine,
            type: res?.payload?.data?.data?.result?.type,
            sujet: res?.payload?.data?.data?.result?.sujet,
            priorite: res?.payload?.data?.data?.result?.priorite,
            intervenant: getintervenant,
            impact: res?.payload?.data?.data?.result?.impact,
            idAssignee: res?.payload?.data?.data?.result?.idAssigne,
            telephone: res?.payload?.data?.data?.result?.intervenantPhone,
            description: res?.payload?.data?.data?.result?.description

          })
          props.setDirty(false);
          if ((res?.payload?.data?.status).toLowerCase() === 'success') {

            setSeverity('success');
            setMessage('Les détails du ticket ont été mis à jour');
            setOpen(true);

          }
        }).catch((error) => {

          setSeverity('error');
          setMessage('Un problème est survenu lors de la mise à jours du ticket!');
          setOpenErrorBox(true);
        });

    }


  }
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const [dirty, setDirty] = useState(false)
  const onValuesChange = (changedValues, allValues) => {
    props.setDirty(true);

    if (changedValues) {
      setDirty(true);
      let editedTicketDraftCopy = [...editedTicketDraft];
      
      const existingIndex = editedTicketDraftCopy.findIndex(ticket => ticket.id === ticket?.id);
      
      if (existingIndex !== -1) {

        // If the item with the same ID exists, replace it by creating a new array
        editedTicketDraftCopy = editedTicketDraftCopy.map(item => {
          if (item.id === ticket?.id) {
            // Keep old values for unchanged fields
            const updatedItem = { ...item.updatedItem, ...changedValues };
            return { ...item, updatedItem };
          }
          return item;
        });
      } else {
        // If the item with the same ID does not exist, add it to the array
        editedTicketDraftCopy = [
          ...editedTicketDraftCopy,
          {
            updatedItem: allValues,
            id: ticket?.id
          }
        ];
       
      }

      dispatch(setEditTicketDraft(editedTicketDraftCopy));
     
      Persistor.persist();
    }

  };

  return (
    <div
      style={{
        width: '110%',
        height: '63vh',
        overflowY: 'auto',
        backgroundColor: 'white',
        marginLeft: '-10px'

      }}
    >


      <div
        style={{
          //  display: 'flex',
          direction: 'row',
          // alignItems: 'flex-end',
          // justifyContent: 'flex-end',
          backgroundColor: 'white',
          marginTop: '-52px',
          marginLeft: '20px',




        }}
      >

        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinishUpdate}
          onValuesChange={onValuesChange}
        >
          <div
          // style={{ display: 'flex', 
          // justifyContent: 'flex-end',
          //  alignItems: 'flex-end',
          //   marginRight: '0px' }}
          >
            <Form.Item>

            </Form.Item>
            <br></br>
            <span style={{

              marginLeft: '10px',
              marginRight: 'auto',
              color: Colors.black,

              textAlign: "left",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: "DM Sans",
              fontSize: "18px",
              fontStyle: 'bold',
              fontWeight: 700,
              lineHeight: "16px",
              weight: "24px",
              height: "20px",
              textTransform: "none",

            }}>Détails du ticket :</span>


            <Form.Item >
              <div
                style={{
                  display: 'flex',
                  direction: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  backgroundColor: 'white',
                  height: '60px',
                  gap: '5px',
                  marginLeft: 'auto',
                  marginRight: '-80px'
                }}
              >
                {canExecute ? 
                <>
                 <AddNotesInternes idTicket={ticket?.id} fetchData={fetchData} />
                 <Button
                  sx={{
                    borderRadius: 32,
                    textTransform: "none",
                    color: '#FFF',
                    height: '50px',
                    weight: '500px',
                    padding: '20px',
                    letterSpacing: '1px',
                    textAlign: 'center',
                    fontFamily: "DM Sans",
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 'semi-bold',
                    //  lineHeight: '12%',
                  }}
                  type="submit"
                  color="primary"
                  variant='contained'
                // ref={formRef}
                >
                  Sauvegarder
                </Button>
                </>
               
                  : <></>}
              </div>
            </Form.Item>
          </div>
          <GridContainer >
            <GridItem sx={12} sm={6} md={4} xl={4} xxl={4} >
              <H5 title={'Etablissement'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="etablissement"
              // onChange={handleChange}

              // label="Name"

              >
                <Select
                  disabled placeholder=""
                  className="selectTypeProblem"
                  style={{


                    backgroundColor: '#F1F4FA',

                    width: '100%'
                  }}
                >
                  {chaines &&
                    chaines.map((chaine) => (
                      <option key={chaine.id} value={chaine.id}>
                        {chaine.nom}
                      </option>
                    ))}
                </Select>
              </Form.Item>
              <H5 title={'Machine'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="machine"


              >
                <Select
                  className="selectTypeProblem"
                  style={{


                    backgroundColor: '#F1F4FA',

                    width: '100%'
                  }}
                  disabled placeholder="" >
                  {array_machine &&
                    array_machine.map((machine) => (
                      <option key={machine.id} value={machine.id}>
                        {machine.nom}
                      </option>
                    ))}
                </Select>
              </Form.Item>
              <H5 title={'Sujet'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="sujet"
              // label="Name"


              >
                <Input style={{
                  width: "100%",
                  fontWeight: 400,
                  color: 'black',
                  backgroundColor: 'rgb(232, 234, 240)',

                  "& .MuiInputBaseInput.MuiDisabled": {
                    WebkitTextFillColor: "#000000",

                  },
                }} disabled={true} />
              </Form.Item>
              <H5 title={'Priorité'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="priorite"
              // label="Name"

              >
                <Select
                  className="selectTypeProblem"
                  style={{


                    backgroundColor: '#F1F4FA',

                    width: '100%'
                  }}
                  disabled placeholder="" >
                  {array_priorite &&
                    array_priorite.map((priorite) => (
                      <option key={priorite.id} value={priorite.id}>
                        {priorite.nom}
                      </option>
                    ))}
                </Select>
              </Form.Item>


              <H5 title={'Intervenant'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="intervenant"
              // label="Name"
              //readOnly

              >
                <Input style={{

                  fontWeight: 400,
                  color: 'black',
                  backgroundColor: 'rgb(232, 234, 240)',


                  "& .MuiInputBaseInput.MuiDisabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }} disabled={true} placeholder="" />

              </Form.Item>
            </GridItem>
            <GridItem sx={12} sm={6} md={4} xl={4} xxl={4} >
              <H5 title={'Point de vente'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="pointDeVente"
              // label="Name"
              // onChange={handleChange}
              //readOnly

              >
                <Select
                  className="selectTypeProblem"
                  style={{


                    backgroundColor: '#F1F4FA',

                    width: '100%'
                  }}
                  disabled placeholder="" >
                  {pointDeVentes &&
                    pointDeVentes.map((pv) => (
                      <option key={pv.id} value={pv.id}>
                        {pv.nom}
                      </option>
                    ))}
                </Select>
              </Form.Item>
              <H5 title={'Type de problème'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="type"
              // label="Name"
              //readOnly

              >
                <Select className="selectTypeProblem"
                  style={{


                    backgroundColor: '#F1F4FA',

                    width: '100%'
                  }}
                  disabled placeholder=""
                >
                  {array_typeticket &&
                    array_typeticket.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.nom}
                      </option>
                    ))}
                </Select>
              </Form.Item>
              <H5 title={'Impact de ticket'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="impact"
              // label="Name"
              //readOnly

              >
                <Select
                  className="selectTypeProblem"
                  style={{


                    backgroundColor: '#F1F4FA',

                    width: '100%'
                  }}
                  disabled placeholder="" >
                  {array_impact &&
                    array_impact.map((impact) => (
                      <option key={impact.id} value={impact.id}>
                        {impact.nom}
                      </option>
                    ))}
                </Select>
              </Form.Item>
              <H5 title={'Assigner à'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="idAssignee"

              // label="Name"
              //readOnly

              >
                {canExecute ? <><Select
                  style={{


                    backgroundColor: '#F1F4FA',

                    width: '100%'
                  }}
                  showSearch
                  filterOption={filterOption} placeholder="" >
                  {agentsSAV.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.id}>{`${option.nom} ${option.prenom}`}</MenuItem>
                    )
                  })}
                </Select>
                </> : <>
                  <Select
                    className="selectTypeProblem"
                    style={{


                      backgroundColor: '#F1F4FA',

                      width: '100%'
                    }}
                    disabled placeholder="" >
                    {agentsSAV.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.id}>{`${option.nom} ${option.prenom}`}</MenuItem>
                      )
                    })}
                  </Select></>}
              </Form.Item>
              <H5 title={'Télephone'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="telephone"
              // label="Name"

              >
                <InputNumber disabled={true}
                  style={{
                    width: "100%",
                    fontWeight: 400,
                    color: 'black',
                    backgroundColor: 'rgb(232, 234, 240)',
                  }} />
              </Form.Item>
            </GridItem>
            <GridItem sx={12} sm={6} md={4} xl={4} xxl={4}>
              <H5 title={'Description'} requiredInput={false} fontWeight='500' />
              <Form.Item
                name="description"
              // label="Name"

              >
                <TextArea
                  autoSize={{ minRows: 14, maxRows: 14 }}
                  rows={15} style={{

                    fontWeight: 400,
                    color: 'black',
                    backgroundColor: 'rgb(232, 234, 240)',
                    "& .MuiInputBaseInput.MuiDisabled": {
                      WebkitTextFillColor: "#000000",
                    },
                    width: "100%"
                  }} disabled={true} />
              </Form.Item>
            </GridItem>

          </GridContainer>
          <Snackbars severity={severity} message={message} open={open} setOpen={setOpen} autoHideDuration={1000} />
        </Form>
        <div
          style={{
            marginLeft: '-25px',
          }}
        >
          <NotesList notes={ticket?.notesInternes} /></div>
      </div>

    </div>
  )
}