import { createSlice,  createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken'

export const fetchCauses = createAsyncThunk('cause/fetchCauses', 
() => {
    const tokenStored = localStorage.getItem('TokenStored')
    const timezone = localStorage.getItem('TZ')
    return axios.get(BaseUrl + `/SAV/getCauses`,
     {headers:  {
        'Content-Type': 'application/json',
        'Authorization': tokenStored,
        'Platform': 'web',
        'timezone' : timezone,
      }});
   

   
  });
const initialState = {
    loading : false,
    data : [],
    causes : [],
    error: "",
};
const causesSlice = createSlice({
    name: ' cause',
    initialState
    ,
    extraReducers: (builder) => {
        
        builder.addCase(fetchCauses.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchCauses.fulfilled, (state, action) => {
            state.loading = false;
            state.causes = action.payload.data.data.result;
            state.error = "";
        })
        builder.addCase(fetchCauses.rejected, (state, action) => {
            state.loading = false;
            state.causes = [];
            state.error = action.error.message;
        }) 
}
});
export default causesSlice.reducer;