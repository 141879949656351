import React, { useState, useEffect } from "react";
import H4 from "../../../../GlobalComponents/TypoGraphy/H4";

import { Colors } from "../../../../GlobalComponents/Utility/Colors";
import GridItem from "../../../../GlobalComponents/Grid/GridItem";
import GridContainer from "../../../../GlobalComponents/Grid/GridContainer";

import { useDispatch } from "react-redux";

import {
  fetchTicketById, updateTicketAssignedTo,

} from "../../../../ReduxTLK/features/SAV/TicketSlice";
import { Button, Card, CardContent,  Tab, Tabs, Tooltip, Box } from "@mui/material";


import { unwrapResult } from '@reduxjs/toolkit'
import { fetchUsersAgentSAV } from "../../../../ReduxTLK/features/User/UserSlice";
import { fetchChaines } from "../../../../ReduxTLK/features/Chaines/ChaineSlice";
import { fetchPventes } from "../../../../ReduxTLK/features/Pointdeventes/PointdeventeSlice";

import { array_status } from '../../../../ReduxTLK/features/SAV/ticketList';
import ExecuteIcon from "../../../../GlobalComponents/IconComponents/ExcecuteIcon";
import HomeIcon from "../../../../GlobalComponents/IconComponents/HomeIcon";

import ChatComponent from "../../../../GlobalComponents/Chat/Chat";
import { getMessages, getNbrMessagesNonLus } from "../../../../ReduxTLK/features/Chat/ChatSlice";
import { ImageBaseUrl } from '../../../../assets/utils/accessToken'
import { sortBy } from 'lodash'
import placeholder from "../../../../assets/images/placeholder.png"
import ExecuteTicketModalInsideView from "./ExecuteTicketModalInsideView";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import NoteRate from "./NoteRate";
import TicketDetailsView from "./TicketDetailsView";
import TabContainer from "../../../../GlobalComponents/TabsContainer/TabContainer";
import AttachmentListView from "./AttachementListView";
import { NavContext } from "../../../../App";
import { getAttachementList, getNbreAttachementNotSeen } from "../../../../ReduxTLK/features/Attachement/AttachementSlice";
import { setPaginationModelSAVTA } from "../../../../ReduxTLK/features/Pagination/PaginationsSlice";
import AttachementCounter from "../../Attachements/AttachementCounter";
import { Persistor } from "../../../..";



export default function ViewSAVTicket({ id }) {
  const context = React.useContext(NavContext)
  const [loading, setLoading] = useState(true);

  const [dirty, setDirty] = useState(false)
  const [ticketDetails, setTicket] = useState();

  const [openChatBox, setOpenChatBox] = useState(false)
  const [messagesList, setMessagesList] = useState([])
  const [nbreAttachementsNonLus, setNbreAttachementsNonLus] = useState(null)
 
  const [attachmentList, setAttachmentList] = useState([])
  const [paginationModelCustom, setPaginationModelCustom] = useState({
    pageSize: 7,
    page: 1,
    nbPage: 0,
    idTicket: ticketDetails?.id,
    view : true
  })
  // const id  = context.selectedTicket
  const dispatch = useDispatch();

  const fetchMessages = async () => {
    const data = {
      idTicket: ticketDetails?.id,
      page: 1,
      pageSize: 1000
    }
    try {
      dispatch(getMessages(data)).then(unwrapResult).then((res) => {
        const sortedMessages = sortBy(res.data?.data?.result, 'date')

        setMessagesList(sortedMessages)
      })
    } catch (e) {
     
    }
  }

  const fetchAttachments = async (paginationModelCustom) => {
    if (value === 2) {
      dispatch(getAttachementList(paginationModelCustom))
        .then(unwrapResult).then((res) => {
          setAttachmentList(res?.data?.data?.result)
          setPaginationModelCustom({
            pageSize: res?.data?.data?.pagination?.pageSize,
            page: res?.data?.data?.pagination?.currentPage,
            nbPage: res?.data?.data?.pagination?.nbPage,
            idTicket: ticketDetails?.id,
            view : true,
          })
          dispatch(setPaginationModelSAVTA({
            page: res?.data?.pagination?.currentPage,
            pageSize: res?.data?.pagination?.pageSize,
            nbPage: res?.data?.pagination?.nbPage,
            idTicket: ticketDetails?.id,
            view : true,
          }))
          Persistor.persist();
        }
        );
    }
  };
 
  const [selectedChaine, setSelectedChaine] = useState();




  useEffect(() => {
    dispatch(fetchUsersAgentSAV());
    dispatch(fetchChaines());
    //  fetchData();


  }, [ticketDetails]);
  const symbol = '#';
  const [pageTitle, setPageTitle] = useState()
  const [nbreMessagesNonLus, setNbreMessagesNonLus] = useState([])
  useEffect(() => {
    
    dispatch(fetchTicketById(id))
      .then(unwrapResult)
      .then((res) => {
        setTicket(res);
        setLoading(false);
        setPageTitle(`${symbol}${res?.id}`);
        setSelectedChaine(res?.chaine.id);
        if(res?.statut === 0)
        {
          setCanExecute(true);
        }
        dispatch(getNbreAttachementNotSeen(id)).then(unwrapResult).then((res) => {

          setNbreAttachementsNonLus(res.data.data.result)
        })

        const data = {
          idTicket: res?.id,
          page: 1,
          pageSize: 1000
        }
        try {
          dispatch(getNbrMessagesNonLus(data)).then((res) => {
            setNbreMessagesNonLus(res?.payload?.data?.data?.result)
          }).then(() =>
            dispatch(getMessages(data)).then(unwrapResult).then((res) => {
              const sortedMessages = sortBy(res.data?.data?.result, 'date')

              setMessagesList(sortedMessages)
            }))
        } catch (e) {
         
        }
        if (res?.assigne?.id) {
          const assigne = res?.assigne?.nom + ' ' + res?.assigne?.prenom

          setAssigne(assigne)
        }
        else {
          setAssigne('');
        }

      });

  }, []);

  const handleExecute = () => {
    //setRowSelectionModel(ticket.id)
    setOpenModalExecute(true);

  }
  useEffect(() => {
    dispatch(fetchPventes(selectedChaine));
  }, [selectedChaine])
 
  const GetStatutChip = () => {
    const foundStatut = array_status.find(statut => statut.id === ticketDetails?.statut);
    if (!foundStatut) {
      return '';
    }

    return (
      <Button
        variant='outlined'
        disabled={true}
        sx={{
          marginTop: '10px',
          //marginRight: "500px",
          textTransform: "none",
          borderRadius: 32,

          //  padding:'12px, 5px, 12px, 10px',
          '&.MuiButtonBase-root': {
            color: foundStatut.backgroundColor,
            borderColor: foundStatut.backgroundColor,
            borderRadius: '100px',
            width: '120px',
            height: '30px',
            border: '2px solid',
            fontWeight: 'bold'

          }
        }}
      //color="primary"
      > {foundStatut.nom}
      </Button>
      // <StyledChip label={foundStatut.nom} bgcolor={foundStatut.backgroundColor} />
    );
  };
  const [canExecute, setCanExecute] = useState(false);

  const [openModalExecute, setOpenModalExecute] = useState(false)


  /*******related tabs  */
  const [value, setValue] = React.useState(1);
  const [assigne, setAssigne] = React.useState('');
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };
  /****tabs */
 
  const tabs = [
    {
      id: 1,
      label: 'Ticket',
      component: <TicketDetailsView ticket={ticketDetails}
       id={ticketDetails?.id}
        setTicket={setTicket}
       setDirty={setDirty}
        />,
      icon: <HomeIcon />,
      disabled: false,
    },
    {
      id: 2,
      label: 'Attachements',
      component: <AttachmentListView idTicket={ticketDetails?.id}
        nbreAttachementsNonLus={nbreAttachementsNonLus}
        // formRef={formRef}
        fetchAttachments={fetchAttachments}

        setAttachmentList={setAttachmentList}
        attachmentList={attachmentList}

      />,
      counter: ticketDetails?.statut === 6 ? <></> : <AttachementCounter nbreAttachementsNonLus={nbreAttachementsNonLus} />,
      disabled: false,
    },
    // {
    //   id: 3,
    //   label: 'Contacts',
    //   component: <></>,
    //   disabled : true,
    //   // component: <ContactList idTicket={ticketDetails?.id} />
    // },
    // {
    //   id: 4,
    //   label: 'Actions',
    //   component: <></>,
    //   disabled : true,
    //   // component: <ActionList idTicket={ticketDetails?.id} />
    // },
    // {
    //   id: 5,
    //   label: 'Historique',
    //   component: <></>,
    //   disabled : true,
    // },
  ]
 

  return (
    <>
      {loading ? (

        <center>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
        </center>
      ) : (
        <>
          {/* header card contains details of ticket and possible actions */}
          <Card>

            <GridContainer
              spacing={-1}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              direction='row'
            // sx={{padding : 10, margin : 10}}
            >
              <GridItem sx={3} sm={3} md={1} xl={1} xxl={1}>
                <img src={ticketDetails?.pointDeVente?.logo ? (ImageBaseUrl + `${ticketDetails?.pointDeVente?.logo}`) : placeholder} alt={"logo"} width={'100%'} />
              </GridItem>
              <GridItem sx={9} sm={9} md={4} xl={4} xxl={4}>

                <CardContent>

                  <H4 title={pageTitle} color={Colors.primary}></H4>
                  <H4 title={ticketDetails?.pointDeVente?.nom} ></H4>
                  <Tooltip title={ticketDetails?.sujet} placement='top'>
                    {ticketDetails?.sujet?.length > 60 ?
                      <span>{ticketDetails?.sujet?.charAt(0)?.toUpperCase() + ticketDetails?.sujet?.toLowerCase().slice(1, 60) + '...'}</span>
                      : <span>{ticketDetails?.sujet?.charAt(0)?.toUpperCase() + ticketDetails?.sujet?.toLowerCase().slice(1, 60)}</span>}

                    {/* <H5 title={ticketDetails?.sujet?.slice(0,70)}></H5> */}
                  </Tooltip>
                  {/* <H4 title={assigne}></H4> */}
                  <br></br>
                  <GetStatutChip statut={ticketDetails?.statut} />
                </CardContent>
              </GridItem>
              <GridItem sx={6} sm={6} md={5} xl={5} xxl={5}></GridItem>
              <GridItem sx={6} sm={3} md={2} xl={2} xxl={2}>
                <NoteRate rate={ticketDetails?.rate} />
                {canExecute ? <Button
                  onClick={handleExecute}
                  startIcon={<ExecuteIcon color={Colors.velvet} />}
                  sx={{
                    color: `${Colors.velvet}`,
                    borderRadius: '16px',
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    marginLeft: '100px'

                  }}>Exécuter</Button>
                  : null}

              </GridItem>

            </GridContainer>

          </Card>
          {/* tabs conatins ticket details and related objects management componenets */}
      
            <GridContainer sx={{
              borderBottom: 0,
              borderColor: 'divider'
            }}>
              <GridItem sx={11} sm={11} md={11} xl={11} xxl={11}>
              
                <Tabs
                    value={value}
                    onChange={changeTab}
                    sx={{
                      backgroundColor: `${Colors.white}`,
                      fontWeight: 'bold',
                      fontStyle: 'bold',
                      textTransform: 'none',
                      color: '#344054',
                      textAlign: 'center',
                      fontFamily: "DM Sans",
                      fontSize: '30px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '145%',
                    }}
                  >
                    {tabs && tabs.map(tab => (
                      context.activeTab === tab.id ? (
                        tab.id === 1 ? (<>
                          <Tab
                            key={tab.id}
                            component={Box}
                            value={tab.id}
                            maxWidth='5px'
                            disabled={tab.disabled}
                            sx={{
                              textTransform: 'none',
                              justifyContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              maxHeight: '3px',
                              height: '3px',
                              '&.MuiButtonBase-root': {
                                fontFamily: 'DM Sans',
                                marginleft: "5px",
                                marginRight: "5px",
                                marginBottom: "-5px",
                                marginTop: "8px",
                                width: '5px',
                                maxWidth: '5px',
                                height: '20px',
                                minHeight: '40px',
                                maxHeight: '20px',

                              }
                            }}
                            icon={tab.icon}
                            iconPosition="center"
                            iconwidth='5px'> </Tab>
                        </>) :
                          <Tab
                            key={tab.id}
                            component={Box}
                            value={tab.id}
                            disabled={tab.disabled}
                            label={
                              typeof tab.label === "string" ? (
                                <span style={{ marginLeft: 10 }}>
                                  {" "}
                                  {tab.label}
                                </span>
                              ) : (
                                null
                              )
                            }
                            sx={{
                              textTransform: 'none',
                              borderSpacing: '10px',
                              justifyContent: 'right',
                              display: 'flex',
                              alignItems: 'center',
                              maxHeight: '3px',
                              height: '3px',
                              '&.MuiButtonBase-root': {
                                marginleft: "5px",
                                marginRight: "5px",
                                marginBottom: "-5px",
                                marginTop: "8px",
                                height: '20px',
                                minHeight: '40px',
                                maxHeight: '20px',

                              }
                            }}
                            //icon={tab.counter}
                            iconPosition="end"
                            iconwidth='5px'

                          > </Tab>

                      ) : (

                        <Tab
                          key={tab.id}
                          component={Box}
                          value={tab.id}
                          disabled={tab.disabled}
                          label={
                            typeof tab.label === "string" ? (
                              <span style={{ display: 'flex', direction: 'row', marginLeft: 10 }}>
                                {" "}
                                {tab.label}
                                {tab.counter && React.cloneElement(tab.counter, {})}
                              </span>
                            ) : (
                              null
                            )
                          }

                          sx={{
                            backgroundColor: "white",
                            borderCollapse: 'separate',
                            // broderShadow: '0px -2px 2px 0px rgba(16, 24, 40, 0.08)',
                            // border: '1px solid #ededf0',
                            textTransform: 'none',
                            borderSpacing: '10px',
                            justifyContent: 'right',
                            display: 'flex',
                            alignItems: 'center',
                            maxHeight: '3px',
                            height: '3px',
                            '&.MuiButtonBase-root': {
                              //  padding: "5px, 5px, 5px, 0px",
                              //  broderShadow: '0px -2px 2px 0px rgba(16, 24, 40, 0.08)',
                              marginleft: "5px",
                              marginRight: "5px",
                              marginBottom: "-5px",
                              marginTop: "8px",
                              // borderTopLeftRadius: '16px',
                              // borderTopRightRadius: '16px',
                              // width : '100px',
                              height: '20px',
                              minHeight: '40px',
                              maxHeight: '20px',
                            }
                          }}
                          icon={tab.icon}
                          iconPosition="start"
                          iconwidth='20px'
                        />)
                    ))
                    }

                  </Tabs>
                  {tabs.map(tab =>
                    value === tab.id ? (<TabContainer key={tab.id}>
                      {tab.component}
                    </TabContainer>) : null
                  )}



              </GridItem>
              <GridItem sx={{ backgroundColor: 'white', }} sm={1} md={1} xl={1} xxl={1}>
            
               <div>
                <ChatComponent
                  open={openChatBox}
                  setOpen={setOpenChatBox}
                  fetchMessages={fetchMessages}
                  messagesList={messagesList}
                  setMessagesList={setMessagesList}
                  openTicket={ticketDetails?.id}
                  pageTitle={pageTitle}
                  nbreMessagesNonLus={nbreMessagesNonLus}
                  setNbreMessagesNonLus={setNbreMessagesNonLus}
                  disableInput={true}
                />
</div>
<div style={{ backgroundColor: 'white', minHeight: '10px' }}> </div>
                  {/* <div style={{backgroundColor: '#F4F7FE', minHeight : '7px'}}> </div>
                  <div style={{backgroundColor: 'white', minHeight : '55px'}}> </div> */}
                  <div style={{ backgroundColor: '#F4F7FE', minHeight: '10px' }}> </div>
                  <div style={{ backgroundColor: 'white', minHeight: '529px' }}> </div>
              </GridItem>
            </GridContainer>
            {/* tab1 for ticket details and edit */}
          
       

          <ExecuteTicketModalInsideView 
          openModal={openModalExecute} 
          setOpenModal={setOpenModalExecute} 
          openTicket={id}
          status={ticketDetails?.statut}
           setTicket={setTicket}
           setDirty={setDirty}
            />
        </>)}
    </>
  );

}
