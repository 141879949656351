import {  Card, Button, Alert} from "@mui/material";
import React , {useState}from "react";
import {useTranslation} from "react-i18next";
import Box from '@mui/material/Box';

export default function DefaultTab () {
  
    
    const {t, i18n} = useTranslation();
    /**
     * The function `changeLanguage` is used to change the language in a React application using the
     * i18n library.
     */
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
      };
   
    const [error, setError] = useState(false);
    function action() {
      
   
      return <Alert ></Alert>
      
    };
return(
    <div>
         <Box 
    
    
    >
        <Button  title="aaa" ></Button>
        
       
      
       </Box>
       <Card>
       
       </Card>
      
       
 
    </div>
);

}