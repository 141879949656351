import { createSlice } from '@reduxjs/toolkit';

const initialState = {

  paginationModelSAVTT: {
   
    
    pageSize: 12,
    page: 1,
},
  paginationModelSAVTA: {
    pageSize: 7,
    page: 1,
    idTicket: null
  },
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPaginationModelSAVTT: (state, action) => {


      state.paginationModelSAVTT = action.payload;


    },
    setPaginationModelSAVTA: (state, action) => {


      state.paginationModelSAVTA = action.payload;


    },
    clearPaginations: (state, action) => {
      state.paginationModelSAVTT = initialState.paginationModelSAVTT
      state.paginationModelSAVTA = initialState.paginationModelSAVTA
    }

  },
});

export const { setPaginationModelSAVTT, setPaginationModelSAVTA, clearPaginations } = paginationSlice.actions;
export default paginationSlice.reducer;